<template>
  <div
    class="export-top-bar"
  >
    <div
      class="_top"
    >
      <div class="_left">
        <h1 class="_title">
          {{ title }}
        </h1>
      </div>
      <div
        class="_right"
      >
        <div
          class="_date"
        >
          {{ date }}
        </div>
        <img
          src="/img/icons/mooncamp-logo.svg"
        >
      </div>
    </div>
    <hr
      class="_divider"
    >
  </div>
</template>

<script setup>
import { DateTime } from 'luxon';

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const date = DateTime.local().toLocaleString(DateTime.DATETIME_SHORT);

</script>

<style scoped lang="scss">
.export-top-bar {
  margin: 1rem;

  ._top {
    padding-top: .6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._left {
      ._title {
        font-size: 30px;
        margin: 0;
      }
    }

    ._right {
      display: flex;
      align-items: center;

      ._date {
        padding-right: 1rem;
        margin-right: 1rem;
        border-right: 1px solid map_get($grey, 'lighten-3');
      }
    }
  }

  ._divider {
    margin-top: .6rem;
    margin-bottom: 3rem;
    border: 1px solid map_get($grey, 'lighten-4');
  }
}
</style>

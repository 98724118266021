<template>
  <div
    class="edit-property-card"
  >
    <m-content
      padding
      class="_content"
    >
      <m-form-item>
        <m-switch
          v-model:value="status"
          :disabled="!canSave || !canDeactivate"
          :label="$t('propertyConstants.active')"
        />
      </m-form-item>
      <m-form-item
        :label="$t('editPropertyForm.label')"
      >
        <m-text-field
          v-model:value="label"
          :auto-focus="label === ''"
          :hint="labelHint"
          :disabled="!canSave"
          counter="30"
        />
      </m-form-item>
      <m-form-item
        class="_type-container"
        :label="$t('editPropertyForm.type')"
      >
        <m-dropdown
          :title="$t('editPropertyForm.type')"
          block
          :disabled="isEdit"
          close-on-click
        >
          <div :class="['_value', isEdit ? '-disabled' : '']">
            <div class="_icon">
              <m-icon :type="iconByType({ type })" />
            </div>
            <div class="_text">
              {{ typeLabelByType(type)() }}
            </div>
            <div class="_right">
              <m-icon
                type="down"
                :color="$colors.grey.lighten1"
                size="11"
              />
            </div>
          </div>
          <template #overlay>
            <m-card
              list
              no-padding
              class="_type-overlay"
            >
              <m-card-item
                v-for="t in propertyTypes"
                :key="t.value"
                class="_type-item"
                @click="updateType(t.value)"
              >
                <div class="_type-icon">
                  <m-icon :type="t.icon" />
                </div>
                <div class="_right">
                  <div class="_top">
                    {{ t.title }}
                  </div>
                  <div class="_bottom">
                    {{ t.description }}
                  </div>
                </div>
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </m-form-item>
      <m-section
        heading-size="h5"
        :title="$t('editPropertyForm.usedFor')"
        :sub-title="propertyType.lookup === type ? '' : $t('editPropertyForm.usedForDescription')"
        class="_settings"
      >
        <m-form-item>
          <component
            :is="type === propertyType.lookup ? 'm-tooltip' : 'div'"
            placement="left"
          >
            <span>
              <m-select
                v-model:value="usedFor"
                :items="usedForItems"
                :disabled="!canSave || !canDeactivate || type === propertyType.lookup"
                :placeholder="$t('general.empty')"
                multiple
                tags
                full-width
                match-trigger-width
              />
            </span>
            <template #title>
              <template
                v-if="type === propertyType.lookup"
              >
                {{ $t('editPropertyForm.lookupUsedForHint') }}
              </template>
            </template>
          </component>
        </m-form-item>
      </m-section>
      <m-section
        v-if="type === propertyType.number"
        heading-size="h5"
        :title="$t('editPropertyForm.numberFormat')"
        class="_settings"
      >
        <m-form-item>
          <m-select
            v-model:value="numberFormat"
            :items="formattingOptions"
            :disabled="!canSave"
            :placeholder="$t('general.empty')"
            full-width
            match-trigger-width
          />
        </m-form-item>
      </m-section>
      <m-section
        v-if="isOption"
        heading-size="h5"
        :title="$t('editPropertyForm.segments')"
        :sub-title="$t('editPropertyForm.segmentsDescription')"
        class="_options"
      >
        <m-draggable
          draggable-item-class="_handle"
          ghost-item-class="property-option"
          dragover-item-class="property-option"
          can-drag-over-top
          can-drag-over-bottom
          scroll-container-class="_content"
          :recreate-key="options.map(({uid}) => uid)"
          @set-drag-item="setDragItem"
          @over-top="setOverTop"
          @over-bottom="setOverBottom"
          @drag-drop="handleDrop"
          @cancel="cancelDragging"
        >
          <property-option
            v-for="(option, index) in options"
            :key="option.uid"
            :label="optionLabel(option.label)"
            :option="option"
            :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(option.uid)"
            :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(option.uid)"
            @delete-segment="deleteSegment(option)"
            @update-label="updateLabel(index, $event)"
            @update-color="updateColor(index, $event)"
          />
        </m-draggable>
        <div class="_action">
          <m-btn
            small
            @click="addOption"
          >
            {{ $t('editPropertyForm.addSegment') }}
          </m-btn>
        </div>
      </m-section>
      <m-alert
        v-if="isSpace && showSpaceManagementHint"
        class="_alert"
        type="info"
      >
        <i18n-t
          keypath="editPropertyForm.spaceManagementHint"
          tag="div"
        >
          <template #link>
            <m-link
              :to="{ name: routeName.spaceList }"
              underlined
              inherit-color
            >
              {{ $t('editPropertyForm.spaceManagementLink') }}
            </m-link>
          </template>
        </i18n-t>
      </m-alert>
      <m-section
        v-if="type === propertyType.lookup"
        heading-size="h5"
        :title="$t('editPropertyForm.lookupValue')"
        :sub-title="$t('editPropertyForm.lookupDescription')"
        class="_options"
      >
        <lookup-form
          :used-for="usedFor"
          :relation="lookupRelation"
          :property="lookupProperty"
          @input="updateLookup"
        />
      </m-section>
    </m-content>
    <m-default-form-actions
      v-if="canSave"
      class="_footer"
      :clickable="validationError === ''"
      :submit-message="validationError"
      :loading="loading"
      @submit="submit"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import LookupForm from '@/components/property/LookupForm.vue';
import MTextField from 'shared/components/base/MTextField.vue';
import PropertyOption from '@/components/PropertyOption.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useSort from '@/composables/draggable/sort';
import useSpaces from '@/composables/space/spaces';
import { DateTime } from 'luxon';
import { accessGroupFlag, numberFormat, optionColor, propertyApplication, propertyStatus, propertyType, routeName } from 'shared/constants.json';
import { accountSettings as accountSettingsConfig } from 'shared/api/query/configs.json';
import { automaticColor } from 'shared/lib/color';
import { camelCase } from 'lodash-es';
import { copy } from 'shared/lib/copy';
import { guid } from 'shared/lib/uuid';
import {
  iconByType,
  typeDescriptionByType,
  typeLabelByType,
} from '@/lib/property';
import { logCatch } from '@/lib/logger/logger';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'EditPropertyForm',
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Object,
      required: true,
    },
  },
  emits: ['cancel', 'created', 'updated'],
  components: { PropertyOption, MTextField, LookupForm },
  setup() {
    const { userLang } = useLoggedInUser();
    const { accountHasFeature, userHasRight } = useAccess();
    const { properties, createSingleWithOptions: createProperty, updateSingleWithOptions: updateProperty } = useProperties();
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();

    const spacesSvc = useSpaces();

    return {
      dragItemId,
      accountHasFeature,
      userHasRight,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      properties,
      createProperty,
      updateProperty,
      userLang,
      allSpaces: spacesSvc.colorfulSpaces,
    };
  },
  data() {
    return {
      loading: false,
      options: [],
      optionsToDelete: [],
      spaces: [],
      spacesToDelete: [],
      valid: false,
      maxLabelLengthHint: 30,
      propertyType,
      lookupRelation: {},
      lookupProperty: {},
      iconByType,
      typeLabelByType,
      status: '',
      type: '',
      label: '',
      description: '',
      usedFor: [],
      numberFormat: '',
      routeName,
    };
  },
  computed: {
    showSpaceManagementHint() {
      const releaseDate = DateTime.local(2024, 7, 19);
      const createdAt = DateTime.fromISO(this.account.createdAt);
      return createdAt.diff(releaseDate) < 0;
    },
    values() {
      return {
        ...this.entity,
        label: { en: this.label, de: this.label },
        type: this.type,
        usedFor: this.usedFor,
        numberFormat: this.numberFormat,
        lookupProperty: this.lookupProperty,
        lookupRelation: this.lookupRelation,
        propertyOptionOrder: this.options.map((o, index) => (typeof o.uid === 'number' ? { uid: o.uid } : { rid: index + 2 })),
        options: [
          ...this.options.map((o, index) => {
            const res = {
              uid: o.uid,
              account: o.account,
              label: { en: textByLang(o.label, this.userLang), de: textByLang(o.label, this.userLang) },
              color: o.color,
            };

            if (typeof res.uid === 'string') {
              res.rid = index + 2;
              delete res.uid;
            }

            return res;
          }),
          ...this.optionsToDelete.map((op) => ({
            uid: op.uid,
            deletedAt: DateTime.local().toISO(),
          })).filter((op) => typeof op.uid !== 'string'),
        ],
        resourceSettings: { uid: this.account.resourceSettings.uid, spaceOrder: this.spaces.map((o, index) => (typeof o.uid === 'number' ? { uid: o.uid } : { rid: index + 1 })) },
        spaces: [
          ...this.spaces.map((o, index) => {
            const res = {
              uid: o.uid,
              account: o.account,
              title: o.title,
              color: o.color,
            };
            if (typeof res.uid === 'string') {
              res.rid = index + 1;
              delete res.uid;
            }
            return res;
          }),
          ...this.spacesToDelete.map((op) => ({
            uid: op.uid,
            deletedAt: DateTime.local().toISO(),
          })).filter((op) => typeof op.uid !== 'string'),
        ],
        status: this.status ? propertyStatus.active : propertyStatus.inactive,
      };
    },
    propertyTypes() {
      const types = [
        propertyType.options,
        propertyType.singleSelect,
        propertyType.text,
        propertyType.date,
        propertyType.user,
        propertyType.number,
        propertyType.url,
        propertyType.lookup,
      ];
      return types.map((item) => ({
        value: item,
        icon: iconByType({ type: item }),
        title: typeLabelByType(item)(),
        description: typeDescriptionByType(item)(),
      }));
    },
    formattingOptions() {
      return [numberFormat.number, numberFormat.percent, numberFormat.commaSeparated, numberFormat.euro, numberFormat.dollar, numberFormat.pound].map((f) => ({
        text: this.$t(`editPropertyForm.${camelCase(f)}`),
        value: f,
      }));
    },
    usedForItems() {
      if (this.type === propertyType.lookup) {
        return [
          {
            text: this.$t(`propertyApplication.${propertyApplication.goal}`),
            value: propertyApplication.goal,
          },
        ];
      }
      const items = [
        {
          text: this.$t(`propertyApplication.${propertyApplication.user}`),
          value: propertyApplication.user,
        },
        {
          text: this.$t(`propertyApplication.${propertyApplication.space}`),
          value: propertyApplication.space,
          disabled: true,
        },
      ];
      if (this.account.accountSettings[accountSettingsConfig.edges.usesGoals]) {
        items.push(
          {
            text: this.$t(`propertyApplication.${propertyApplication.goal}`),
            value: propertyApplication.goal,
          },
        );
      }
      if (this.account.accountSettings[accountSettingsConfig.edges.usesUpdates]) {
        items.push(
          {
            text: this.$t(`propertyApplication.${propertyApplication.update}`),
            value: propertyApplication.update,
          },
        );
      }
      return items;
    },
    validationError() {
      if (this.values.type === this.propertyType.lookup) {
        if ([this.values.lookupRelation, this.values.lookupProperty].some((property) => typeof property === 'undefined' || property.uid === null)) {
          return this.$t('editPropertyForm.lookupRelationsNotSet');
        }
      }

      return '';
    },
    canSave() {
      return this.userHasRight([accessGroupFlag.propertyWriteAccess]);
    },
    canDeactivate() {
      return !this.entity.isNotDeletable;
    },
    isEdit() {
      return this.entity.uid !== 0;
    },
    isOption() {
      return [propertyType.singleSelect, propertyType.options].includes(this.type);
    },
    isSpace() {
      return [propertyType.space].includes(this.type);
    },
    labelHint() {
      if (typeof this.label === 'undefined') {
        return '';
      }

      if (this.label.length > this.maxLabelLengthHint) {
        return this.$t('editPropertyForm.labelHint');
      }
      return '';
    },
  },
  methods: {
    updateLookup({ relation, property }) {
      this.lookupRelation = relation;
      this.lookupProperty = property;
    },
    handleDrop() {
      this.options = this.dropItem(this.options);
    },
    optionLabel(label) {
      return textByLang(label, this.userLang);
    },
    updateLabel(index, value) {
      this.options[index].label.de = value;
      this.options[index].label.en = value;
    },
    updateColor(index, value) {
      this.options[index].color = value;
    },
    addOption() {
      this.options.push({
        uid: `option_${guid()}`,
        label: { de: '', en: '' },
        color: automaticColor(guid(), optionColor.all),
      });
    },
    deleteSegment(option) {
      const deleteMethod = () => {
        if (`${option.uid}`.indexOf('option_') === -1) {
          this.optionsToDelete.push(option);
        }
        this.options = this.options.filter((op) => op.uid !== option.uid);
      };
      this.$confirm({
        title: this.$t('editPropertyForm.deleteText'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    cancel() {
      this.$emit('cancel');
    },
    updateType(val) {
      this.type = val;
      if (val === propertyType.lookup) {
        this.usedFor = [propertyApplication.goal];
      }
    },
    create(property) {
      this.loading = true;
      this.createProperty(property)
        .then((property) => {
          this.options = copy(property.options);
          this.spaces = copy(this.allSpaces);
          this.$showSnackbar({ color: 'success', message: this.$t('success.created') });
          this.resetForm();
          this.$emit('created', { property });
        })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    update(prop) {
      const toUpdate = copy(prop);
      if (this.values.type !== propertyType.lookup) {
        delete (toUpdate.lookupRelation);
        delete (toUpdate.lookupProperty);
      }

      this.loading = true;
      this.updateProperty(toUpdate)
        .then((property) => {
          this.options = copy(property.options);
          this.spaces = copy(this.allSpaces);
          this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
          this.resetForm();
          this.$emit('updated', { property });
        })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.optionsToDelete = [];
      this.spacesToDelete = [];
    },
    submit() {
      if (this.isEdit) {
        this.update({
          ...this.values,
          account: { uid: this.account.uid },
          type: this.type,
          usedFor: this.usedFor,
          lookupRelation: this.lookupRelation,
          lookupProperty: this.lookupProperty,
        });
        return;
      }

      this.create({ ...this.values, account: { uid: this.account.uid } });
    },
  },
  created() {
    this.type = this.entity.type;
    this.label = textByLang(this.entity.label, this.userLang);
    this.status = this.entity.status === propertyStatus.active;
    this.usedFor = this.entity.usedFor;
    this.numberFormat = this.entity.numberFormat;
    this.usedFor = this.entity.usedFor;
    this.lookupProperty = this.entity.lookupProperty;
    this.lookupRelation = this.entity.lookupRelation;
    this.options = copy(this.entity.options).map((o) => {
      if (o.color === null) {
        return {
          ...o,
          color: automaticColor(textByLang(o.label, this.userLang), optionColor.all),
        };
      }

      return o;
    });
    this.spaces = copy(this.allSpaces);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .edit-property-card {
    ._content {
      height: calc(100vh - 13rem);
      padding-bottom: 10rem;
      overflow: auto;
    }

    ._footer {
      @media (max-width: $screen-size-sm) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
      }
    }

    ._type-container {
      margin-bottom: 1.5rem;

      ._value {
        display: flex;
        align-items: center;
        width: 100%;
        height: 3.2rem;
        padding: .6rem 1.1rem;
        cursor: pointer;
        border: 1px solid $input-border-color;
        border-radius: $default-border-radius;

        ._icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: .6rem;
        }

        ._text {
          margin-top: .2rem;
          line-height: 1.6;
        }

        ._right {
          margin-right: -.3rem;
          margin-left: auto;
        }

        &:hover {
          border-color: $primary-color;
        }

        &.-disabled {
          color: map_get($grey, 'lighten-1');
          cursor: not-allowed;
          background-color: map_get($grey, 'lighten-4');

          &:hover {
            border-color: $input-border-color;
          }
        }
      }

      ._item {
        margin: 1rem;
      }
    }

    ._options {
      ._action {
        margin-top: .6rem;
      }
    }
  }

  ._type-overlay {
    ._type-item {
      ._type-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        margin: .1rem 1rem .1rem .1rem;
        border: 1px solid $input-border-color;
        border-radius: $default-border-radius;
      }

      ._bottom {
        font-size: $font-size-2;
        color: $font-color-secondary;
      }
    }
  }
</style>

import { DEFAULT_ATTRIBUTES } from 'shared/api/query/constants';
import { printable as printableConfig, storageObject as storageObjectConfig } from 'shared/api/query/configs.json';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const printableChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: printableConfig.edges.width },
  { attr: printableConfig.edges.status },
  {
    attr: printableConfig.edges.storageObject,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
  },
];

<template>
  <m-dropdown
    v-model:value="showMenu"
    :data-id="item.key"
    placement="bottomLeft"
    :class="['table-header-cell', item.sortable ? '-sortable' : '', item.hideBorder ? '-hide-border' : '', isDisabled ? '': '-hover-effect']"
    :style="columnStyle"
    :trigger-style="{height: `100%` }"
    :disabled="isDisabled"
    :title="$t('general.actions')"
    block
  >
    <m-dragzone
      v-if="draggingOverLeft"
      vertical
      class="_dragzone-left"
    />
    <div
      ref="cell"
      class="_cell"
    >
      <div
        v-if="item.icon !== '' && item.icon !== undefined"
        class="_icon"
      >
        <m-icon
          :type="item.icon"
          color="grey"
        />
      </div>
      <div class="_text">
        {{ item.label }}
      </div>
    </div>
    <template
      v-if="showMenu"
      #overlay
    >
      <m-card
        class="header-menu"
        no-padding
        list
      >
        <table-header-cell-menu
          :actions="actions"
          @click-action="handleClickAction"
        />
        <m-divider
          v-if="showDivider"
          xxs
        />
        <slot
          name="custom-menu-action"
          :item="item"
        />
      </m-card>
    </template>
    <div
      v-if="!readOnly && draggable"
      :class="['_drag-handle', active? '-active':'']"
      :style="dragStyle"
      draggable="true"
      @dragstart="handleDragStart"
      @dragend="handleDragEnd"
      @mousedown.stop="handle"
    />
    <m-dragzone
      v-if="draggingOverRight"
      vertical
      class="_dragzone-right"
    />
  </m-dropdown>
</template>

<script>
import MDragzone from 'shared/components/base/MDragzone.vue';
import TableHeaderCellMenu from '@/components/table/TableHeaderCellMenu.vue';
import { CASCADE_TABLE_HEADER_WIDTH_CHANGED } from '@/lib/constants';
import { datadogRum } from '@datadog/browser-rum';
import { intersection } from 'shared/lib/array/array';

export default {
  name: 'TableHeaderCell',
  props: {
    item: {
      type: Object,
      required: true,
    },
    actions: {
      type: Array,
      default: () => ['sort', 'filter', 'hide'],
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    draggingOverLeft: {
      type: Boolean,
      default: false,
    },
    draggingOverRight: {
      type: Boolean,
      default: false,
    },
  },
  components: { MDragzone, TableHeaderCellMenu },
  emits: ['width-changed', 'dragstart', 'dragend', 'drag', 'click-action'],
  data() {
    return {
      initialCellLeft: 0,
      initialPageX: 0,
      active: false,
      width: this.item.width,
      ghostImage: null,
      showMenu: false,
    };
  },
  computed: {
    columnStyle() {
      return { flex: `0 0 ${this.item.width}px`, width: `${this.item.width}px` };
    },
    dragStyle() {
      return { left: `${this.width - 2}px` };
    },
    standardActions() {
      return intersection(['sort', 'filter', 'hide'], this.actions);
    },
    showDivider() {
      return this.standardActions.length > 0 && !!this.$slots['custom-menu-action'];
    },
    isDisabled() {
      return this.readOnly || this.actions.length === 0;
    },
  },
  methods: {
    handle(event) {
      event.stopPropagation();
    },
    initGhostImage() {
      const pic = new Image();
      // transparent gif, resolves issue with Safari that otherwise does not allow dragging;
      pic.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
      pic.style.visibility = 'hidden';
      this.ghostImage = pic;
    },
    handleDragStart(event) {
      this.initialCellLeft = this.$refs.cell.getBoundingClientRect().left - 1;
      this.initialPageX = event.pageX - this.width;
      this.active = true;
      event.dataTransfer.setData('text/plain', 'anything');
      event.dataTransfer.setDragImage(this.ghostImage, 0, 0);
      this.$emit('dragstart', event);
    },
    handleDragEnd() {
      this.$emit('width-changed', { ...this.item, width: this.width });
      this.$emit('dragend');
      this.active = false;
      datadogRum.addAction(CASCADE_TABLE_HEADER_WIDTH_CHANGED);
    },
    handleDrag(event) {
      if (!this.active) {
        return;
      }

      if (event.pageX === 0) {
        return;
      }

      const diff = event.pageX - (this.initialPageX + this.width);
      let width = this.width + diff;
      if (width <= 100) {
        width = 100;
      }
      if (this.width !== width) {
        this.$emit('drag', { pageX: this.initialCellLeft + width });
      }
      this.width = width;
    },
    handleClickAction({ action, input }) {
      this.showMenu = false;
      this.$emit('click-action', { item: this.item, action, input });
    },
  },
  watch: {
    item() {
      if (this.item.width === this.width) {
        return;
      }
      this.width = this.item.width;
    },
  },
  created() {
    window.addEventListener('dragover', this.handleDrag);
    this.initGhostImage();
  },
  beforeUnmount() {
    window.removeEventListener('dragover', this.handleDrag);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  $height: 4rem;

  .table-header-cell {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: $height;
    line-height: 1.15;
    color: $font-color-secondary;
    border-right: 1px solid $border-color;

    &.-hover-effect {
      cursor: pointer;

      &:hover {
        background-color: $hover-color;
      }
    }

    ._dragzone-right {
      right: -2px;
    }

    ._dragzone-left {
      left: -2px;
    }

    &.-hide-border {
      border-right: none;
    }

    ._cell {
      display: flex;
      align-items: center;
      padding: .3rem .8rem;
      overflow: hidden;
      user-select: none;

      ._icon {
        margin-right: .6rem;
      }

      ._text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $font-color-secondary;
      }
    }

    ._drag-handle {
      position: absolute;
      top: 0;
      right: -.2rem;
      z-index: 1;
      width: .4rem;
      height: 100%;
      cursor: col-resize;
      background-color: transparent;

      &.-active {
        background-color: map_get($blue, 'lighten-1');
        outline-color: map_get($blue, 'lighten-1');
      }

        &:hover {
          background-color: map_get($blue, 'lighten-1');
          outline-color: map_get($blue, 'lighten-1');
        }

    }
  }

</style>

import i18n from '@/lang';

import intersection from 'lodash-es/intersection';
import intersectionWith from 'lodash-es/intersectionWith';
import { CREATED_AT } from 'shared/api/query/constants';
import { GOAL_RELATION } from '@/lib/props/custom-types';
import { copy } from 'shared/lib/copy';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { propertyApplication, propertyStatus, propertyType } from 'shared/constants.json';

export const iconByType = ({ type, edgeName }) => {
  switch (edgeName) {
    case goalConfig.edges.description:
    case goalConfig.edges.title:
      return 'align-left';
    case CREATED_AT:
    case goalConfig.edges.cachedCalculatedCurrent:
    case goalConfig.edges.progressMeasurement:
      return 'line-chart';
    case goalConfig.edges.goalCycle:
      return 'retweet';
    case goalConfig.edges.parents:
      return 'cluster';
    case goalConfig.edges.cachedLastUpdatedAt:
      return 'history';
    default:
      switch (type) {
        case propertyType.status:
          return 'status';
        case propertyType.space:
          return 'team';
        case propertyType.options:
          return 'bars';
        case propertyType.singleSelect:
          return 'caret-down';
        case propertyType.text:
          return 'align-left';
        case propertyType.date:
          return 'calendar';
        case propertyType.number:
          return 'number';
        case propertyType.user:
          return 'user';
        case propertyType.url:
          return 'link';
        case propertyType.lookup:
          return 'search';
        case GOAL_RELATION:
          return 'cluster';
        default:
          return '';
      }
  }
};

export const usedForLabelByType = (type) => {
  switch (type) {
    case propertyApplication.user:
      return () => i18n.t('propertyConstants.user');
    case propertyApplication.goal:
      return () => i18n.t('propertyConstants.goal');
    case propertyApplication.update:
      return () => i18n.t('propertyConstants.update');
    default:
      return '';
  }
};

export const typeLabelByType = (type) => {
  switch (type) {
    case propertyType.space:
      return () => i18n.t('propertyConstants.space');
    case propertyType.options:
      return () => i18n.t('propertyConstants.options');
    case propertyType.singleSelect:
      return () => i18n.t('propertyConstants.singleSelect');
    case propertyType.text:
      return () => i18n.t('propertyConstants.text');
    case propertyType.date:
      return () => i18n.t('propertyConstants.date');
    case propertyType.number:
      return () => i18n.t('propertyConstants.number');
    case propertyType.user:
      return () => i18n.t('propertyConstants.user');
    case propertyType.url:
      return () => i18n.t('propertyConstants.url');
    case propertyType.lookup:
      return () => i18n.t('propertyConstants.lookup');
    default:
      return () => '';
  }
};

export const typeDescriptionByType = (type) => {
  switch (type) {
    case propertyType.space:
      return () => i18n.t('propertyConstants.spaceDescription');
    case propertyType.options:
      return () => i18n.t('propertyConstants.optionsDescription');
    case propertyType.singleSelect:
      return () => i18n.t('propertyConstants.singleSelectDescription');
    case propertyType.text:
      return () => i18n.t('propertyConstants.textDescription');
    case propertyType.date:
      return () => i18n.t('propertyConstants.dateDescription');
    case propertyType.number:
      return () => i18n.t('propertyConstants.numberDescription');
    case propertyType.user:
      return () => i18n.t('propertyConstants.userDescription');
    case propertyType.url:
      return () => i18n.t('propertyConstants.urlDescription');
    case propertyType.lookup:
      return () => i18n.t('propertyConstants.lookupDescription');
    default:
      return '';
  }
};

export const statusLabelbyStatus = (status) => {
  switch (status) {
    case propertyStatus.active:
      return () => i18n.t('propertyConstants.active');
    case propertyStatus.inactive:
      return () => i18n.t('propertyConstants.inactive');
    default:
      return () => '';
  }
};

export const emptyValue = (property) => ({
  uid: 0,
  number: null,
  text: '',
  timestamp: null,
  selectedOptions: [],
  spaces: [],
  users: [],
  property,
});

export const emptyValues = (properties) => properties.map((prop) => emptyValue(prop));

export const addObjectiveOption = ({ properties, objectiveOption }) => properties.map((prop) => {
  if (objectiveOption === undefined || objectiveOption === null) {
    return prop;
  }

  if (!prop.property.isGoalType) {
    return prop;
  }

  const p = copy(prop);
  p.selectedOptions = [objectiveOption];
  return p;
});

export const addKeyResultOption = ({ properties, keyResultOption }) => properties.map((prop) => {
  if (typeof keyResultOption === 'undefined' || keyResultOption === null) {
    return prop;
  }

  if (!prop.property.isGoalType) {
    return prop;
  }

  const p = copy(prop);
  p.selectedOptions = [keyResultOption];
  return p;
});

export const initValue = ({ propertyOwners = [], property }) => {
  const value = emptyValue(property);
  delete value.uid;
  let intersect;
  switch (property.type) {
    case propertyType.space:
      value.spaces = intersectionWith(...propertyOwners.map((g) => g.properties.filter((item) => item.property.uid === property.uid).map((p) => p.spaces).flat()), (a, b) => a.uid === b.uid);
      break;
    case propertyType.options:
    case propertyType.singleSelect:
      value.selectedOptions = intersectionWith(...propertyOwners.map((g) => g.properties.filter((item) => item.property.uid === property.uid).map((p) => p.selectedOptions).flat()), (a, b) => a.uid === b.uid);
      break;
    case propertyType.user:
      value.users = intersectionWith(...propertyOwners.map((g) => g.properties.filter((item) => item.property.uid === property.uid).map((p) => p.users).flat()), (a, b) => a.uid === b.uid);
      break;
    case propertyType.text:
    case propertyType.url:
      intersect = intersection(...propertyOwners.map((g) => g.properties.filter((item) => item.property.uid === property.uid).map((p) => p.text).flat()));
      if (intersect.length === 1) { value.text = intersect[0]; }
      break;
    case propertyType.number:
      intersect = intersection(...propertyOwners.map((g) => g.properties.filter((item) => item.property.uid === property.uid).map((p) => p.number).flat()));
      if (intersect.length === 1) { value.number = intersect[0]; }
      break;
    case propertyType.date:
      intersect = intersection(...propertyOwners.map((g) => g.properties.filter((item) => item.property.uid === property.uid).map((p) => p.timestamp).flat()));
      if (intersect.length === 1) { value.timestamp = intersect[0]; }
      break;
    default:
      break;
  }
  return value;
};

<template>
  <div class="google-sheets-summary">
    <div>
      <m-link
        :href="query.url"
        target="_blank"
        class="_link"
        underline-on-hover
        :disabled="query.url === '' || isPrivateDataSource"
      >
        <m-icon :type="icon" />
        <span class="_text">
          {{ query.documentTitle }}
        </span>
        <m-icon
          v-if="!(query.url === '' || isPrivateDataSource)"
          type="external-link"
        />
      </m-link>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('googleSheetsSummary.sheetName') }}:
      </div>
      <div class="_content">
        {{ query.sheetName }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('googleSheetsSummary.column') }}:
      </div>
      <div class="_content">
        {{ query.column }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('googleSheetsSummary.row') }}:
      </div>
      <div class="_content">
        {{ query.row }}
      </div>
    </div>
  </div>
</template>

<script>
import { dataSourceIcon } from '@/lib/data-source';

export default {
  name: 'GoogleSheetsSummary',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isPrivateDataSource() {
      return this.query.dataSource === null;
    },
    icon() {
      if (this.isPrivateDataSource) {
        return 'sheets';
      }
      return dataSourceIcon(this.query.dataSource);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .google-sheets-summary {
    ._link {
      display: flex;
      margin-bottom: 1rem;
      color: $font-color-primary;

      ._text {
        margin: 0 .4rem 0 .8rem;
      }
    }

    ._item {
      display: flex;

      ._label {
        flex: 0 0 6rem;
        color: $font-color-secondary;
      }
    }
  }
</style>

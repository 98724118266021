import { aggregationMethod, goalProgressMeasurement } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { useI18n } from 'vue-i18n';

export default function useProgressMeasurement() {
  const { t } = useI18n();
  const progressMeasurementOptions = [
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.continuous)}`),
      value: goalProgressMeasurement.continuous,
      icon: 'line-chart',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.alignedItems)}`),
      value: goalProgressMeasurement.alignedItems,
      icon: 'apartment',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.threshold)}`),
      value: goalProgressMeasurement.threshold,
      icon: 'threshold',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.binary)}`),
      value: goalProgressMeasurement.binary,
      icon: 'check-square',
    },
    {
      text: t(`metricForm.${camelCase(goalProgressMeasurement.none)}`),
      value: goalProgressMeasurement.none,
      icon: 'stop',
    },
  ];

  const aggregationMethodOptions = [
    {
      text: t(`metricForm.aggregationMethod.${camelCase(aggregationMethod.relative)}`),
      value: aggregationMethod.relative,
      // icon: '',
    },
    {
      text: t(`metricForm.aggregationMethod.${camelCase(aggregationMethod.absolute)}`),
      value: aggregationMethod.absolute,
      // icon: '',
    },

  ];

  const allowedProgressMeasurementOptions = (option) => {
    if (option === null) {
      return progressMeasurementOptions;
    }

    if (option.allowedMeasurementTypes.length === 0) {
      return progressMeasurementOptions;
    }

    return progressMeasurementOptions.map((o) => ({
      ...o,
      disabled: !option.allowedMeasurementTypes.includes(o.value),
    }));
  };

  const allowedAggregationMethodOptions = () => aggregationMethodOptions;

  return {
    progressMeasurementOptions,
    allowedProgressMeasurementOptions,
    allowedAggregationMethodOptions,
  };
}

<template>
  <div />
</template>

<script setup>
import useSpaces from '@/composables/space/spaces';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_VIEW, VIEW } from '@/route-params';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { routeName } from 'shared/constants.json';
import { useRoute, useRouter } from 'vue-router';

const { linkToRoute } = useViewNavigator();
const { accountSpace: space } = useSpaces();

const route = useRoute();
const router = useRouter();
const redirect = () => {
  if (!isNullOrUndefined(space.value)) {
    const view = !isNullOrUndefined(route.query[VIEW]) ? route.query[VIEW] : GOAL_VIEW;
    const { to } = linkToRoute(undefined, routeName.spaceDetails, view, space.value.uid);
    router.replace(to);
    return;
  }
  router.replace({ name: routeName.goalsExplorer });
};
redirect();
</script>

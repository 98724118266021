<template>
  <div class="comment-list">
    <m-content
      v-if="comments.length > 0 && !showComments && collapse"
      padding-xs
      class="_divider"
      @click="showComments = true"
    >
      <div class="_commentators">
        <user-avatar
          v-for="commentator in commentators"
          :key="commentator.uid"
          :user="commentator"
          class="_user"
        />
      </div>
      <div
        v-if="comments.length > 0"
        class="_amount"
      >
        {{ commentsAmount }} {{ $t('commentList.comments', commentsAmount) }}
        <template v-if="!$store.state.breakpoint.smAndDown">
          <span class="_time">
            {{ $t('commentList.latestComment', { latestComment }) }}
          </span>
          <span class="_view-thread">
            {{ $t('commentList.viewThread') }}
          </span>
        </template>
      </div>
    </m-content>
    <m-content
      v-if="(showComments || !collapse) && comments.length > 0"
    >
      <template
        v-for="(c, index) in pComments"
        :key="c.uid"
      >
        <comment-item
          :comment="c"
          :class="['_item', index === 0 ? '-first' : '', c.showMeta ? '-show-meta' : '']"
        />
      </template>
      <m-btn
        v-if="comments.length === 0 && !addComment"
        hide-border
        light
        icon="message"
        small
        @click="comment"
      >
        {{ $t('commentList.addComment') }}
      </m-btn>
    </m-content>
    <comment-layout
      v-if="(addComment || (comments.length > 0 && showComments) || !collapse) && !disabled"
      :creator="loggedInUser"
      hide-title
      show-meta
      :class="['_new', comments.length === 0 ? '-single' : '']"
    >
      <div :class="['_editor', horizontal ? '-horizontal' : '', hoverEditor ? '-hover-editor' : '']">
        <m-editor
          ref="editor"
          :key="key"
          v-model:value="message"
          :allowed-content="allowedContent"
          :placeholder="$t('commentList.writeComment')"
          class="_inner"
          :ctrl-enter-handlers="[onCmdOrModEnter]"
          :mod-enter-handlers="[onCmdOrModEnter]"
          @focus="focused = true"
        />
        <m-btn
          v-if="focused || Object.keys(message).length > 0"
          color="primary"
          small
          class="_btn _submit-btn"
          :loading="createLoading"
          @click="submit"
        >
          {{ $t('general.send') }}
        </m-btn>
      </div>
    </comment-layout>
  </div>
</template>

<script>
import CommentItem from '@/components/comment/CommentItem.vue';
import CommentLayout from '@/components/comment/CommentLayout.vue';
import MEditor from '@/components/editor/MEditor.vue';
import UserAvatar from 'shared/components/UserAvatar.vue';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { DateTime } from 'luxon';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { guid } from 'shared/lib/uuid';
import { humanReadableDiffFromNow } from '@/lib/time/time';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';
import { trimEmptyLines } from '@/lib/editor/editor';

export default {
  name: 'CommentList',
  props: {
    comments: {
      type: Array,
      required: true,
    },
    update: {
      type: Object,
      default: () => null,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    hoverEditor: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    emitContentChange: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['write-comment', 'update-content'],
  components: {
    CommentLayout,
    MEditor,
    CommentItem,
    UserAvatar,
  },
  setup() {
    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { debounce } = useDebounce();
    const { deletedUser } = useUsers();
    const { createComment, createCommentLoading } = useComments();
    const { loggedInUser } = useLoggedInUser();
    return {
      debounce,
      deletedUser,
      createComment,
      createLoading: createCommentLoading,
      loggedInUser,
      allowedContent,
    };
  },
  data() {
    return {
      showComments: false,
      addComment: false,
      focused: false,
      message: {},
      key: guid(),
    };
  },
  computed: {
    commentsAmount() {
      return this.pComments.filter((c) => c.showMeta).length;
    },
    pComments() {
      return this.comments.map((c, index) => {
        const previousComment = this.comments[index - 1];
        if (typeof previousComment === 'undefined') {
          return {
            ...c,
            showMeta: true,
          };
        }

        const diff = DateTime.fromISO(c.createdAt).diff(DateTime.fromISO(previousComment.createdAt));
        if (diff.as('minutes') < 1
            && typeof previousComment.creator !== 'undefined'
            && previousComment.creator.uid === c.creator.uid
        ) {
          return {
            ...c,
            showMeta: false,
          };
        }

        return {
          ...c,
          showMeta: true,
        };
      });
    },
    latestComment() {
      if (this.comments.length === 0) {
        return '';
      }
      const res = humanReadableDiffFromNow(DateTime.fromISO(this.comments[this.comments.length - 1].createdAt));
      return this.$t(res.translationKey, res);
    },
    commentators() {
      return this.comments.reduce((acc, comment) => {
        let creator = comment.creator;
        if (comment.creator === null) {
          creator = this.deletedUser;
        }
        const filtered = acc.filter((user) => user.uid === creator.uid);
        if (filtered.length === 0) {
          acc.push(creator);
        }

        return acc;
      }, []);
    },
  },
  methods: {
    ...mapActions(['createEntity']),
    comment() {
      this.addComment = true;
      this.$emit('write-comment');
      this.$nextTick(() => {
        this.$refs.editor.focus();
      });
    },
    onCmdOrModEnter() {
      this.submit();
      return true;
    },
    add() {
      this.comment();
      this.showComments = true;
      if (this.emitContentChange) {
        this.$nextTick(() => {
          this.$emit('update-content');
        });
      }
    },
    submit() {
      this.message = trimEmptyLines(this.message);
      if (typeof this.$refs.editor !== 'undefined') {
        this.$refs.editor.setContent(this.message);
      }

      this.createComment({ message: this.message, update: this.update }).then(() => {
        this.message = {};
        this.focused = false;
        this.key = guid();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
  watch: {
    message() {
      if (!this.emitContentChange) {
        return;
      }

      const emit = () => {
        this.$emit('update-content');
      };

      this.debounce(emit, 100);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .comment-list {
    ._divider {
      display: inline-flex;
      align-items: center;
      min-width: 40rem;
      padding: .2rem;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: $border-radius-sm;

      ._commentators {
        display: flex;
        margin-right: .8rem;

        ._user {
          margin-right: .4rem;
        }
      }

      ._amount {
        font-size: $font-size-4;
        color: $link-color;

        ._time,
        ._view-thread {
          margin-left: .4rem;
          font-size: $font-size-2;
          color: $font-color-secondary;
        }

        ._view-thread {
          display: none;
        }
      }

      &:hover {
        background-color: $hover-color;

        ._amount {
          ._time {
            display: none;
          }

          ._view-thread {
            display: unset;
          }
        }
      }
    }

    ._new {
      margin-top: .8rem;

      &.-single {
        margin-top: 0;
      }

      ._editor {
        padding: .4rem .8rem;
        margin: -.4rem -.8rem 0;
        cursor: text;
        border-radius: $border-radius-sm;

        &.-hover-editor {
          &:hover {
            background-color: $hover-color;
          }
        }

        ._inner {
          margin-bottom: 1rem;
        }

        &.-horizontal {
          display: flex;

          ._inner {
            flex: 1 1 auto;
            margin-right: 2rem;
            margin-bottom: 0;
          }

          ._btn {
            margin-left: auto;
          }
        }
      }
    }

    ._item {
      &:not(.-first) {
        &.-show-meta {
          margin-top: 1.2rem;
        }
      }
    }
  }
</style>

<template>
  <m-content
    :padding-x="9"
    :padding-y="9"
    class="app-feedback"
  >
    <m-btn
      fab
      icon="close"
      xs
      light
      hide-border
      class="_hide-btn"
      @click="clickCloseButton"
    />
    <div class="_left">
      <div class="_author">
        <div class="_image">
          <img
            src="@/assets/img/laura-from-mooncamp.png"
            :alt="$t('appFeedback.avatarName')"
          >
        </div>
      </div>
    </div>
    <div class="_right">
      <div class="_name">
        {{ $t('appFeedback.avatarName') }}
      </div>
      <div class="_steps">
        <div
          v-if="step === 1"
          class="_step"
        >
          <div class="_bubble">
            <p class="_greeting">
              <i18n-t
                keypath="appFeedback.greeting"
                tag="p"
                class="_greeting"
              >
                <template #name>
                  {{ loggedInUser.firstName }}
                </template>
              </i18n-t>
            </p>
            <p class="_question">
              {{ $t('appFeedback.npsQuestion') }}
            </p>
          </div>
          <div
            class="_nps"
          >
            <div class="_tiles">
              <div
                v-for="(_, index) in 11"
                :key="index"
                :class="['_tile', selectedValue === index ? '-selected': '']"
                @click="selectValue(index)"
              >
                {{ index }}
              </div>
            </div>
            <div class="_description">
              <div class="_left">
                {{ $t('appFeedback.npsScale.left') }}
              </div>
              <div class="_right">
                {{ $t('appFeedback.npsScale.right') }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="step === 2"
          class="_step -step-2"
        >
          <div class="_bubble">
            <p class="_question">
              {{ $t('appFeedback.followUp') }}
            </p>
          </div>
          <m-textarea
            v-model:value="comment"
            class="_comment"
            :rows="3"
            full-width
            auto-focus
            :placeholder="$t('appFeedback.hint')"
          />
          <div class="_buttons">
            <m-btn
              @click="goBack"
            >
              {{ $t('general.back') }}
            </m-btn>
            <m-btn
              color="primary"
              class="_send"
              @click="submit"
            >
              {{ $t('general.send') }}
            </m-btn>
          </div>
        </div>
        <div
          v-if="step === 3"
          class="_step -step-3"
        >
          <div
            v-if="[9,10].includes(selectedValue)"
            class="_thank-you"
          >
            <div class="_bubble">
              <p>
                {{ $t('appFeedback.referral.first') }}
              </p>
              <p>
                {{ $t('appFeedback.referral.second') }}
              </p>
              <p>
                <i18n-t
                  keypath="appFeedback.referral.third"
                  tag="strong"
                >
                  <template #name>
                    {{ referral.name }}
                  </template>
                </i18n-t>
              </p>
            </div>
            <div>
              <div class="_buttons">
                <m-btn
                  @click="clickCloseButton"
                >
                  {{ $t('appFeedback.referral.noButton') }}
                </m-btn>
                <m-btn
                  :href="referral.link"
                  color="primary"
                  target="_blank"
                  class="_right-button"
                  @click="clickReferralButton"
                >
                  {{ $t('appFeedback.referral.yesButton') }}
                  <m-icon
                    class="_icon"
                    type="external-link"
                    :style="{ marginLeft: '.8rem' }"
                  />
                </m-btn>
              </div>
            </div>
          </div>
          <div
            v-else
            class="_thank-you"
          >
            <div class="_bubble">
              <p>
                <strong>
                  {{ $t('appFeedback.thankYou.first') }}
                </strong>
              </p>
              <p>
                {{ $t('appFeedback.thankYou.second') }}
              </p>
            </div>
            <div class="_buttons">
              <m-btn
                @click="clickCloseButton"
              >
                {{ $t('general.close') }}
              </m-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </m-content>
</template>

<script>
import useAppFeedback from '@/composables/app-feedback/app-feedback';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { appFeedbackType } from 'shared/constants.json';

export default {
  name: 'AppFeedback',
  emits: ['close'],
  data() {
    return {
      selectedValue: null,
      comment: '',
      step: 1,
      uid: null,
    };
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { mutateAppFeedback } = useAppFeedback();
    return {
      account: loggedInUserAccount,
      loggedInUser,
      mutateAppFeedback,
    };
  },
  computed: {
    referral() {
      return {
        name: 'G2.com',
        link: 'https://www.g2.com/products/mooncamp/reviews',
        key: 'g2',
      };
    },
  },
  methods: {
    selectValue(val) {
      this.selectedValue = val;
      this.step = 2;
    },
    saveEntity(referralLinkClicked, ignored) {
      this.mutateAppFeedback({
        user: { uid: this.loggedInUser.uid },
        account: { uid: this.account.uid },
        value: this.selectedValue,
        comment: this.comment,
        referralLinkClicked,
        referralTarget: this.referral.key,
        ignored,
        type: appFeedbackType.nps,
      }).then((entity) => {
        this.uid = entity.uid;
      }).catch(() => {
      });
    },
    submit() {
      this.step = 3;
    },
    clickReferralButton() {
      this.saveEntity(true, false);
      this.$emit('close');
    },
    clickCloseButton() {
      this.saveEntity(false, this.step !== 3);
      this.$emit('close');
    },
    goBack() {
      this.step -= 1;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .app-feedback {
    position: relative;
    display: flex;
    width: 100%;

    ._hide-btn {
      position: absolute;
      top: .8rem;
      right: .8rem;
    }

    ._left {
      margin-right: 1rem;

      ._image {
        img {
          width: 4.1rem;
          height: auto;
        }
      }
    }

    ._right {
      width: 100%;
      padding-right: .8rem;

      ._name {
        padding-left: .4rem;
        font-size: $font-size-2;
        color: $font-color-secondary;
      }

      ._bubble {
        padding: .8rem 1.6rem;
        margin: .8rem 0 1.2rem;
        background-color: map_get($grey, 'lighten-4');
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;

        ._greeting {
          font-weight: $font-weight-bold;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      ._nps {
        margin-bottom: .8rem;

        ._tiles {
          display: flex;
          justify-content: space-between;

          ._tile {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 4rem;
            height: 4rem;
            padding: .8rem;
            margin: .4rem;
            font-weight: $font-weight-semibold;
            cursor: pointer;
            background-color: map_get($grey, 'lighten-6');
            border-radius: .4rem;

            &.-selected {
              background-color: $hover-color-dark;
            }

            &:hover {
              background-color: $hover-color-dark;
            }

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        ._description {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: .4rem;
          font-size: $font-size-2;
          color: $font-color-secondary;

          ._left {
            flex: 1;
          }

          ._right {
            flex: 1;
            text-align: right;
          }
        }
      }

      ._thank-you {
        ._buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 2rem;

          ._right-button {
            margin-left: 1.2rem;
          }
        }
      }

      ._steps {
        ._step {
          &.-step-2 {
            ._bubble {
              animation: slide-up ease-in-out .3s;
            }

            ._comment {
              animation: slide-up ease-in-out .3s;
            }

            ._buttons {
              display: flex;
              justify-content: space-between;
              margin-top: 1.6rem;
            }
          }

          &.-step-3 {
            ._bubble {
              animation: slide-up ease-in-out .3s;
            }

            ._comment {
              animation: slide-up ease-in-out .3s;
            }
          }

          @keyframes slide-up {
            0% {
              transform: translateY(1rem);
            }

            100% {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }

</style>

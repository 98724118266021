<template>
  <m-content
    :padding-xxs="!noPadding"
    :class="['goal-progress', showIndicator ? '-show-indicator' : '', clickable ? '-clickable': '']"
    :style="progressBarWidth === '100%' ? { flexGrow: '1' } : { flexGrow: '0' }"
    @click="emitClick($event)"
    @mousedown="emitClick($event)"
  >
    <div class="_wrapper">
      <div
        v-if="dataSourceQuery !== null && showSmallDataSourceIndicator"
        class="_indicator-small"
      >
        <m-dropdown
          v-model:value="showIndicator"
          :title="$t('goalProgress.dataConnectionTitle')"
        >
          <m-btn
            class="_icon"
            hide-border
            xs
            fab
            :icon="dataSourceIcon"
            @click.stop="showIndicator = true"
          />
          <template #overlay>
            <m-card
              no-padding
              list
              class="_overlay"
            >
              <data-source-summary
                :goal="goal"
              />
            </m-card>
          </template>
        </m-dropdown>
      </div>
      <div
        :ref="(el) => progressElements.progress = el"
        :class="['_container', hideDetails ? '-hide-details' : '']"
        :style="resolveStyles(mStyle)"
      >
        <div
          class="_inner"
          :style="progressBarWidth === '100%' ? { flexGrow: '1' } : { flexGrow: '0' }"
        >
          <div
            v-if="!hideDetails"
            class="_top"
          >
            <div
              :ref="(el) => progressElements.current = el"
              class="_number"
              :style="currentStyle"
            >
              {{ formatLabeledNumber(current, metric, userLang) }}
            </div>
            <div
              :ref="(el) => progressElements.calculatedCurrent = el"
              class="_number -light -stick-right"
            >
              {{ formatLabeledNumber(calculatedCurrent, '%', userLang) }}
            </div>
          </div>
          <div
            class="_progress"
            :style="{ width: progressBarWidth }"
          >
            <div
              class="_bar"
              :style="style"
            />
          </div>
          <div
            v-if="!hideDetails"
            class="_bottom"
          >
            <div class="_left">
              {{ formatLabeledNumber(start, progressMetric, userLang) }}
            </div>
            <div class="_right">
              {{ formatLabeledNumber(end, progressMetric, userLang) }}
            </div>
          </div>
        </div>
        <div
          v-if="hideDetails && displayOption.absolute || displayOption.full"
          class="_behind"
        >
          {{ formatLabeledNumber(current, metric, userLang) }}
        </div>
        <div
          v-if="hideDetails && displayOption.relative"
          class="_behind"
        >
          {{ formatLabeledNumber(calculatedCurrent, '%', userLang) }}
        </div>
        <div
          v-if="hideDetails && displayOption.full"
          class="_behind -light"
        >
          {{ formatLabeledNumber(calculatedCurrent, '%', userLang) }}
        </div>
      </div>
    </div>
  </m-content>
</template>

<script>
import DataSourceSummary from '@/components/datasource/DataSourceSummary.vue';
import camelCase from 'lodash-es/camelCase';
import useGoalDatasource from '@/composables/goal/goal-datasource';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { aggregationMethod, goalProgressMeasurement, progressDisplayOption, propertyType } from 'shared/constants.json';
import { computed, onMounted, onUnmounted, ref, toRef } from 'vue';
import { current, formatLabeledNumber } from '@/lib/goal/progress';
import { experimentFlag } from 'shared/experiments.json';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { translatePropertyOptionColor } from '@/lib/goal/status';

export default {
  name: 'GoalProgress',
  props: {
    ...mStyleProps,
    goal: {
      type: Object,
      required: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    showSmallDataSourceIndicator: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    progressBarWidth: {
      type: String,
      default: '100%',
    },
    progressDisplayOption: {
      type: String,
      default: progressDisplayOption.absolute,
    },
  },
  emits: ['click'],
  components: { DataSourceSummary },
  setup(props) {
    const goal = toRef(props, 'goal');
    const { userLang } = useLoggedInUser();
    const { query: dataSourceQuery, icon: dataSourceIcon } = useGoalDatasource(goal);

    const progressElements = ref({
      current: null,
      calculatedCurrent: null,
      progress: null,

      currentWidth: 0,
      calculatedCurrentWidth: 0,
      progressWidth: 0,
    });

    const displayOption = computed(() => {
      let option = props.progressDisplayOption;
      if (option === undefined || option === null) {
        option = progressDisplayOption.absolute;
      }

      return {
        absolute: option === progressDisplayOption.absolute,
        relative: option === progressDisplayOption.relative,
        full: option === progressDisplayOption.full,
      };
    });

    const currentObserver = new ResizeObserver((v) => { progressElements.value.currentWidth = v[0].target.clientWidth; });
    const calculatedCurrentObserver = new ResizeObserver((v) => { progressElements.value.calculatedCurrentWidth = v[0].target.clientWidth; });
    const progressObserver = new ResizeObserver((v) => { progressElements.value.progressWidth = v[0].target.clientWidth; });

    onMounted(() => {
      if (progressElements.value.current !== null) {
        currentObserver.observe(progressElements.value.current);
      }
      if (progressElements.value.calculatedCurrent !== null) {
        calculatedCurrentObserver.observe(progressElements.value.calculatedCurrent);
      }
      if (progressElements.value.progress !== null) {
        progressObserver.observe(progressElements.value.progress);
      }
    });

    onUnmounted(() => {
      currentObserver.disconnect();
      calculatedCurrentObserver.disconnect();
      progressObserver.disconnect();
    });

    return { dataSourceQuery, dataSourceIcon, userLang, experimentFlag, progressElements, displayOption, formatLabeledNumber };
  },
  data() {
    return {
      showIndicator: false,
      camelCase,
    };
  },
  computed: {
    current() {
      const c = current(this.goal, this.goal.cachedCurrent);

      if ([goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(this.goal.progressMeasurement)) {
        return c;
      }

      if (this.goal.progressMeasurement === goalProgressMeasurement.alignedItems && this.goal.aggregationMethod === aggregationMethod.absolute) {
        return c;
      }

      return this.calculatedCurrent;
    },
    calculatedCurrent() {
      return Math.round(this.goal.cachedCalculatedCurrent);
    },
    progressMetric() {
      switch (this.goal.progressMeasurement) {
        case goalProgressMeasurement.continuous:
          return this.goal.metric;
        case goalProgressMeasurement.alignedItems:
          switch (this.goal.aggregationMethod) {
            case aggregationMethod.absolute:
              return this.goal.metric;
            default:
              return '%';
          }

        default:
          return '%';
      }
    },
    metric() {
      switch (this.goal.progressMeasurement) {
        case goalProgressMeasurement.continuous:
        case goalProgressMeasurement.threshold:
          return this.goal.metric;
        case goalProgressMeasurement.alignedItems:
          switch (this.goal.aggregationMethod) {
            case aggregationMethod.absolute:
              return this.goal.metric;
            default:
              return '%';
          }
        default:
          return '%';
      }
    },
    percent() {
      return Math.round(Math.min(100, this.goal.cachedCalculatedCurrent));
    },
    style() {
      return {
        width: `${this.percent}%`,
        backgroundColor: this.color,
      };
    },
    currentStyle() {
      if ((this.percent / 100) * this.progressElements.progressWidth <= this.progressElements.currentWidth) {
        return {
          left: '0',
          color: this.color,
        };
      }

      if (this.progressElements.calculatedCurrentWidth === 0) {
        return {
          right: `${100 - this.percent}%`,
          color: this.color,
        };
      }

      const withPaddingCalculatedCurrentWidth = this.progressElements.calculatedCurrentWidth + 3;
      let rightPosition = this.progressElements.progressWidth - ((this.percent / 100) * this.progressElements.progressWidth);

      if (rightPosition < withPaddingCalculatedCurrentWidth) {
        rightPosition = withPaddingCalculatedCurrentWidth;
      }

      return {
        right: `${rightPosition}px`,
        color: this.color,
      };
    },
    color() {
      const propertyValue = this.goal.properties.find((pv) => pv.property.type === propertyType.status);
      if (propertyValue.selectedOptions.length === 0) {
        return this.$colors.grey.base;
      }

      return translatePropertyOptionColor(propertyValue.selectedOptions[0]);
    },
    start() {
      if (this.goal.progressMeasurement === goalProgressMeasurement.continuous) {
        return this.goal.start;
      }

      if (this.goal.progressMeasurement === goalProgressMeasurement.alignedItems && this.goal.aggregationMethod === aggregationMethod.absolute) {
        return this.goal.start;
      }

      return 0;
    },
    end() {
      if (this.goal.progressMeasurement === goalProgressMeasurement.continuous) {
        return this.goal.end;
      }

      if (this.goal.progressMeasurement === goalProgressMeasurement.alignedItems && this.goal.aggregationMethod === aggregationMethod.absolute) {
        return this.goal.end;
      }

      return 100;
    },
  },
  methods: {
    resolveStyles,
    emitClick(event) {
      if (this.clickable) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._overlay {
    width: 30rem;
  }

  .goal-progress {
    flex: 0 0 auto;

    &.-clickable {
      cursor: pointer;
    }

    ._wrapper {
      position: relative;
      display: flex;
      align-items: center;

      ._indicator-small {
        margin-right: .4rem;
      }

      ._container {
        flex: 1 1 auto;

        &.-no-metric {
          min-width: 0;
        }

        ._inner {
          flex: 0 0 auto;
          line-height: 1;

          &.-no-metric {
            display: flex;
            justify-content: flex-end;
            text-align: right;
          }

          ._top {
            position: relative;
            width: 100%;
            height: 1.6rem;
            margin-bottom: .2rem;

            ._number {
              top: 0;
              font-weight: $font-weight-semibold;
              white-space: nowrap;
              position: absolute;

              &.-light {
                color: $font-color-tertiary;
              }

              &.-stick-right {
                right: 0;
              }
            }

          }

          ._progress {
            width: 100%;
            height: 1.2rem;
            margin-bottom: .8rem;
            overflow: hidden;
            background-color: map_get($grey, 'lighten-3');
            border-radius: .6rem;

            ._bar {
              height: inherit;
              background-color: $primary-color;
              transition: width 1s ease-out;
            }
          }

          ._bottom {
            display: flex;

            ._right {
              margin-left: auto;
              text-align: right;
            }
          }
        }

        &.-hide-details {
          display: flex;
          align-items: center;

          ._inner {
            ._progress {
              margin-bottom: 0;
            }
          }
        }

        ._behind {
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          margin-left: .4rem;
          text-align: left;

          &.-light {
                color: $font-color-tertiary;
              }
        }
      }
    }

    &.-show-indicator {
      ._wrapper {
        position: relative;
      }
    }
  }
</style>

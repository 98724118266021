import { Entity } from '@/nebula/entity';
import {
  accessGroup as accessGroupConfig,
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  account as accountConfig,
  accountSettings as accountSettingsConfig,
  appFeedback as appFeedbackConfig,
  appIntegration as appIntegrationConfig,
  asanaEntity as asanaEntityConfig,
  asanaQuery as asanaQueryConfig,
  comment as commentConfig,
  companyInfo as companyInfoConfig,
  customerContract as customerContractConfig,
  dataSource as dataSourceConfig,
  favorite as favoriteConfig,
  feedEntry as feedEntryConfig,
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  goalCycle as goalCycleConfig,
  goalSettings as goalSettingsConfig,
  goalSubscription as goalSubscriptionConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileIframe as gridPageTileIframeConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
  hubspotQuery as hubspotQueryConfig,
  invitation as invitationConfig,
  jiraQuery as jiraQueryConfig,
  mSTeamsConfiguration as mSTeamsConfigurationConfig,
  msPlannerQuery as msPlannerQueryConfig,
  msPowerBiQuery as msPowerBiQueryConfig,
  notification as notificationConfig,
  notificationReminder as notificationReminderConfig,
  notificationSetting as notificationSettingConfig,
  numberRange as numberRangeConfig,
  oauthCodeGrantClient as oauthCodeGrantClientConfig,
  personalAppSettings as personalAppSettingsConfig,
  pin as pinConfig,
  planning as planningConfig,
  printable as printableConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  propertyValue as propertyValueConfig,
  provisionedNames as provisionedNamesConfig,
  provisionedResource as provisionedResourceConfig,
  provisionedUser as provisionedUserConfig,
  reaction as reactionConfig,
  resourceSettings as resourceSettingsConfig,
  salesforceQuery as salesforceQueryConfig,
  saml as samlConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  space as spaceConfig,
  spreadsheetCell as spreadsheetCellConfig,
  storageObject as storageObjectConfig,
  timeRange as timeRangeConfig,
  update as updateConfig,
  updateTemplate as updateTemplateConfig,
  user as userConfig,
  userProvisioning as userProvisioningConfig,
  userProvisioningMapping as userProvisioningMappingConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
  viewCollection as viewCollectionConfig,
} from 'shared/api/query/configs.json';
import { customDatasourceProperty } from 'shared/constants.json';

import { sortByArray } from 'shared/lib/sort';
import { union } from 'shared/lib/array/array';

// simple reference only keeps the 'uid' field it was originally introduced to
// support reverse edges for references that only require the 'uid' field
const simpleReference = new Entity(
  'simpleReference',
  {},
  {
    processStrategy(value) {
      return { uid: value.uid };
    },
    simple: true,
  },
);

const numberRange = new Entity(numberRangeConfig.model);
const timeRange = new Entity(timeRangeConfig.model);
const userScope = new Entity(
  userScopeConfig.model,
  {
    numberRange,
    timeRange,
  },
  { exported: false },
);
const userScopeTree = new Entity(
  userScopeTreeConfig.model,
  { [userScopeTreeConfig.edges.scope]: userScope },
  { exported: false },
);
userScopeTree.define({ [userScopeTreeConfig.edges.children]: [userScopeTree] });

const accessPolicyScope = new Entity(
  accessPolicyScopeConfig.model,
  { [accessPolicyScopeConfig.edges.scope]: userScopeTree },
  { exported: false },
);

const accessPolicy = new Entity(
  accessPolicyConfig.model,
  { [accessPolicyConfig.edges.scopes]: [accessPolicyScope] },
  { exported: false },
);

const accessGroup = new Entity(
  accessGroupConfig.model,
  { [accessGroupConfig.edges.accessPolicy]: accessPolicy },
  { exported: true },
);

const accountSettings = new Entity(
  accountSettingsConfig.model,
  {},
  { exported: true },
);

const resourceSettings = new Entity(
  resourceSettingsConfig.model,
  {},
  { exported: true },
);

const goalSettings = new Entity(
  goalSettingsConfig.model,
  { [goalSettingsConfig.edges.defaultAccessPolicy]: accessPolicy },
  { exported: true },
);

const appIntegration = new Entity(
  appIntegrationConfig.model,
  {},
  { exported: true },
);

const saml = new Entity(
  samlConfig.model,
  { },
  { exported: true, reverseEdges: { [samlConfig.edges.account]: { model: accountConfig.model, rEdge: 'saml' } } },
);

const userProvisioningMapping = new Entity(
  userProvisioningMappingConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [userProvisioningMappingConfig.edges.userProvisioning]: { model: userProvisioningConfig.model, rEdge: 'mappings' } },
  },
);

const oauthCodeGrantClient = new Entity(
  oauthCodeGrantClientConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [oauthCodeGrantClientConfig.edges.userProvisioning]: { model: userProvisioningConfig.model, rEdge: 'oauthCodeGrantClient' } },
  },
);

const provisionedNames = new Entity(provisionedNamesConfig.model, {}, { exported: false });

const provisionedResource = new Entity(provisionedResourceConfig.model, {}, { exported: false });

const provisionedUser = new Entity(
  provisionedUserConfig.model,
  {
    [provisionedUserConfig.edges.name]: provisionedNames,
    [provisionedUserConfig.edges.emails]: [provisionedResource],
  },
  { exported: false },
);

const userProvisioning = new Entity(
  userProvisioningConfig.model,
  {
    mappings: [userProvisioningMapping],
    oauthCodeGrantClient,
    provisionedUsers: [provisionedUser],
  },
  { exported: true, reverseEdges: { [userProvisioningConfig.edges.account]: { model: accountConfig.model, rEdge: 'userProvisioning' } } },
);

const customerContract = new Entity(
  customerContractConfig.model,
  {},
);

const storageObject = new Entity(
  storageObjectConfig.model,
  {},
);

const companyInfo = new Entity(
  companyInfoConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [companyInfoConfig.edges.account]: { model: accountConfig.model, rEdge: 'companyInfo' } },
  },
);

const account = new Entity(
  accountConfig.model,
  {
    [accountConfig.edges.accountSettings]: accountSettings,
    [accountConfig.edges.resourceSettings]: resourceSettings,
    [accountConfig.edges.goalSettings]: goalSettings,
    saml,
    userProvisioning,
    [accountConfig.edges.companyImage]: storageObject,
    [accountConfig.edges.appIntegration]: appIntegration,
    [accountConfig.edges.customerContract]: customerContract,
    companyInfo,
  },
  { exported: true },
);

const notificationSetting = new Entity(
  notificationSettingConfig.model,
  { },
  { exported: true },
);

const personalAppSettings = new Entity(
  personalAppSettingsConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [personalAppSettingsConfig.edges.user]: { model: userConfig.model, rEdge: 'personalAppSettings' } },
  },
);

const invitation = new Entity(
  invitationConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [invitationConfig.edges.recipient]: { model: userConfig.model, rEdge: 'invitation' } },
  },
);

const mSTeamsConfiguration = new Entity(
  mSTeamsConfigurationConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [mSTeamsConfigurationConfig.edges.user]: { model: userConfig.model, rEdge: 'msTeamsConfiguration' } },
  },
);

const user = new Entity(
  userConfig.model,
  {
    [userConfig.edges.appIntegration]: appIntegration,
    [userConfig.edges.notificationSetting]: notificationSetting,
    [userConfig.edges.profileImage]: storageObject,
    personalAppSettings,
    invitation,
    msTeamsConfiguration: mSTeamsConfiguration,
  },
  { exported: true },
);

const goalCycle = new Entity(
  goalCycleConfig.model,
  {},
  { exported: true },
);

personalAppSettings.define({ [personalAppSettingsConfig.edges.selectedGoalCycles]: [goalCycle] });

const viewCollection = new Entity(
  viewCollectionConfig.model,
  {},
);

const savedView = new Entity(
  savedViewConfig.model,
  { [savedViewConfig.edges.goalCycles]: [goalCycle] },
  {
    exported: true,
    processStrategy(value) {
      if (value.params === undefined) {
        return { ...value };
      }
      if (value.params.filter === undefined || value.params.filter === null) {
        return { ...value };
      }
      if (value.params.filter.children === undefined) {
        value.params.filter.children = [];
      }
      return { ...value };
    },
  },
);

const selectedView = new Entity(
  selectedViewConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [selectedViewConfig.edges.user]: { model: userConfig.model, rEdge: 'selectedView' } },
  },
);

accessPolicyScope.define({ [accessPolicyScopeConfig.edges.users]: [user] });

const propertySettings = new Entity(
  propertySettingsConfig.model,
  {},
  {
    exported: true,
    reverseEdges: { [propertySettingsConfig.edges.propertyOption]: { model: propertyOptionConfig.model, rEdge: 'propertySettings' } },
  },
);

const propertyOption = new Entity(
  propertyOptionConfig.model,
  {
    [propertyOptionConfig.edges.image]: storageObject,
    propertySettings: [propertySettings],
  },
  {
    exported: true,
    reverseEdges: { [propertyOptionConfig.edges.property]: { model: propertyConfig.model, rEdge: 'options' } },
  },
);

const space = new Entity(
  spaceConfig.model,
  {
    [spaceConfig.edges.image]: storageObject,
    [spaceConfig.edges.parents]: [simpleReference],
    children: [simpleReference],
  },
  {
    exported: true,
    reverseEdges: { [spaceConfig.edges.parents]: { model: spaceConfig.model, rEdge: 'children' } },
  },
);

const property = new Entity(
  propertyConfig.model,
  { options: [propertyOption] },
  {
    exported: true,
    processStrategy: (value) => {
      if (value.options === undefined || value.options.length === 0 || value.propertyOptionOrder === undefined) {
        return { ...value };
      }
      value.options.sort(sortByArray(value.propertyOptionOrder));
      return {
        ...value,
        options: value.options,
      };
    },
  },
);

property.define({
  [propertyConfig.edges.lookupRelation]: property,
  [propertyConfig.edges.lookupProperty]: property,
});

const propertyValue = new Entity(
  propertyValueConfig.model,
  {
    [propertyValueConfig.edges.property]: property,
    [propertyValueConfig.edges.selectedOptions]: [propertyOption],
    [propertyValueConfig.edges.spaces]: [space],
  },
);

userProvisioningMapping.define({
  [userProvisioningMappingConfig.edges.property]: property,
  [userProvisioningMappingConfig.edges.space]: space,
  [userProvisioningMappingConfig.edges.propertyOption]: propertyOption,
});

userScope.define({
  [userScopeConfig.edges.users]: [user],
  [userScopeConfig.edges.staticUsers]: [user],
  [userScopeConfig.edges.selectedOptions]: [propertyOption],
  [userScopeConfig.edges.property]: property,
});

mSTeamsConfiguration.define({ [mSTeamsConfigurationConfig.edges.selectedTeam]: space });

space.define({ [spaceConfig.edges.properties]: [propertyValue] });

const favorite = new Entity(
  favoriteConfig.model,
  { [favoriteConfig.edges.image]: storageObject },
  { exported: true },
);

const pin = new Entity(
  pinConfig.model,
  { [pinConfig.edges.space]: space },
  { exported: true },
);

const goalSubscription = new Entity(
  goalSubscriptionConfig.model,
  { [goalSubscriptionConfig.edges.user]: user },
);

const planning = new Entity(
  planningConfig.model,
  {
    [planningConfig.edges.creator]: user,
    [planningConfig.edges.properties]: [propertyValue],
    [planningConfig.edges.goalCycles]: [goalCycle],
    [planningConfig.edges.accessPolicy]: accessPolicy,
  },
  { exported: true },
);

const spreadsheetDocument = new Entity(
  'spreadsheetDocument',
  {},
  {
    idAttribute: 'id',
    processStrategy(value) {
      return {
        ...value,
        uid: value.id,
      };
    },
  },
);

const asanaProject = new Entity(
  'asanaProject',
  {},
  {
    idAttribute: 'gid',
    processStrategy(value) {
      return {
        ...value,
        uid: value.gid,
      };
    },
  },
);

const salesforceReport = new Entity(
  'salesforceReport',
  {},
  {
    idAttribute: 'id',
    processStrategy(value) {
      return {
        ...value,
        uid: value.id,
      };
    },
  },
);

const msPlannerPlan = new Entity(
  'msPlannerPlan',
  {},
  {
    idAttribute: 'id',
    processStrategy(value) {
      return {
        ...value,
        uid: value.id,
      };
    },
  },
);

const dataSource = new Entity(
  dataSourceConfig.model,
  {
    [customDatasourceProperty.spreadsheetDocuments]: [spreadsheetDocument],
    [customDatasourceProperty.asanaProjects]: [asanaProject],
    [customDatasourceProperty.salesforceReports]: [salesforceReport],
    [customDatasourceProperty.msPlannerPlans]: [msPlannerPlan],
  },
  {
    exported: true,
    mergeStrategy(a, b) {
      return {
        ...a,
        ...b,
        [customDatasourceProperty.spreadsheetDocuments]: union(a[customDatasourceProperty.spreadsheetDocuments], b[customDatasourceProperty.spreadsheetDocuments]),
        [customDatasourceProperty.asanaProjects]: union(a[customDatasourceProperty.asanaProjects], b[customDatasourceProperty.asanaProjects]),
        [customDatasourceProperty.salesforceReports]: union(a[customDatasourceProperty.salesforceReports], b[customDatasourceProperty.salesforceReports]),
        [customDatasourceProperty.msPlannerPlans]: union(a[customDatasourceProperty.msPlannerPlans], b[customDatasourceProperty.msPlannerPlans]),
      };
    },
  },
);

user.define({
  selectedView: [selectedView],
  [userConfig.edges.values]: [propertyValue],
});

const spreadsheetCell = new Entity(
  spreadsheetCellConfig.model,
  { [spreadsheetCellConfig.edges.dataSource]: dataSource },
  {
    exported: true,
    reverseEdges: { [spreadsheetCellConfig.edges.goal]: { model: goalConfig.model, rEdge: 'spreadsheetCell' } },
  },
);

const jiraQuery = new Entity(
  jiraQueryConfig.model,
  { [jiraQueryConfig.edges.dataSource]: dataSource },
  {
    exported: true,
    reverseEdges: { [jiraQueryConfig.edges.goal]: { model: goalConfig.model, rEdge: 'jiraQuery' } },
  },
);

const hubspotQuery = new Entity(
  hubspotQueryConfig.model,
  { [hubspotQueryConfig.edges.dataSource]: dataSource },
  {
    exported: true,
    reverseEdges: { [hubspotQueryConfig.edges.goal]: { model: goalConfig.model, rEdge: 'hubspotQuery' } },
  },
);

const asanaEntity = new Entity(
  asanaEntityConfig.model,
  {},
);

const asanaQuery = new Entity(
  asanaQueryConfig.model,
  {
    [asanaQueryConfig.edges.dataSource]: dataSource,
    [asanaQueryConfig.edges.assignedTo]: [asanaEntity],
    [asanaQueryConfig.edges.sections]: [asanaEntity],
    [asanaQueryConfig.edges.overallAssignedTo]: [asanaEntity],
    [asanaQueryConfig.edges.overallSections]: [asanaEntity],
    [asanaQueryConfig.edges.countAssignedTo]: [asanaEntity],
    [asanaQueryConfig.edges.countSections]: [asanaEntity],
    [asanaQueryConfig.edges.task]: asanaEntity,
    [asanaQueryConfig.edges.taskCompletionSections]: [asanaEntity],
  },
  {
    exported: true,
    reverseEdges: { [asanaQueryConfig.edges.goal]: { model: goalConfig.model, rEdge: 'asanaQuery' } },
  },
);

const salesforceQuery = new Entity(
  salesforceQueryConfig.model,
  { [salesforceQueryConfig.edges.dataSource]: dataSource },
  {
    exported: true,
    reverseEdges: { [salesforceQueryConfig.edges.goal]: { model: goalConfig.model, rEdge: 'salesforceQuery' } },
  },
);

const msPowerBiQuery = new Entity(
  msPowerBiQueryConfig.model,
  { [msPowerBiQueryConfig.edges.dataSource]: dataSource },
  {
    exported: true,
    reverseEdges: { [msPowerBiQueryConfig.edges.goal]: { model: goalConfig.model, rEdge: 'msPowerBiQuery' } },
  },
);

const msPlannerQuery = new Entity(
  msPlannerQueryConfig.model,
  { [msPlannerQueryConfig.edges.dataSource]: dataSource },
  {
    exported: true,
    reverseEdges: { [msPlannerQueryConfig.edges.goal]: { model: goalConfig.model, rEdge: 'msPlannerQuery' } },
  },
);

const appFeedback = new Entity(
  appFeedbackConfig.model,
  {},
  { exported: true },
);

const goal = new Entity(
  goalConfig.model,
  {
    [goalConfig.edges.goalCycle]: [goalCycle],
    [goalConfig.edges.properties]: [propertyValue],
    [goalConfig.edges.subscriptions]: [goalSubscription],
    [goalConfig.edges.accessPolicy]: accessPolicy,
    spreadsheetCell,
    hubspotQuery,
    salesforceQuery,
    jiraQuery,
    asanaQuery,
    msPowerBiQuery,
    msPlannerQuery,
    [goalConfig.edges.parents]: [simpleReference],
    children: [simpleReference],
  },
  {
    exported: true,
    reverseEdges: { [goalConfig.edges.parents]: { model: goalConfig.model, rEdge: 'children' } },
  },
);

const goalActivity = new Entity(
  goalActivityConfig.model,
  {
    [goalActivityConfig.edges.properties]: [propertyValue],
    [goalActivityConfig.edges.previousProperties]: [propertyValue],
    [goalActivityConfig.edges.goal]: goal,
  },
  {
    excludeFromDenormalization: { [goalActivityConfig.edges.goal]: true },
    exported: true,
    processStrategy(value) {
      return { ...value, referenced: undefined };
    },
  },
);

const reaction = new Entity(
  reactionConfig.model,
  { [reactionConfig.edges.creator]: user },
  { exported: true },
);

const comment = new Entity(
  commentConfig.model,
  { [commentConfig.edges.creator]: user },
  { exported: true },
);

const update = new Entity(
  updateConfig.model,
  {
    [updateConfig.edges.creator]: user,
    [updateConfig.edges.image]: storageObject,
    [updateConfig.edges.goalActivities]: [goalActivity],
    [updateConfig.edges.properties]: [propertyValue],
    reactions: [reaction],
    comments: [comment],
  },
  { exported: true },
);

const updateTemplate = new Entity(
  updateTemplateConfig.model,
  {
    [updateTemplateConfig.edges.propertyValues]: [propertyValue],
    [updateTemplateConfig.edges.creator]: user,
    [updateTemplateConfig.edges.accessPolicy]: accessPolicy,
    [updateTemplateConfig.edges.image]: storageObject,
    [updateTemplateConfig.edges.goalTypes]: [propertyOption],
    [updateTemplateConfig.edges.userProperties]: [property],
  },
  { exported: true },
);

const notificationReminder = new Entity(
  notificationReminderConfig.model,
  {},
);

const notification = new Entity(
  notificationConfig.model,
  {
    [notificationConfig.edges.creator]: user,
    [notificationConfig.edges.recipients]: userScopeTree,
    [notificationConfig.edges.recipientsList]: [user],
    [notificationConfig.edges.accessPolicy]: accessPolicy,
    [notificationConfig.edges.reminders]: [notificationReminder],
  },
  { exported: true },
);

const feedEntry = new Entity(
  feedEntryConfig.model,
  {
    [feedEntryConfig.edges.actor]: user,
    [feedEntryConfig.edges.goal]: goal,
    [feedEntryConfig.edges.update]: update,
    [feedEntryConfig.edges.comment]: comment,
    [feedEntryConfig.edges.propertyValue]: propertyValue,
    [feedEntryConfig.edges.notification]: notification,
  },
  { exported: true },
);

const gridPageTileText = new Entity(
  gridPageTileTextConfig.model,
  {},
  { exported: true },
);

const gridPageTileIframe = new Entity(
  gridPageTileIframeConfig.model,
  {},
  { exported: true },
);

const gridPageTileSingleGoal = new Entity(
  gridPageTileSingleGoalConfig.model,
  {},
  { exported: true },
);

const gridPageTile = new Entity(
  gridPageTileConfig.model,
  {},
  { exported: true, reverseEdges: { [gridPageTileConfig.edges.gridPageRow]: { model: gridPageRowConfig.model, rEdge: 'tiles' } } },
);

const gridPageRow = new Entity(
  gridPageRowConfig.model,
  { tiles: [gridPageTile] },
  { exported: true, reverseEdges: { [gridPageRowConfig.edges.gridPage]: { model: gridPageConfig.model, rEdge: 'rows' } } },
);

const gridPage = new Entity(
  gridPageConfig.model,
  {
    [gridPageConfig.edges.creator]: user,
    [gridPageConfig.edges.accessPolicy]: accessPolicy,
    [gridPageConfig.edges.image]: storageObject,
    rows: [gridPageRow],
  },
  { exported: true },
);

const printable = new Entity(
  printableConfig.model,
  {
    [printableConfig.edges.creator]: user,
    [printableConfig.edges.gridPage]: gridPage,
  },
);

export default {
  accessGroup,
  accessPolicy,
  accessPolicyScope,
  account,
  accountSettings,
  appFeedback,
  appIntegration,
  asanaQuery,
  asanaEntity,
  asanaProject,
  comment,
  companyInfo,
  customerContract,
  gridPage,
  gridPageRow,
  gridPageTile,
  gridPageTileText,
  gridPageTileIframe,
  gridPageTileSingleGoal,
  dataSource,
  favorite,
  feedEntry,
  goalActivity,
  goal,
  goalCycle,
  goalSettings,
  goalSubscription,
  hubspotQuery,
  invitation,
  jiraQuery,
  msPlannerPlan,
  msPlannerQuery,
  msPowerBiQuery,
  mSTeamsConfiguration,
  notification,
  notificationReminder,
  notificationSetting,
  oauthCodeGrantClient,
  personalAppSettings,
  pin,
  planning,
  printable,
  property,
  propertyOption,
  propertyValue,
  propertySettings,
  provisionedResource,
  provisionedNames,
  provisionedUser,
  reaction,
  resourceSettings,
  salesforceQuery,
  salesforceReport,
  saml,
  savedView,
  selectedView,
  space,
  spreadsheetCell,
  spreadsheetDocument,
  storageObject,
  update,
  updateTemplate,
  user,
  userProvisioning,
  userProvisioningMapping,
  userScopeTree,
  userScope,
  simpleReference,
  viewCollection,
};

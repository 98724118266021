<template>
  <div class="ms-power-bi-summary">
    <div class="_item">
      <div class="_label">
        {{ $t('msPowerBiSummary.dataset') }}:
      </div>
      <div class="_content">
        {{ query.datasetName }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('msPowerBiSummary.dax') }}:
      </div>
      <m-tooltip
        class="_content"
        placement="bottom"
        :disabled="!isTruncated"
        :mouse-enter-delay=".5"
      >
        <template #title>
          {{ query.dax }}
        </template>
        <div
          ref="daxRef"
          class="_content"
        >
          {{ query.dax }}
        </div>
      </m-tooltip>
    </div>
  </div>
</template>

<script>

import useElementsTruncate from 'shared/composables/element-truncate';
import { ref } from 'vue';

export default {
  name: 'MsPowerBiSummary',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const daxRef = ref(null);
    const { isTruncated } = useElementsTruncate([daxRef]);
    return { daxRef, isTruncated };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .ms-power-bi-summary {
    ._item {
      display: flex;

      ._label {
        flex: 0 0 8.1rem;
        color: $font-color-secondary;
      }

      ._content {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>

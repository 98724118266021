<template>
  <div class="data-source-summary">
    <m-content
      v-if="error !== null"
      :padding-top="0"
      :padding-x="8"
      :padding-bottom="2"
    >
      <m-alert
        class="_error-message"
        type="warning"
        :message="error"
      />
    </m-content>
    <m-content
      padding-xs
      :padding-bottom="0"
    >
      <component
        :is="datasourceTypeToComponent"
        v-if="datasourceTypeToComponent !== null"
        :query="query"
      />
      <div
        v-if="dataSource !== null"
        class="_connection"
      >
        {{ $t('dataSourceSummary.connectedBy') }} {{ dataSource.name }}
      </div>
      <div
        v-else
        class="_connection"
      >
        {{ $t('dataSourceSummary.connectedBy') }}: {{ $t('dataSourceSummary.private') }}
      </div>
    </m-content>
    <m-divider
      v-if="dataSource !== null"
      xxs
    />
    <m-card-item
      v-if="dataSource !== null"
      icon="sync"
      :loading="syncLoading"
      :disabled="error !== null"
      @click="syncNow"
    >
      {{ $t('dataSource.syncNow') }}
    </m-card-item>
  </div>
</template>

<script>
import AsanaSummary from '@/components/datasource/AsanaSummary.vue';
import GoogleSheetsSummary from '@/components/datasource/GoogleSheetsSummary.vue';
import HubspotSummary from '@/components/datasource/HubspotSummary.vue';
import JiraSummary from '@/components/datasource/JiraSummary.vue';
import MsPlannerSummary from '@/components/datasource/MsPlannerSummary.vue';
import MsPowerBiSummary from '@/components/datasource/MsPowerBiSummary.vue';
import SalesforceSummary from '@/components/datasource/SalesforceSummary.vue';
import camelCase from 'lodash-es/camelCase';
import useAsana from '@/composables/integrations/asana/asana';
import useGoalDatasource from '@/composables/goal/goal-datasource';
import useHubspot from '@/composables/integrations/hubspot/hubspot';
import useJira from '@/composables/integrations/jira/jira';
import useMSPlanner from '@/composables/integrations/ms-planner/ms-planner';
import useMSPowerBI from '@/composables/integrations/ms-powerbi/ms-powerbi';
import useSalesforce from '@/composables/integrations/salesforce/salesforce';
import useSpreadsheet from '@/composables/integrations/spreadsheet/spreadsheet';
import { dataSourceError } from '@/lib/data-source';
import { dataSourceType, msPlannerError, msPowerBiError, salesforceError } from 'shared/constants.json';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';
import { toRef } from 'vue';

export default {
  name: 'DataSourceSummary',
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },
  components: { MsPowerBiSummary, MsPlannerSummary, AsanaSummary, GoogleSheetsSummary, JiraSummary, HubspotSummary, SalesforceSummary },
  setup(props) {
    const goal = toRef(props, 'goal');
    const { query, dataSource, type: goalDataSourceType } = useGoalDatasource(goal);
    const { updateQuery: updateJiraQuery } = useJira();
    const { updateQuery: updateSpreadsheetQuery } = useSpreadsheet();
    const { updateQuery: updateHubspotQuery } = useHubspot();
    const { updateQuery: updateAsanaQuery } = useAsana();
    const { updateQuery: updateSalesforceQuery } = useSalesforce();
    const { updateQuery: updateMsPowerBiQuery } = useMSPowerBI();
    const { updateQuery: updateMsPlannerBiQuery } = useMSPlanner();

    let updateQuery;
    switch (goalDataSourceType.value) {
      case dataSourceType.excel:
      case dataSourceType.sheets:
        updateQuery = updateSpreadsheetQuery;
        break;
      case dataSourceType.jira:
        updateQuery = updateJiraQuery;
        break;
      case dataSourceType.hubspot:
        updateQuery = updateHubspotQuery;
        break;
      case dataSourceType.asana:
        updateQuery = updateAsanaQuery;
        break;
      case dataSourceType.salesforce:
        updateQuery = updateSalesforceQuery;
        break;
      case dataSourceType.msPowerBi:
        updateQuery = updateMsPowerBiQuery;
        break;
      case dataSourceType.msPlanner:
        updateQuery = updateMsPlannerBiQuery;
        break;
      default:
        break;
    }
    return {
      query,
      dataSource,
      goalDataSourceType,
      updateQuery,
    };
  },
  data() {
    return {
      dataSourceType,
      syncLoading: false,
    };
  },
  computed: {
    datasourceTypeToComponent() {
      switch (this.goalDataSourceType) {
        case dataSourceType.jira:
          return 'jira-summary';
        case dataSourceType.asana:
          return 'asana-summary';
        case dataSourceType.hubspot:
          return 'hubspot-summary';
        case dataSourceType.salesforce:
          return 'salesforce-summary';
        case dataSourceType.sheets:
        case dataSourceType.excel:
          return 'google-sheets-summary';
        case dataSourceType.msPowerBi:
          return 'ms-power-bi-summary';
        case dataSourceType.msPlanner:
          return 'ms-planner-summary';
        default:
          return null;
      }
    },
    error() {
      let error;
      let possibleErrors;
      switch (this.goalDataSourceType) {
        case dataSourceType.salesforce:
          possibleErrors = salesforceError.all;
          error = dataSourceError({ salesforceQuery: this.query });
          break;
        case dataSourceType.msPowerBi:
          possibleErrors = msPowerBiError.all;
          error = dataSourceError({ msPowerBiQuery: this.query });
          break;
        case dataSourceType.msPlanner:
          possibleErrors = msPlannerError.all;
          error = dataSourceError({ msPlannerQuery: this.query });
          break;
        default:
          return null;
      }
      if (isNullOrUndefined(error)) {
        return null;
      }
      if (possibleErrors.includes(error)) {
        return this.$t(`${camelCase(this.goalDataSourceType)}.error.${error}`);
      }
      return null;
    },
  },
  methods: {
    syncNow() {
      this.syncLoading = true;
      this.updateQuery(this.query).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .data-source-summary {
    ._connection {
      margin-top: 1rem;
      font-size: $font-size-3;
      color: $font-color-secondary;
    }

    ._error-message {
      margin-top: 1.2rem;
      margin-bottom: .8rem;
    }
  }
</style>

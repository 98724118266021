import { NONE } from '@/lib/constants';
import { UID } from 'shared/api/query/constants';
import { dateScopeDynamicType, dateScopeType, gridPageTileSingleGoalType } from 'shared/constants.json';
import {
  goal as goalConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
} from 'shared/api/query/configs.json';

export const gridPageTileSingleGoalChildren = [
  { attr: UID },
  { attr: gridPageTileSingleGoalConfig.edges.goal, model: goalConfig.model, default: null, children: [{ attr: UID }] },
  { attr: gridPageTileSingleGoalConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
  { attr: gridPageTileSingleGoalConfig.edges.type, default: gridPageTileSingleGoalType.number },
  { attr: gridPageTileSingleGoalConfig.edges.compareTo, default: { value: NONE } },
  { attr: gridPageTileSingleGoalConfig.edges.timeRange, default: { type: dateScopeType.between, dynamicType: dateScopeDynamicType.allTime } },
];

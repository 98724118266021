import {
  ALL_ALIAS,
  AND,
  CREATED_AT,
  DEFAULT_ATTRIBUTES,
  DGRAPH_TYPE,
  HAS,
  NOT,
  ONE_TO_ONE,
  RESULT, SOFT_DELETED_AT,
  TYPE,
  UID,
  VAR,
} from 'shared/api/query/constants';
import { GOAL_PARENT_QUERY_LEVEL, UNASSIGNED } from '@/lib/constants';
import {
  accessPolicy,
  account as accountConfig,
  asanaQuery,
  goal,
  goalActivity,
  goalCycle as goalCycleConfig,
  goalSubscription,
  hubspotQuery,
  jiraQuery,
  msPlannerQuery,
  msPowerBiQuery,
  planning as planningConfig,
  property,
  propertyValue,
  salesforceQuery,
  spreadsheetCell,
  update,
  user,
} from 'shared/api/query/configs.json';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import { accessPolicyType, aggregationMethod, goalThresholdTargetArea } from 'shared/constants.json';
import { asanaQueryChildren } from '@/api/query/nebula/asana-query';
import { buildCustomFuncProgressCoursePred } from '@/api/query/custom-func-helper';
import { combine, notHasFilter } from 'shared/api/query/filter';
import { hubspotQueryChildren } from '@/api/query/nebula/hubspot-query';
import { jiraQueryChildren } from '@/api/query/nebula/jira-query';
import { msPlannerQueryChildren } from '@/api/query/nebula/ms-planner-query';
import { msPowerBiQueryChildren } from '@/api/query/nebula/ms-powerbi-query';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { reverseEdge } from 'shared/api/query/edges';
import { salesforceQueryChildren } from '@/api/query/nebula/salesforce-query';
import { searchTermFilter } from '@/lib/filter/search-term';
import { spreadsheetCellChildren } from '@/api/query/nebula/sheets-cell';

export const children = ({ childrenOrder = [] } = { childrenOrder: [] }) => {
  let childrenOrderObject = {};
  if (childrenOrder.length > 0) {
    childrenOrderObject = { order: childrenOrder };
  }
  return [
    ...DEFAULT_ATTRIBUTES,
    { attr: DGRAPH_TYPE },
    { attr: SOFT_DELETED_AT },
    { attr: 'completelyLoaded', default: true },
    { attr: goal.edges.title, default: '' },
    { attr: goal.edges.description, default: null },
    { attr: goal.edges.icon, default: '' },
    { attr: goal.edges.accessRight, default: accessPolicyType.read },
    { attr: goal.edges.progressMeasurement },
    { attr: goal.edges.aggregationMethod, default: aggregationMethod.relative },
    { attr: goal.edges.thresholdTargetArea, default: goalThresholdTargetArea.above },
    { attr: goal.edges.cachedCalculatedCurrent, default: 0 },
    { attr: goal.edges.threshold, default: 0 },
    { attr: goal.edges.start, default: 0 },
    { attr: goal.edges.end, default: 100 },
    { attr: goal.edges.cachedCurrent },
    { attr: goal.edges.cachedLastUpdatedAt, default: null },
    { attr: goal.edges.disableStatusAutoUpdate, default: false },
    { attr: goal.edges.unboundedProgress, default: false },
    { attr: goal.edges.publishedAt, default: null },
    { attr: goal.edges.childrenSort, default: [] },
    { attr: goal.edges.planning, default: null, model: planningConfig.model, children: [{ attr: UID }] },
    {
      attr: goal.edges.subscriptions,
      default: [],
      model: goalSubscription.model,
      filter: { func: { name: 'has', attr: goalSubscription.edges.user } },
      children: [
        { attr: UID },
        { attr: goalSubscription.edges.active, default: false },
        {
          attr: goalSubscription.edges.user,
          model: user.model,
          children: [{ attr: UID }],
        },
      ],
    },
    {
      attr: goal.edges.alignedItemsGroupedBy,
      default: null,
      model: property.model,
      children: [{ attr: UID }],
    },
    { attr: goal.edges.metric, default: '%' },
    {
      attr: goal.edges.creator,
      default: null,
      model: user.model,
      children: [{ attr: UID }],
    },
    {
      attr: goal.edges.paysOnto,
      model: goal.model,
      default: [],
      facets: { param: [{ key: 'weight' }] },
      facetVar: {},
      children: [
        { attr: UID },
      ],
    },
    {
      alias: 'children',
      attr: reverseEdge(goal.edges.parents),
      model: goal.model,
      default: [],
      children: [{ attr: UID }],
      ...childrenOrderObject,
    },
    {
      attr: goal.edges.parents,
      model: goal.model,
      default: [],
      facets: { param: [{ key: 'order' }] },
      facetVar: {},
      children: [
        { attr: UID },
      ],
    },
    {
      attr: goal.edges.goalCycle,
      model: goalCycleConfig.model,
      default: [],
      children: [{ attr: UID }],
    },
    {
      attr: goal.edges.accessPolicy,
      model: accessPolicy.model,
      default: null,
      children: accessPolicyChildren,
    },
    {
      attr: goal.edges.properties,
      default: [],
      model: propertyValue.model,
      children: propertyValueEdges,
    },
    {
      alias: 'commentCount',
      model: update.model,
      filter: { op: NOT, child: [{ func: { name: HAS, attr: 'goalActivities' } }] },
      attr: reverseEdge(update.edges.goal),
      isCount: true,
    },
    {
      alias: 'lastComment',
      model: update.model,
      filter: { op: NOT, child: [{ func: { name: HAS, attr: 'goalActivities' } }] },
      attr: reverseEdge(update.edges.goal),
      args: { first: '1' },
      order: [{ attr: CREATED_AT, desc: true }],
      default: [],
      children: DEFAULT_ATTRIBUTES,
    },
    {
      alias: 'goalActivityCount',
      model: goalActivity.model,
      attr: reverseEdge(goalActivity.edges.goal),
      isCount: true,
    },
    {
      alias: 'lastGoalActivity',
      model: goalActivity.model,
      attr: reverseEdge(goalActivity.edges.goal),
      args: { first: '1' },
      order: [{ attr: CREATED_AT, desc: true }],
      default: [],
      children: DEFAULT_ATTRIBUTES,
    },
    {
      attr: reverseEdge(spreadsheetCell.edges.goal),
      alias: 'spreadsheetCell',
      assoc: ONE_TO_ONE,
      model: spreadsheetCell.model,
      children: spreadsheetCellChildren,
      default: null,
    },
    {
      attr: reverseEdge(jiraQuery.edges.goal),
      alias: 'jiraQuery',
      assoc: ONE_TO_ONE,
      model: jiraQuery.model,
      children: jiraQueryChildren,
      default: null,
    },
    {
      attr: reverseEdge(hubspotQuery.edges.goal),
      alias: 'hubspotQuery',
      assoc: ONE_TO_ONE,
      model: hubspotQuery.model,
      children: hubspotQueryChildren,
      default: null,
    },
    {
      attr: reverseEdge(asanaQuery.edges.goal),
      alias: 'asanaQuery',
      assoc: ONE_TO_ONE,
      model: asanaQuery.model,
      children: asanaQueryChildren,
      default: null,
    },
    {
      attr: reverseEdge(salesforceQuery.edges.goal),
      alias: 'salesforceQuery',
      assoc: ONE_TO_ONE,
      model: salesforceQuery.model,
      children: salesforceQueryChildren,
      default: null,
    },
    {
      attr: reverseEdge(msPowerBiQuery.edges.goal),
      alias: 'msPowerBiQuery',
      assoc: ONE_TO_ONE,
      model: msPowerBiQuery.model,
      children: msPowerBiQueryChildren,
      default: null,
    },
    {
      attr: reverseEdge(msPlannerQuery.edges.goal),
      alias: 'msPlannerQuery',
      assoc: ONE_TO_ONE,
      model: msPlannerQuery.model,
      children: msPlannerQueryChildren,
      default: null,
    },
  ];
};

export const goalDetailChildren = ({
  childrenFilter = null,
  childrenOrder = [],
  customFuncs = [],
}) => {
  const c = children({ childrenFilter, childrenOrder });
  c.push(buildCustomFuncProgressCoursePred({ attr: reverseEdge(goal.edges.paysOnto) }));
  if (customFuncs.length !== 0) {
    c.push(...customFuncs);
  }
  return c;
};

export const goalParents = (id, alias = RESULT) => {
  const getParents = (count, threshold) => {
    if (count >= threshold) {
      return {
        model: goal.model,
        attr: goal.edges.parents,
        children: [
          { attr: UID, var: `parent_level_${count}` },
        ],
      };
    }

    return {
      model: goal.model,
      attr: goal.edges.parents,
      children: [
        { attr: UID, var: `parent_level_${count}` },
        getParents(count + 1, threshold),
      ],
    };
  };
  return [
    {
      alias: VAR,
      func: { name: UID },
      uid: [id],
      model: goal.model,
      children: [
        { attr: UID, var: 'parent_level_0' },
        getParents(1, GOAL_PARENT_QUERY_LEVEL - 1),
      ],
    },
    {
      alias,
      needsVar: Array.from(Array(GOAL_PARENT_QUERY_LEVEL).keys()).map((n) => ({
        name: `parent_level_${n}`,
        typ: 1,
      })),
      func: {
        name: UID,
        needsVar: Array.from(Array(GOAL_PARENT_QUERY_LEVEL).keys()).map((n) => ({
          name: `parent_level_${n}`,
          typ: 1,
        })),
      },
      model: goal.model,
      children: children(),
    },
  ];
};

export const goalChildren = (id, alias = RESULT) => [
  {
    alias: VAR,
    uid: [
      id,
    ],
    func: { name: UID },
    model: goal.model,
    children: [
      {
        model: goal.model,
        attr: reverseEdge(goal.edges.parents),
        var: 'goalChildren',
      },
    ],
  },
  {
    alias,
    needsVar: [{ name: 'goalChildren', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'goalChildren', typ: 1 }] },
    model: goal.model,
    children: children(),
  },
];

export const getChildren = ({
  childrenFilter,
  childrenOrder,
  customFuncs = [],
}) => {
  if (customFuncs.length === 0) {
    return children({ childrenOrder, childrenFilter });
  }

  return goalDetailChildren({
    childrenFilter,
    childrenOrder,
    customFuncs,
  });
};

export const goalById = (id, alias = RESULT, customFuncs = []) => [
  {
    uid: [id],
    alias,
    func: { name: UID },
    model: goal.model,
    children: goalDetailChildren({ customFuncs }),
  },
];

export const goalsById = ({
  ids = [],
  pagination,
  order = [],
  alias = RESULT,
  allAlias = ALL_ALIAS,
  progressCourse = [],
  progressCourseAlias = '',
  includeChildren = true,
  includeParents = true,
}) => {
  const getVars = () => {
    const vars = [{ name: 'result_goals', typ: 1 }];
    if (includeChildren) {
      vars.push({ name: 'goal_children', typ: 1 });
    }
    if (includeParents) {
      vars.push({ name: 'parent_goals', typ: 1 });
    }
    return vars;
  };

  const children = () => {
    const children = [{ attr: UID, var: 'result_goals' }];
    if (includeChildren) {
      children.push({
        attr: reverseEdge(goal.edges.parents),
        model: goal.model,
        var: 'goal_children',
      });
    }
    if (includeParents) {
      children.push({ attr: goal.edges.parents, model: goal.model, children: [{ attr: UID, var: 'parent_goals' }] });
    }
    return children;
  };

  const customFuncs = [];
  if (progressCourseAlias !== '') {
    customFuncs.push(buildCustomFuncProgressCoursePred({ alias: progressCourseAlias, attr: reverseEdge(goal.edges.paysOnto), timeSeries: progressCourse }));
  }

  return [
    {
      uid: ids,
      alias,
      func: { name: UID },
      model: goal.model,
      order,
      pagination,
      default: [],
      children: children(),
    },
    {
      model: goal.model,
      alias: allAlias,
      needsVar: getVars(),
      func: {
        name: UID,
        needsVar: getVars(),
      },
      default: [],
      children: getChildren({ childrenOrder: order, customFuncs }),
    },
  ];
};

export const goalsByTitle = (title, accountId, alias = RESULT, pagination = {
  itemsPerPage: 20,
  page: 1,
}) => [
  {
    alias: VAR,
    uid: [
      accountId,
    ],
    model: accountConfig.model,
    func: { name: UID },
    children: [
      {
        model: goal.model,
        attr: reverseEdge(goal.edges.account),
        var: 'goals',
      },
    ],
  },
  {
    model: goal.model,
    alias,
    needsVar: [{ name: 'goals', typ: 1 }],
    func: {
      name: UID,
      needsVar: [{ name: 'goals', typ: 1 }],
    },
    filter: {
      op: AND,
      child: title.split(' ').map((t) => ({
        func: {
          attr: goal.edges.title,
          name: 'regexp',
          args: [{ value: t }, { value: 'i' }],
        },
      })),
    },
    pagination,
    default: [],
    children: children(),
  },
];

export const goalsInCycleVarBlock = ({ goalCycles = [], filter = null, varName }) => {
  const unassigned = goalCycles.filter((c) => c.uid === UNASSIGNED).length > 0;
  const cycles = goalCycles.filter((c) => c.uid !== UNASSIGNED);

  if (cycles.length === 0 && !unassigned) {
    return [{
      alias: VAR,
      model: goal.model,
      func: { name: TYPE, args: [{ value: goal.model }] },
      filter,
      children: [{ attr: UID, var: varName }],
    }];
  }

  if (unassigned) {
    const f = {
      op: AND,
      child: [
        notHasFilter(goal.edges.goalCycle),
      ],
    };
    if (filter !== null) {
      f.child.push(filter);
    }

    if (cycles.length === 0) {
      return [
        {
          alias: VAR,
          model: goal.model,
          func: { name: TYPE, args: [{ value: goal.model }] },
          filter: f,
          children: [{ attr: UID, var: varName }],
        },
      ];
    }

    return [
      {
        alias: VAR,
        model: goal.model,
        func: { name: TYPE, args: [{ value: goal.model }] },
        filter: f,
        children: [{ attr: UID, var: 'unassigned' }],
      },
      {
        alias: VAR,
        model: goalCycleConfig.model,
        uid: cycles.map((g) => g.uid),
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(goal.edges.goalCycle),
            model: goal.model,
            filter,
            var: 'filtered_goal_cycles',
          },
        ],
      },
      {
        alias: VAR,
        model: goal.model,
        needsVar: [{ name: 'filtered_goal_cycles', typ: 1 }, { name: 'unassigned', typ: 1 }],
        func: { name: UID, needsVar: [{ name: 'filtered_goal_cycles', typ: 1 }, { name: 'unassigned', typ: 1 }] },
        filter,
        children: [{ attr: UID, var: varName }],
      },
    ];
  }

  return [{
    alias: VAR,
    model: goalCycleConfig.model,
    uid: goalCycles.map((g) => g.uid),
    func: { name: UID },
    children: [
      {
        attr: reverseEdge(goal.edges.goalCycle),
        model: goal.model,
        filter,
        var: varName,
      },
    ],
  }];
};

export const goalList = ({
  pagination,
  goalCycles = [],
  filter = null,
  varBlocks = [],
  progressCourse = [],
  progressCourseAlias = '',
  searchTerm = '',
  order = [],
  alias = RESULT,
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const f = combine(AND, [
    searchTermFilter({ searchTerm, edgeName: goal.edges.title }),
    filter,
  ]);

  const customFuncs = [];
  if (progressCourseAlias !== '') {
    customFuncs.push(buildCustomFuncProgressCoursePred({ alias: progressCourseAlias, attr: reverseEdge(goal.edges.paysOnto), timeSeries: progressCourse }));
  }

  return [
    ...goalsInCycleVarBlock({ goalCycles, filter: f, varName: cycleGoalsVar }),
    {
      model: goal.model,
      alias,
      needsVar: [{ name: cycleGoalsVar, typ: 1 }],
      func: { name: UID, needsVar: [{ name: cycleGoalsVar, typ: 1 }] },
      order,
      filter,
      pagination,
      default: [],
      children: getChildren({ customFuncs }),
    },
    ...varBlocks,
  ];
};

<template>
  <div :class="['m-switch', disabled ? '-disabled' : '', value ? '-checked' : '']">
    <div
      v-if="!$slots.default && label !== ''"
      class="_label"
    >
      {{ label }}
    </div>
    <template v-if="$slots.default">
      <slot />
    </template>
    <div
      :style="switchStyle"
      class="_switch"
      @click="change"
    >
      <div
        :style="circleStyle"
        class="_circle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change'],
  computed: {
    circleSize() {
      if (this.small) {
        return {
          height: '1.4rem',
          width: '1.4rem',
          borderRadius: '.7rem',
        };
      }

      return {
        height: '1.8rem',
        width: '1.8rem',
        borderRadius: '.9rem',
      };
    },
    size() {
      if (this.small) {
        return {
          height: '1.8rem',
          flex: '0 0 3rem',
          width: '3rem',
          borderRadius: '.9rem',
        };
      }

      return {
        height: '2.2rem',
        flex: '0 0 3.6rem',
        width: '3.6rem',
        borderRadius: '1.1rem',
      };
    },
    circlePosition() {
      if (this.small) {
        if (this.value) {
          return { transform: 'translateX(14px) translateY(0px)' };
        }
        return { transform: 'translateX(2px) translateY(0px)' };
      }

      if (this.value) {
        return { transform: 'translateX(16px) translateY(0px)' };
      }
      return { transform: 'translateX(2px) translateY(0px)' };
    },
    circleStyle() {
      if (this.value) {
        return {
          backgroundColor: 'white',
          ...this.circlePosition,
          ...this.circleSize,
        };
      }
      return {
        backgroundColor: 'white',
        ...this.circlePosition,
        ...this.circleSize,
      };
    },
    switchStyle() {
      if (this.value) {
        return { ...this.size };
      }

      return { ...this.size };
    },
  },
  methods: {
    change() {
      if (this.disabled) {
        return;
      }

      this.$emit('input', !this.value);
      this.$emit('update:value', !this.value);
      this.$emit('change', !this.value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-switch {
    display: flex;
    align-items: center;
    width: 100%;

    ._switch {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;
      cursor: pointer;
      background-color: map_get($grey, 'lighten-3');
      transition: background-color .1s ease-in-out;

      ._circle {
        transition: transform 200ms ease-out 0s, background 200ms ease-out 0s;
      }

      &:hover {
        background-color: map_get($grey, 'lighten-2');
      }
    }

    &.-disabled {
      cursor: default;

      ._switch {
        cursor: not-allowed;
        opacity: .4;
      }
    }

    &.-checked {
      ._switch {
        background-color: map_get($blue, 'base');

        &:hover {
          background-color: map_get($blue, 'darken-1');
        }
      }
    }

    ._label {
      margin-right: 2rem;
      overflow: hidden;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>

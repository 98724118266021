<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9 16.7612C13.4288 17.1606 13.4288 17.8875 13.9 18.2869L14.0948 18.452C14.4678 18.7682 15.0149 18.7682 15.3879 18.452L22.1 12.7628C22.5712 12.3634 22.5712 11.6366 22.1 11.2372L15.3879 5.54804C15.0149 5.23183 14.4678 5.23183 14.0948 5.54804L13.9 5.71313C13.4288 6.11253 13.4288 6.83941 13.9 7.23881L19.5173 12L13.9 16.7612Z"
      fill="#383630"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1 16.7612C10.5712 17.1606 10.5712 17.8875 10.1 18.2869L9.90522 18.452C9.53216 18.7682 8.98512 18.7682 8.61206 18.452L1.90001 12.7628C1.42879 12.3634 1.42879 11.6366 1.90001 11.2372L8.61206 5.54804C8.98512 5.23183 9.53216 5.23183 9.90522 5.54804L10.1 5.71313C10.5712 6.11253 10.5712 6.83941 10.1 7.23881L4.48272 12L10.1 16.7612Z"
      fill="#383630"
    />
  </svg>
</template>

<script>
export default { name: 'EmbedIcon' };
</script>

<style scoped lang="scss" type="text/scss">

</style>

<template>
  <dashboard-card
    class="goal-insights-health-table"
    :title="$t('goalInsightsHealth.breakdown.cardTitle', { groupBy: textByLang(spaceProperty.label, userLang) })"
    :loading="loading"
    :bottom-style="{ padding: 0 }"
  >
    <div class="_header">
      <list-string-filter
        small
        :disabled="loading"
        @input="val => searchTerm = val"
      />
    </div>
    <m-table
      :key="groupBy"
      class="_table"
      :columns="columns"
      :data-source="rows"
      :loading="loading"
      :pagination="pagination"
      :row-key="rowKey()"
      show-count
      @update:order="updateOrder"
    >
      <template #propertyOption="{ propertyOption, row }">
        <hierarchical-property-option-table-cell
          :property-option="propertyOption"
          :row="row"
          :show-as-hierarchy="showAsHierarchy"
          @toggle-collapse="toggleCollapse(propertyOption.uid)"
        />
      </template>
      <template #publicGoals="{ publicGoals, row }">
        <div
          v-if="publicGoals !== undefined"
          class="_number-cell"
        >
          <m-btn
            hide-border
            small
            :disabled="loading"
            @click="handlePublicGoals(row)"
          >
            <span class="_metric">{{ publicGoals.length }}</span>
          </m-btn>
        </div>
      </template>
      <template #invalidParents="{ invalidParents, row }">
        <div
          v-if="invalidParents !== undefined"
          class="_number-cell"
        >
          <m-btn
            hide-border
            small
            :disabled="loading"
            @click="handleInvalidParents(row)"
          >
            <span :class="['_metric', invalidParents.length > 0 ? '-negative' : '']">{{ invalidParents.length }}</span>
          </m-btn>
        </div>
      </template>
      <template #invalidProperties="{ invalidProperties, row }">
        <div
          v-if="invalidProperties !== undefined"
          class="_number-cell"
        >
          <m-btn
            hide-border
            small
            :disabled="loading"
            @click="handleInvalidProperties(row)"
          >
            <span :class="['_metric', invalidProperties.length > 0 ? '-negative' : '']">{{ invalidProperties.length }}</span>
          </m-btn>
        </div>
      </template>
      <template #invalidMeasurementTypes="{ invalidMeasurementTypes, row }">
        <div
          v-if="invalidMeasurementTypes !== undefined"
          class="_number-cell"
        >
          <m-btn
            hide-border
            small
            :disabled="loading"
            @click="handleInvalidMeasurementTypes(row)"
          >
            <span :class="['_metric', invalidMeasurementTypes.length > 0 ? '-negative' : '']">{{ invalidMeasurementTypes.length }}</span>
          </m-btn>
        </div>
      </template>
    </m-table>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import HierarchicalPropertyOptionTableCell from '@/components/goal-insights/HierarchicalPropertyOptionTableCell.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import useDebounce from '@/composables/debounce';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useSpaces from '@/composables/space/spaces';
import useSubscription from '@/composables/subscription/subscription';
import { AND } from 'shared/api/query/filter';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { allGoalTypesMeasurementTypesFilter } from '@/lib/filter/goal/measurement-types-filter';
import { allGoalTypesParentTypeFilter } from '@/lib/filter/goal/parent-scope-filter';
import { allGoalTypesPropertiesRequiredFilter } from '@/lib/filter/goal/properties-required-filter';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { breakdownActiveFilter } from '@/lib/goal-insights/breakdown-filter';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { dogma } from '@/api';
import { goalInsightsGroupBy } from 'shared/constants.json';
import {
  hasRuleInvalidMeasurementTypes,
  hasRuleInvalidParentType,
  hasRuleInvalidProperties,
  queryInsights,
} from '@/api/query/goal-insights-health';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';
import { teamGoalsFilter } from '@/lib/filter/goal/team-goals-filter';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'HealthTableCard',
  props: {
    selectedCycles: {
      type: Array,
      required: true,
    },
    viewFilter: {
      type: Object,
      required: true,
    },
    goalTypes: {
      type: Array,
      required: true,
    },
    groupByProperty: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['show-goal-list'],
  components: { HierarchicalPropertyOptionTableCell, ListStringFilter, DashboardCard },
  setup(props) {
    const { debounce } = useDebounce();
    const { subscribe } = useSubscription();
    const { goalProperties, spaceProperty } = useProperties();
    const searchTerm = ref('');
    const { userLang } = useLoggedInUser();
    const { normalizedSpaces, setSpaceMappers, toggleCollapse } = useSpaces(searchTerm);

    const goalTypesWithRequiredProperties = computed(() => props.goalTypes.map((goalType) => ({
      ...goalType,
      requiredProperties: goalType.requiredProperties.map((rp) => goalProperties.value.find((prop) => prop.uid === rp.uid)),
    })));
    const { goalTypeProperty } = useGoalTypeProperty();
    const { publishedAtFilter } = usePublishedAtFilter();

    const groupBy = goalInsightsGroupBy.propertyOption;
    return {
      debounce,
      subscribe,

      userLang,
      searchTerm,
      groupBy,
      spaceProperty,
      normalizedSpaces,
      setSpaceMappers,
      toggleCollapse,
      goalTypesWithRequiredProperties,
      goalTypeProperty,
      publishedAtFilter,
    };
  },
  data() {
    return {
      loading: false,
      textByLang,
      pageSize: 40,
      lastOrder: {},
      data: this.normalizedSpaces.map((s) => ({
        propertyOption: this.spaceToPropertyOption(s),
        publicGoals: [],
        invalidParents: [],
        invalidProperties: [],
        invalidMeasurementTypes: [],
      })),
    };
  },
  computed: {
    series() {
      return this.data.map((e) => e.publicGoals).flat().map((e) => e.uid);
    },
    pagination() {
      if (this.data.length < this.pageSize) {
        return false;
      }

      return { pageSize: this.pageSize };
    },
    showAsHierarchy() {
      const keys = Object.keys(this.lastOrder);
      return !(keys.length === 1 && this.lastOrder[keys[0]].length !== 0);
    },
    columns() {
      const groupByColumn = [];
      switch (this.groupBy) {
        case goalInsightsGroupBy.propertyOption:
        case goalInsightsGroupBy.space:
          groupByColumn.push(...[{
            key: 'propertyOption',
            dataIndex: 'propertyOption',
            title: textByLang(this.groupByProperty.label, this.userLang),
            scopedSlots: { customRender: 'propertyOption' },
            sorter: (a, b) => textByLang(a.propertyOption.label, this.userLang).localeCompare(textByLang(b.propertyOption.label, this.userLang)),
          }]);
          break;
        default:
      }
      return [...groupByColumn, {
        key: 'publicGoals',
        dataIndex: 'publicGoals',
        title: this.$t('goalInsightsHealth.breakdown.table.publicGoals'),
        scopedSlots: { customRender: 'publicGoals' },
        sorter: this.numberSorter('publicGoals'),
      }, {
        key: 'invalidParents',
        dataIndex: 'invalidParents',
        title: this.$t('goalInsightsHealth.breakdown.table.invalidParents'),
        scopedSlots: { customRender: 'invalidParents' },
        sorter: this.numberSorter('invalidParents'),
      }, {
        key: 'invalidProperties',
        dataIndex: 'invalidProperties',
        title: this.$t('goalInsightsHealth.breakdown.table.invalidProperties'),
        scopedSlots: { customRender: 'invalidProperties' },
        sorter: this.numberSorter('invalidProperties'),
      }, {
        key: 'invalidMeasurementTypes',
        dataIndex: 'invalidMeasurementTypes',
        title: this.$t('goalInsightsHealth.breakdown.table.invalidMeasurementTypes'),
        scopedSlots: { customRender: 'invalidMeasurementTypes' },
        sorter: this.numberSorter('invalidMeasurementTypes'),
      }];
    },
    rows() {
      const setInsightsData = (node) => ({
        ...node,
        data: this.data.find((c) => c.propertyOption.uid === node.uid),
        children: node.children.map(setInsightsData),
      });

      const hasData = (res, node) => node.data !== undefined || res || node.children.reduce(hasData, false);
      const mapContainsData = (node) => ({
        ...node,
        containsData: node.children.reduce(hasData, false),
        children: node.children.map(mapContainsData),
      });

      const hasDataMatch = (res, node) => (node.data !== undefined && node.match) || res || node.children.reduce(hasDataMatch, false);
      const mapContainsDataMatch = (node) => ({
        ...node,
        containsDataMatch: node.children.reduce(hasDataMatch, false),
        children: node.children.map(mapContainsDataMatch),
      });

      const resetInsightsData = (e) => {
        if (e === undefined) {
          return {
            publicGoals: [],
            invalidParents: [],
            invalidProperties: [],
            invalidMeasurementTypes: [],
          };
        }
        return e;
      };

      this.setSpaceMappers([setInsightsData, mapContainsData, mapContainsDataMatch]);
      return this.normalizedSpaces.map((s) => ({
        ...s,
        ...resetInsightsData(s.data),
        propertyOption: this.spaceToPropertyOption(s),
        disabled: !s.match || s.data === undefined,
        hasChildren: s.children.length > 0,
      })).reduce(breakdownActiveFilter(this.showAsHierarchy), []);
    },
    gqlFilterObject() {
      const handler = new GoalFilterHandler();
      return handler.Translate(this.viewFilter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },
  },
  methods: {
    spaceToPropertyOption(space) {
      return {
        ...space,
        icon: buildIconFromEntity(space),
        label: { en: space.title, de: space.title },
        property: this.spaceProperty,
      };
    },
    rowKey() {
      return 'propertyOption.uid';
    },
    updateOrder(event) {
      this.lastOrder = event;
    },
    numberSorter(key) {
      return (a, b) => a[key] - b[key];
    },
    showGoalList(title, extraFilters) {
      this.$emit('show-goal-list', {
        filter: {
          op: AND,
          children: [
            this.viewFilter,
            ...extraFilters,
          ],
        },
        title,
      });
    },
    titlePrefixed(record, title) {
      return `${textByLang(record.propertyOption.label, this.userLang)} - ${title}`;
    },
    filterPerGroupBy(record) {
      return teamGoalsFilter({
        spaceProperty: this.spaceProperty,
        team: record.propertyOption,
      });
    },
    handlePublicGoals(record) {
      this.showGoalList(
        this.titlePrefixed(record, this.$t('goalInsightsHealth.breakdown.table.publicGoals')),
        [
          this.filterPerGroupBy(record),
        ],
      );
    },
    handleInvalidParents(record) {
      const filteredGoalTypes = this.goalTypes.filter((goalType) => hasRuleInvalidParentType(goalType, this.goalTypeProperty));
      this.showGoalList(
        this.titlePrefixed(record, this.$t('goalInsightsHealth.breakdown.table.invalidParents')),
        [
          this.filterPerGroupBy(record),
          allGoalTypesParentTypeFilter(this.goalTypeProperty, filteredGoalTypes),
        ],
      );
    },
    handleInvalidProperties(record) {
      const filteredGoalTypes = this.goalTypesWithRequiredProperties.filter((goalType) => hasRuleInvalidProperties(goalType));
      this.showGoalList(
        this.titlePrefixed(record, this.$t('goalInsightsHealth.breakdown.table.invalidProperties')),
        [
          this.filterPerGroupBy(record),
          allGoalTypesPropertiesRequiredFilter(this.goalTypeProperty, filteredGoalTypes),
        ],
      );
    },
    handleInvalidMeasurementTypes(record) {
      const filteredGoalTypes = this.goalTypes.filter((goalType) => hasRuleInvalidMeasurementTypes(goalType));
      this.showGoalList(
        this.titlePrefixed(record, this.$t('goalInsightsHealth.breakdown.table.invalidMeasurementTypes')),
        [
          this.filterPerGroupBy(record),
          allGoalTypesMeasurementTypesFilter(this.goalTypeProperty, filteredGoalTypes),
        ],
      );
    },
    retrieveHealthInsights() {
      this.loading = true;
      const filteredSpaces = this.normalizedSpaces.filter((s) => isInFilter({ isFilterMode: true })({
        entity: { properties: [{ property: this.spaceProperty, spaces: [s] }] },
        scopeTree: this.viewFilter,
      }));
      dogma.query(applyBaseFilter({
        queries: [
          ...queryInsights({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            teams: filteredSpaces,
            goalTypeProperty: this.goalTypeProperty,
            goalTypeOptions: this.goalTypesWithRequiredProperties,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.data = filteredSpaces.map((s) => {
          const prefix = `team${s.uid}`;
          return {
            propertyOption: this.spaceToPropertyOption(s),
            publicGoals: response.data[`${prefix}_count`],
            invalidParents: Object.keys(response.data).filter((k) => k.startsWith(`${prefix}_parents`)).map((k) => response.data[k]).flat(),
            invalidProperties: Object.keys(response.data).filter((k) => k.startsWith(`${prefix}_props`)).map((k) => response.data[k]).flat(),
            invalidMeasurementTypes: Object.keys(response.data).filter((k) => k.startsWith(`${prefix}_mtypes`)).map((k) => response.data[k]).flat(),
          };
        });
      });
    },
    fetch(wait) {
      this.debounce(this.retrieveHealthInsights, wait, 'retrieveHealthInsights');
    },
  },
  watch: {
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.fetch(1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.fetch(1000);
      },
      deep: true,
    },
  },
  created() {
    this.fetch(0);

    const fn = this.fetch;
    this.subscribe({
      queryFn: Promise.resolve(),
      syncFn({ isSelfEvent }) {
        const delay = isSelfEvent ? 1000 : 30000;
        fn(delay);
      },
      model: 'goal',
      includeSelfEvents: true,
    });
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/_padding.scss';

  .goal-insights-health-table {
    ._header {
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2.4rem;
      margin-bottom: .4rem;
    }

    ._table {
      padding-bottom: 2.4rem;

      .m-table {
        padding: 0 2.4rem;
        overflow: unset;
      }

      ._bottom {
        padding: 0 2.4rem;
      }
    }

    ._number-cell {
      display: flex;
      justify-content: flex-end;
      text-align: right;

      ._metric {
        font-size: $font-size-4;
      }

      .-negative {
        color: $error-color;
      }
    }
  }
</style>

import apiConfig, {
  asanaQuery,
  hubspotQuery,
  jiraQuery,
  msPlannerQuery,
  msPowerBiQuery,
  salesforceQuery,
  spreadsheetCell,
} from 'shared/api/query/configs.json';
import { AND, NOT, OR } from '@/lib/filter/scope-tree';
import { guid } from 'shared/lib/uuid';
import { userScopeType } from 'shared/constants.json';

export const datasourceModels = [spreadsheetCell.model, jiraQuery.model, hubspotQuery.model, asanaQuery.model, salesforceQuery.model, msPlannerQuery.model, msPowerBiQuery.model];

export const automatedFilter = (negated = false) => {
  const buildReverseProperty = (model) => ({
    model,
    edgeName: apiConfig[model].edges.goal,
    edgeAlias: model,
  });

  const children = datasourceModels.map((model) => ({
    key: guid(),
    scope: {
      type: userScopeType.reverseProperty,
      reverseProperty: buildReverseProperty(model),
    },
  }));

  const op = negated === true ? NOT : AND;
  return {
    key: guid(),
    op,
    children: [{
      key: guid(),
      op: OR,
      children,
    }],
  };
};

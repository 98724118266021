<template>
  <div
    :class="classes"
    @click="emitClick"
  >
    <div
      v-if="!!$slots.prefix"
      class="_prefix"
    >
      <slot name="prefix" />
    </div>
    <m-placeholder
      v-if="showPlaceholder"
      class="_placeholder"
      :placeholder="placeholder"
      :placeholder-icon="placeholderIcon"
      :wrap="wrapPlaceholder"
      :style="{ ...resolveStyles({ ...defaultStyle, ...mStyle }), ...placeholderStyle }"
    />
    <div
      v-if="!showPlaceholder"
      ref="content"
      :class="['_slot-wrapper']"
    >
      <slot />
    </div>
    <div
      v-if="!!$slots.suffix"
      class="_suffix"
    >
      <slot name="suffix" />
    </div>
  </div>
</template>

<script>
import MPlaceholder from 'shared/components/base/MPlaceholder.vue';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'MFocusable',
  props: {
    ...mStyleProps,
    fullWidth: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'clickable'].includes(value);
      },
    },
    focused: {
      type: Boolean,
      default: false,
    },
    hasTags: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    wrapPlaceholder: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    xxs: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    superLight: {
      type: Boolean,
      default: false,
    },
    showPlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholderStyle: {
      type: Object,
      default: () => {
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  components: { MPlaceholder },
  emits: ['click'],
  computed: {
    defaultStyle() {
      if (this.xs) {
        return { fontStyle: { fontSize: 'small' } };
      }
      return {};
    },
    cursor() {
      if (this.disabled) {
        return '-cursor-disabled';
      }
      if (this.readOnly || this.disabled) {
        return '-cursor-default';
      }
      if (this.type === 'clickable') {
        return '-cursor-pointer';
      }
      return '-cursor-text';
    },
    classes() {
      return [
        'm-focusable',
        this.fullWidth ? '-full-width' : '',
        this.hideBorder ? '-hide-border' : '',
        this.hideHover || this.disabled || this.readOnly ? '-hide-hover' : '',
        this.cursor,
        this.disabled ? '-disabled' : '',
        this.small ? '-small' : '',
        this.type === 'clickable' ? '-clickable' : '',
        this.type === 'text' ? '-text' : '',
        this.hasTags ? '-has-tags' : '',
        this.xs ? '-xs' : '',
        this.xxs ? '-xxs' : '',
        this.light ? '-light' : '',
        this.superLight ? '-super-light' : '',
        this.wrap ? '-wrap' : '',
        this.$slots.prefix ? '-has-prefix' : '',
        this.$slots.suffix ? '-has-suffix' : '',
        this.noPadding ? '-no-padding' : '',
        this.showPlaceholder ? '-show-placeholder' : '',
        this.focused ? '-focused' : '',
        this.hasError ? '-has-error' : '',
      ];
    },
  },
  methods: {
    resolveStyles,
    emitClick(event) {
      if (this.disabled || this.readOnly) {
        return;
      }
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped type="text/scss">
  .m-focusable {
    display: flex;
    align-items: center;
    min-height: $input-height-default;
    padding: .3rem .8rem;
    overflow: hidden;
    border: 1px solid $input-border-color;
    border-radius: $default-border-radius;

    &.-has-error {
      border-color: map_get($red, 'base');

      &.-focused {
        box-shadow: 0 0 0 2px rgb(231 117 143 / 20%);
      }
    }

    ._slot-wrapper {
      z-index: 1;
      display: flex;
      flex-grow: 1;
      align-items: center;
      min-width: 0;

      &:empty::before {
        padding-left: .4rem;
        color: $font-color-tertiary;
        content: attr(data-placeholder);
      }
    }

    &.-no-padding:not(.-show-placeholder) {
      padding: 0;
    }

    &.-wrap {
      ._slot-wrapper {
        flex-wrap: wrap;
      }
    }

    &.-disabled {
      background: map_get($grey, 'lighten-4');

      ._input {
        color: $font-color-disabled;
      }
    }

    &.-full-width {
      width: 100%;
    }

    &.-hide-border {
      resize: none;
      border: none;
      outline: none;

      &:not(.-hide-hover) {
        &:hover {
          background-color: $hover-color;

          &.-disabled {
            background-color: transparent;
          }
        }
      }
    }

    &:not(.-hide-hover) {
      &:hover {
        &:not(.-disabled) {
          &:not(.-hide-border) {
            &.-clickable {
              background-color: $hover-color;
            }

            &:not(.-has-error) {
              &.-text {
                border-color: $primary-color;
              }
            }
          }
        }
      }

      &.-focused {
        &:not(.-disabled) {
          &:not(.-hide-border) {
            &:not(.-has-error) {
              &.-text {
                border-color: $primary-color;
                box-shadow: 0 0 0 2px rgb(59 177 207 / 20%);
              }
            }
          }
        }
      }
    }

    ._prefix {
      display: flex;
      align-items: center;
    }

    ._placeholder {
      margin-left: .2rem;
    }

    &.-has-prefix {
      ._prefix {
        padding-right: .4rem;
      }
    }

    &.-small {
      min-height: $input-height-sm;
      padding: .2rem .6rem;
    }

    &.-xs {
      min-height: $xs-input-height;
      padding: .2rem .4rem;
    }

    &.-xxs {
      min-height: $xxs-input-height;
      padding: .2rem;
    }

    &.-has-tags {
      padding: 0 .8rem;

      ._slot-wrapper {
        padding-top: .5rem;
      }

      &.-small {
        padding: 0 .6rem;

        ._slot-wrapper {
          padding-top: .2rem;
        }
      }

      &.-xs {
        padding: 0 .4rem;

        ._slot-wrapper {
          padding-top: .2rem;
        }
      }
    }

    ._suffix {
      margin-left: auto;
    }

    &.-has-suffix {
      ._suffix {
        padding-left: .4rem;
      }
    }

    &.-light {
      color: $font-color-secondary;
    }

    &.-super-light {
      color: $font-color-tertiary;
    }

    &.-cursor-text {
      cursor: text;
    }

    &.-cursor-pointer {
      cursor: pointer;
    }

    &.-cursor-default {
      cursor: inherit;
    }

    &.-cursor-disabled {
      cursor: not-allowed;
    }
  }
</style>

<template>
  <sign-in :title="$t('passwordForget.header')">
    <password-forget-form />
  </sign-in>
</template>

<script>
import PasswordForgetForm from '@/components/PasswordForgetForm.vue';
import SignIn from '@/components/SignIn.vue';

export default {
  name: 'PasswordForget',
  components: {
    PasswordForgetForm,
    SignIn,
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>

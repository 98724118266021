<template>
  <div class="goal-cascade-table-item">
    <cascade-table-row
      :key="tableItem.entity.uid"
      :identifier="tableItem.guid"
      :data-id="tableItem.entity.uid"
      :data-draggable-id="tableItem.index"
      :entity="tableItem.entity"
      :loading="loading"
      :expanded="expanded"
      :show-expand="isCascading"
      :has-children="tableItem.hasChildren"
      :dragging="dragging"
      :is-last-item="tableItem.isLastItem"
      :show-drag-over-bottom-targets="showDragOverBottomTargets"
      :show-drag-over-top-targets="showDragOverTopTargets"
      :props="props"
      :indentation-level="indentationLevel"
      :update-property="updateProperty"
      :has-empty-row="hasEmptyRow"
      :selected="isSelected"
      :selected-secondary="selectedGoalIds.includes(tableItem.entity.uid) && tableItem.entity.accessRight === accessPolicyType.read"
      :disabled="disabled"
      :class="itemClass"
      :read-only="readOnly"
      :show-checkbox="showCheckbox"
      :focused="typeof $route.query.goalId === 'undefined'"
      :wrap-cells="wrapCells"
      :inline-editable="inlineEditable"
      :error-messages="errorMessages"
      :show-progress-diff="showProgressDiff"
      :filler-column-width="fillerColumnWidth"
      :progress-display-option="progressDisplayOption"
      :drag-depth="dragDepth"
      @select-row="$emit('select-row', { goal: tableItem.entity })"
      @contextmenu="handleContextMenuClick"
      @click="openInModal"
      @prop-clicked="handlePropClick"
      @expand-clicked="toggleExpand"
      @schedule-expand="scheduleExpand"
      @cancel-expand="cancelExpand"
    />
    <goal-context-menu
      v-if="showRightClickMenu"
      ref="contextMenu"
      :create-loading="createLoading"
      :goal="tableItem.entity"
      :can-create="canCreate"
      show-expand-buttons
      @goals-duplicated="$emit('goals-duplicated')"
      @prop-edited="$emit('prop-edited', $event)"
      @create-beneath="$emit('create-beneath', $event)"
      @expand-all="$emit('expand-all', $event)"
      @collapse-all="$emit('collapse-all', $event)"
    />
    <m-dialog
      v-if="showUpdateProgressModal"
      v-model:value="showUpdateProgressModal"
      hide-footer
      :fullscreen-on-mobile="false"
      :body-style="{ overflow: 'visible' }"
      :card-style="{ overflow: 'visible' }"
      :max-width="$modalSizes.lg"
    >
      <goal-update-editor
        :key="tableItem.entity.uid"
        ref="goalUpdateEditor"
        allow-goal-activity
        :goal="tableItem.entity"
        :goal-children="[tableItem.entity]"
        auto-add-goal-activity
        auto-focus
        @created="showUpdateProgressModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import CascadeTableRow from '@/components/table/CascadeTableRow.vue';
import GoalContextMenu from '@/components/goal/GoalContextMenu.vue';
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { accessPolicyType, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { toRef } from 'vue';

export default {
  name: 'GoalCascadeTableItem',
  props: {
    tableItem: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    indentationLevel: {
      type: Number,
      default: 0,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    dragDepth: {
      type: Number,
      default: 0,
    },
    showDragOverTargets: {
      type: Boolean,
      default: false,
    },
    showDragOverTopTargets: {
      type: Boolean,
      default: false,
    },
    showDragOverBottomTargets: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Array,
      required: true,
    },
    parentIds: {
      type: Array,
      required: true,
    },
    isCascading: {
      type: Boolean,
      default: false,
    },
    selectedGoalIds: {
      type: Array,
      default: () => [],
    },
    dragOver: {
      type: Array,
      default: () => [],
    },
    disabledGoalIds: {
      type: Array,
      default: () => [],
    },
    disabledCondition: {
      type: Function,
      default: () => false,
    },
    passDisabledToChildren: {
      type: Boolean,
      default: false,
    },
    hasEmptyRow: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: String,
      required: true,
    },
    validate: {
      type: Function,
      default: () => ({}),
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    showRightClickMenu: {
      type: Boolean,
      default: false,
    },
    allowPropActions: {
      type: Boolean,
      default: false,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    activeGoalId: {
      type: Number,
      default: 0,
    },
    wrapCells: {
      type: Boolean,
      default: false,
    },
    inlineEditable: {
      type: Boolean,
      default: false,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    fillerColumnWidth: {
      type: Number,
      default: 40,
    },
    progressDisplayOption: { type: String, default: undefined },
  },
  emits: ['select-row', 'selection-right-click', 'schedule-expand', 'cancel-expand', 'goals-duplicated', 'prop-edited', 'create-beneath', 'toggle-expand', 'expand-all', 'collapse-all'],
  components: { GoalContextMenu, CascadeTableRow, GoalUpdateEditor },
  setup(props) {
    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));
    const { properties: goalProperties } = useGoalProperty();
    return { peekModeSvc, goalProperties };
  },
  data() {
    return {
      showUpdateProgressModal: false,
      showContextMenu: false,
      accessPolicyType,
    };
  },
  computed: {
    errorMessages() {
      if (!this.showErrors) {
        return {};
      }
      return this.validate({ toValidate: this.tableItem.entity, selectRulesFrom: this.tableItem.entity });
    },
    disabled() {
      return this.disabledGoalIds.includes(this.tableItem.entity.uid) || this.disabledCondition(this.tableItem.entity);
    },
    isSelected() {
      return this.selectedGoalIds.includes(this.tableItem.entity.uid) || this.showDragOverTargets;
    },
    children() {
      return this.tableItem.entity.children;
    },
    canComment() {
      return !this.readOnly && this.tableItem.entity.publishedAt !== null && [accessPolicyType.full, accessPolicyType.write, accessPolicyType.comment].includes(this.tableItem.entity.accessRight);
    },
  },
  methods: {
    handleContextMenuClick(event) {
      if (!this.showRightClickMenu) {
        return;
      }

      event.preventDefault();
      if (this.selectedGoalIds.length > 1 && this.selectedGoalIds.includes(this.tableItem.entity.uid)) {
        this.$emit('selection-right-click', event);
        return;
      }

      this.$emit('select-row', { goal: this.tableItem.entity });
      this.$refs.contextMenu.show(event);
    },
    openInModal() {
      if (this.onClick !== null) {
        if (this.disabled) {
          return;
        }
        this.onClick({ goalId: this.tableItem.entity.uid, index: this.index });
        return;
      }
      this.peekModeSvc.openGoal({ goalId: this.tableItem.entity.uid });
    },
    handlePropClick(prop) {
      if (!this.allowPropActions || !this.canComment) {
        this.openInModal();
        return;
      }

      if (this.tableItem.entity.progressMeasurement !== goalProgressMeasurement.none && ([goalConfig.edges.cachedCalculatedCurrent].includes(prop.key) || prop.property.type === propertyType.status)) {
        this.showUpdateProgressModal = true;
        return;
      }

      this.openInModal();
    },
    scheduleExpand() {
      this.$emit('schedule-expand', this.tableItem);
    },
    cancelExpand() {
      this.$emit('cancel-expand', this.tableItem);
    },
    toggleExpand() {
      this.$emit('toggle-expand', this.tableItem);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";
</style>

<template>
  <div class="jira-form">
    <m-content
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <div class="_item">
        <m-content>
          <m-radio-group
            :value="localQuery.operationMode"
            :options="operationModes"
            inline
            @change="updateOperationMode"
          />
        </m-content>
      </div>
      <div
        v-if="localQuery.operationMode === jiraOperationMode.overallProgress"
        class="_item"
      >
        <jira-overall-progress
          :jira-query="localQuery"
          :data-source="dataSource"
          @overall-count-jql-submit="changeJQL('overallCountJql', $event)"
          @total-jql-submit="changeJQL('totalJql', $event)"
        />
      </div>
      <div
        v-if="localQuery.operationMode === jiraOperationMode.countOfIssues"
        class="_item"
      >
        <jira-count-of-issues
          :jira-query="localQuery"
          :data-source="dataSource"
          @count-submit="changeJQL('countJql', $event)"
        />
      </div>
      <div
        v-if="errorMessage !== ''"
        class="_error-messages"
      >
        <m-alert
          :message="errorMessage"
          type="error"
        />
      </div>
      <div class="_information">
        <m-btn
          inline
          inherit-color
          underlined
          super-light
          hide-border
          xs
          icon="question-circle"
          :href="$t('jiraForm.learnJqlLink')"
          target="_blank"
        >
          {{ $t('jiraForm.learnJql') }}
        </m-btn>
      </div>
      <div class="_item">
        <always-sync-checkbox v-model:value="localQuery.alwaysSync" />
      </div>
    </m-content>
    <m-divider xs />
    <template v-if="localQuery.uid === undefined">
      <m-content padding-small>
        <div class="_actions">
          <div class="_btns">
            <m-btn
              class="_btn"
              @click="$emit('close')"
            >
              {{ $t('general.cancel') }}
            </m-btn>
            <m-btn
              color="primary"
              class="_btn"
              :loading="mutateLoading"
              :disabled="!dataSource[customDatasourceProperty.jiraConnectionOk]"
              @click="save"
            >
              {{ $t('general.save') }}
            </m-btn>
          </div>
        </div>
      </m-content>
    </template>
    <template v-else>
      <m-card-item
        icon="save"
        :loading="mutateLoading"
        :padding-x="8"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-card-item>
      <m-card-item
        icon="sync"
        :loading="syncLoading"
        :padding-x="8"
        @click="syncNow"
      >
        {{ $t('dataSource.syncNow') }}
      </m-card-item>
      <m-card-item
        icon="delete"
        :padding-x="8"
        :loading="deleteLoading"
        @click="deleteJiraQuery"
      >
        {{ $t('general.delete') }}
      </m-card-item>
    </template>
  </div>
</template>

<script>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import JiraCountOfIssues from '@/components/datasource/JiraCountOfIssues.vue';
import JiraOverallProgress from '@/components/datasource/JiraOverallProgress.vue';
import useJira from '@/composables/integrations/jira/jira';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { customDatasourceProperty, jiraError, jiraOperationMode } from 'shared/constants.json';
import { jqlLink } from '@/lib/jira';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'JiraForm',
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
    goal: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['close', 'deleted'],
  components: { JiraOverallProgress, JiraCountOfIssues, AlwaysSyncCheckbox },
  setup() {
    const { createQuery, createQueryLoading, updateQuery, updateQueryLoading, deleteQuery, deleteQueryLoading } = useJira();

    return {
      createQuery,
      updateQuery,
      deleteQuery,
      mutateLoading: computed(() => createQueryLoading.value || updateQueryLoading.value),
      deleteLoading: deleteQueryLoading,
    };
  },
  data() {
    return {
      syncLoading: false,
      documentsLoading: false,
      jiraOperationMode,
      localQuery: {
        totalJql: '',
        overallCountJql: '',
        countJql: '',
        operationMode: jiraOperationMode.overallProgress,
        error: '',
        alwaysSync: false,
      },
      customDatasourceProperty,
    };
  },
  computed: {
    errorMessage() {
      if (!this.dataSource[customDatasourceProperty.jiraConnectionOk]) {
        return this.$t('jiraForm.connectionNotOk');
      }

      if (this.localQuery.error === jiraError.jqlMalformed) {
        return this.$t('jiraForm.jqlMalformed');
      }

      return '';
    },
    jqlLink() {
      return jqlLink({ dataSource: this.dataSource, jql: this.localQuery.jql });
    },
    operationModes() {
      return jiraOperationMode.all.map((mode) => ({
        value: mode,
        label: this.$t(`jiraForm.operationModes.${camelCase(mode)}.label`),
      }));
    },
  },
  methods: {
    changeJQL(key, val) {
      this.localQuery[key] = val;
      this.localQuery.error = '';
    },
    save() {
      if (this.localQuery.uid === undefined) {
        this.createJiraQuery(this.localQuery);
        return;
      }

      this.updateJiraQuery(this.localQuery);
    },
    createJiraQuery(jiraQuery) {
      this.createQuery({
        ...jiraQuery,
        dataSource: { uid: this.dataSource.uid },
        goal: { uid: this.goal.uid },
      }).then((query) => {
        this.localQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    updateJiraQuery(jiraQuery) {
      this.updateQuery(jiraQuery).then((query) => {
        this.localQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    syncNow() {
      this.syncLoading = true;
      this.updateQuery(this.goal.jiraQuery).then((query) => {
        this.localQuery = copy(query);
        this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
    deleteJiraQuery() {
      const deleteMethod = () => this.deleteQuery(this.goal.jiraQuery.uid).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        this.$emit('deleted');
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('dataSource.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    updateOperationMode(value) {
      this.localQuery.operationMode = value;
    },
  },
  created() {
    if (this.query !== null) {
      this.localQuery = copy(this.query);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .jira-form {
    margin-bottom: .6rem;

    ._error-messages {
      margin-bottom: 2rem;
    }

    ._jira-issue-link {
      margin-bottom: 1.4rem;
    }

    ._operation-selection {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 100%;
    }

    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }

      ._link {
        display: flex;

        ._icon {
          margin-left: .4rem;
        }
      }
    }

    ._information {
      margin-top: -.8rem;
      margin-bottom: 1.6rem;
    }

    ._actions {
      display: flex;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }
      }
    }
  }

</style>

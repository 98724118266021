import { dataSourceType, featureFlag, productPlan } from 'shared/constants.json';
import { plans } from 'shared/plans.json';

export const getPlan = (planId) => {
  const filtered = plans.filter((p) => p.id === planId);
  if (filtered.length === 0) {
    throw new Error('missing planId');
  }
  return filtered[0];
};

export const featureFlagToPlanFeature = (flag, plan) => {
  const map = {
    // features
    [featureFlag.multiGoalAlignment]: plan.featureAccess.multiGoalAlignment,
    [featureFlag.weightedGoals]: plan.featureAccess.weightedGoals,
    [featureFlag.publishedViews]: plan.featureAccess.publishedViews,
    [featureFlag.userProvisioning]: plan.featureAccess.userProvisioning,
    [featureFlag.saml]: plan.featureAccess.saml,
    [featureFlag.companyInfo]: plan.featureAccess.companyInfo,
    [featureFlag.advancedOkrRules]: plan.featureAccess.advancedOKRRules,
    [featureFlag.healthDashboard]: plan.featureAccess.healthDashboard,
    [featureFlag.goalTree]: plan.featureAccess.goalTree,
    [featureFlag.customDashboards]: plan.featureAccess.privateDashboardsVolume > 0 || plan.featureAccess.publicDashboardsVolume > 0,
    [featureFlag.restrictPasswordLogin]: true,
    [featureFlag.slack]: true,
    [featureFlag.microsoftBot]: true,
    // data integrations
    [dataSourceType.excel]: plan.integrations.excel,
    [dataSourceType.sheets]: plan.integrations.sheets,
    [dataSourceType.jira]: plan.integrations.jira,
    [dataSourceType.asana]: plan.integrations.asana,
    [dataSourceType.hubspot]: plan.integrations.hubspot,
    [dataSourceType.salesforce]: plan.integrations.salesforce,
    [dataSourceType.msPowerBi]: plan.integrations.msPowerBi,
    [dataSourceType.msPlanner]: plan.integrations.msPlanner,
  };
  return map[flag];
};

export const plansOrder = [
  productPlan.business,
  productPlan.pro,
  productPlan.enterprise,
];

export const minimumPlanForFeature = (flag) => {
  for (let i = 0; i < plansOrder.length; i++) {
    const plan = getPlan(plansOrder[i]);
    const planFeature = featureFlagToPlanFeature(flag, plan);
    if (planFeature === true) {
      return plan;
    }
  }
  return undefined;
};

export const nextPlan = (plan) => {
  switch (plan) {
    case productPlan.trial:
      return productPlan.business;
    case productPlan.starter:
    case productPlan.business:
      return productPlan.pro;
    case productPlan.pro:
      return productPlan.enterprise;
    default:
      return undefined;
  }
};

<template>
  <div class="salesforce-summary">
    <div class="_item">
      <div class="_label">
        {{ $t('salesforceSummary.report') }}:
      </div>
      <div class="_content">
        {{ query.reportName }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('salesforceSummary.field') }}:
      </div>
      <div class="_content">
        {{ query.reportFieldName }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SalesforceSummary',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .salesforce-summary {
    ._item {
      display: flex;

      ._label {
        flex: 0 0 5.4rem;
        color: $font-color-secondary;
      }
    }
  }
</style>

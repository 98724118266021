import useLocalOverwrite from '@/composables/saved-views/local-overwrite';
import { isEqual } from 'lodash-es';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { savedView as savedViewConfig } from 'shared/api/query/configs.json';
import { sortAlphaNumeric, sortByAttr } from 'shared/lib/sort';

const SAVED_VIEW_LOCAL_OVERWRITE = 'saved_view_local_overwrite';

export default function useLocalOverwritesParams(localStorage, loggedInUserAccount) {
  const localStorageKey = `${loggedInUserAccount.value.uid}_${SAVED_VIEW_LOCAL_OVERWRITE}`;

  const localOverwrites = useLocalOverwrite(savedViewConfig.edges.params, localStorageKey, localStorage);

  const equalParams = (params1, params2) => {
    if (params1.props === undefined || params2.props === undefined) {
      return isEqual(params1, params2);
    }

    const getUid = (obj) => {
      if (obj === undefined) {
        return undefined;
      }

      return obj.uid;
    };

    const cleanParams = (params) => {
      const cleaned = {
        ...params,
        props: params.props.map((p) => ({ key: p.key, show: p.show, width: p.width, progressDisplayOption: p.progressDisplayOption })).sort(sortByAttr('key', sortAlphaNumeric)),
      };
      if (isNullOrUndefined(params.propOrder) || (Array.isArray(params.propOrder) && params.propOrder.length === 0)) {
        delete cleaned.propOrder;
      }
      if (isNullOrUndefined(params.filter)) {
        delete cleaned.filter;
      } else {
        cleaned.filter = {
          ...params.filter,
          children: params.filter.children.map((c) => ({
            ...c,
            children: c.children.map((ch) => ({
              ...ch,
              scope: {
                ...ch.scope,
                property: { uid: getUid(ch.scope.property) },
              },
              key: undefined,
            })),
            key: undefined,
          })),
        };
      }
      if (isNullOrUndefined(params.order) || (Array.isArray(params.order) && params.order.length === 0)) {
        delete cleaned.order;
      }
      if (isNullOrUndefined(params.showParents)) {
        cleaned.showParents = true;
      }

      return cleaned;
    };

    const cp1 = cleanParams(params1);
    const cp2 = cleanParams(params2);

    return isEqual(cp1, cp2);
  };

  const hasLocalOverwrite = (originalView) => {
    const overwrite = localOverwrites.findLocalOverwrite(originalView);
    return overwrite !== undefined && !equalParams(overwrite, originalView.params);
  };

  return {
    saveOverwrite: localOverwrites.saveOverwrite,
    removeLocalOverwrite: localOverwrites.removeLocalOverwrite,
    mergeWithLocalOverwrites: localOverwrites.mergeWithLocalOverwrites,
    initOverwrites: localOverwrites.initOverwrites,

    hasLocalOverwrite,
  };
}

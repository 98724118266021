<template>
  <not-found-page
    v-if="(user === null || user.uid === undefined)"
    :to="{ name: routeName.home }"
    :title="$t('notFoundPage.title', { type: $t('notFoundPage.profile')})"
    :anchor-text="$t('notFoundPage.buttonTextGoto', { name: $t('navigation.home')})"
  />
  <page-layout
    v-else
    class="profile"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
      />
    </template>
    <profile-body
      :user="user"
    />
  </page-layout>
</template>

<script>
import NotFoundPage from '@/components/NotFoundPage.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ProfileBody from '@/components/profile/ProfileBody.vue';
import useUsers from '@/composables/user/users';
import { routeName } from 'shared/constants.json';

export default {
  name: 'Profile',
  props: {
    userId: {
      type: [String, Number],
      default: '',
    },
  },
  components: {
    NotFoundPage,
    PageLayout,
    PageTopBar,
    ProfileBody,
  },
  setup() {
    const { selectSingle } = useUsers();
    return { selectSingle };
  },
  data() {
    return { routeName };
  },
  computed: {
    user() {
      if (this.id === 0) {
        return null;
      }

      return this.selectSingle(this.id);
    },
    id() {
      const id = parseInt(this.userId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('profileLayout.users'),
          disabled: true,
        },
        {
          title: `${this.user.firstName} ${this.user.lastName}`,
          disabled: true,
        },
      ];
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>

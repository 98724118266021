import useParamCleaner from '@/composables/view-params/clean';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { viewApplication, viewType } from 'shared/constants.json';

export default function useSavedViewCleaner() {
  const paramCleaner = useParamCleaner();

  const clearProps = ({ view, props = [] }) => {
    if (isNullOrUndefined(view) || isNullOrUndefined(view.params) || isNullOrUndefined(view.params.props)) {
      return view;
    }

    return {
      ...view,
      params: paramCleaner.clearProps(view.params, props),
    };
  };

  const setDefaultWidth = ({ view, props }) => {
    if (isNullOrUndefined(view) || isNullOrUndefined(view.params) || isNullOrUndefined(view.params.props)) {
      return view;
    }

    if (![viewType.cascade].includes(view.viewType)) {
      return view;
    }

    return {
      ...view,
      params: paramCleaner.setDefaultWidth(view.params, props),
    };
  };

  const clearGrouping = ({ view }) => {
    if (isNullOrUndefined(view) || isNullOrUndefined(view.params)) {
      return view;
    }

    if (![viewType.atlas].includes(view.viewType)) {
      return view;
    }

    return {
      ...view,
      params: paramCleaner.clearGrouping(view.params),
    };
  };

  const clearIsPublic = ({ view }) => {
    if (isNullOrUndefined(view)) {
      return view;
    }

    const isPublic = typeof view.isPublic !== 'undefined' ? view.isPublic : [
      viewApplication.goalSpace,
      viewApplication.updateSpace,
      viewApplication.updateFeedSpace,
      viewApplication.insightSpace,
    ].includes(view.viewApplication);
    return {
      ...view,
      isPublic,
    };
  };

  const clearOrder = ({ view, props }) => {
    if (isNullOrUndefined(view) || isNullOrUndefined(view.params) || isNullOrUndefined(view.params.order)) {
      return view;
    }

    return {
      ...view,
      params: paramCleaner.clearOrder(view.params, props),
    };
  };

  const clearFilter = ({ view, props = [] }) => {
    if (isNullOrUndefined(view) || isNullOrUndefined(view.params) || isNullOrUndefined(view.params.filter)) {
      return view;
    }

    return {
      ...view,
      params: paramCleaner.clearFilter(view.params, props),
    };
  };

  const clearView = ({ view, props = [] }) => {
    let clearedView = clearProps({ view, props });
    clearedView = setDefaultWidth({ view: clearedView, props });
    clearedView = clearFilter({ view: clearedView, props });
    clearedView = clearOrder({ view: clearedView, props });
    clearedView = clearGrouping({ view: clearedView });
    clearedView = clearIsPublic({ view: clearedView });
    return clearedView;
  };

  return { clearView };
}

<template>
  <sign-in
    :title="title"
    :show-nav="false"
    :show-footer="false"
    :component-style="{backgroundColor: '#F3F2F1'}"
    :show-images="false"
  >
    <div
      v-if="!samlLogin"
      class="ms-login"
    >
      <sign-in-content>
        <div class="_sso">
          <div class="_btn">
            <m-btn
              block
              :button-style="{ width: '100%' }"
              icon="microsoft-color"
              @click="msLogin"
            >
              {{ $t('login.viaMicrosoft') }}
            </m-btn>
          </div>
          <div class="_btn">
            <m-btn
              block
              :button-style="{ width: '100%' }"
              icon="google-color"
              @click="googleLogin"
            >
              {{ $t('login.viaGoogle') }}
            </m-btn>
          </div>
        </div>
        <div class="_errors">
          <o-auth-error
            v-if="emailNotRegistered[oAuthProvider.google] !== ''"
            :email="emailNotRegistered[oAuthProvider.google]"
            :provider="oAuthProvider.google"
            signup-new-window
          />
          <o-auth-error
            v-if="emailNotRegistered[oAuthProvider.microsoft] !== ''"
            :email="emailNotRegistered[oAuthProvider.microsoft]"
            :provider="oAuthProvider.microsoft"
            signup-new-window
          />
        </div>
        <m-divider small />
        <password-login
          :loading="passwordLoading"
          :sso-route="''"
          :hide-register-link="true"
          @submit="passwordLogin"
          @ssologin="samlLogin = true"
        />
      </sign-in-content>
    </div>
    <saml-login
      v-if="samlLogin"
      :follow-link="false"
      @submit="(email) => samlLoginAction(email)"
    />
    <div
      v-if="samlLogin"
      class="_saml-back"
    >
      <m-link
        inherit-color
        @click="samlLogin = false"
      >
        {{ $t('login.back') }}
      </m-link>
    </div>
  </sign-in>
</template>

<script>
import * as microsoftTeams from '@microsoft/teams-js';
import OAuthError from '@/components/OAuthError.vue';
import PasswordLogin from '@/components/PasswordLogin.vue';
import SamlLogin from '@/components/SamlLogin.vue';
import SignIn from '@/components/SignIn.vue';
import SignInContent from '@/components/SignInContent.vue';
import axios from 'axios';
import useMSTeams from '@/composables/msteams';
import { doMSTeamsLogin } from '@/api';
import { encodeURLValues } from '@/lib/url';
import { loginMethod } from '@/constants.json';
import { oAuthProvider, routeName } from 'shared/constants.json';

export default {
  name: 'MSLogin',
  components: { SignIn, SignInContent, PasswordLogin, SamlLogin, OAuthError },
  setup() {
    const { validateSuccessCallback } = useMSTeams();
    return { validateSuccessCallback };
  },
  data() {
    return {
      microsoftLoginError: '',
      microsoftLoading: false,
      samlLoading: false,
      googleLoading: false,
      passwordLoading: false,
      emailNotRegistered: oAuthProvider.all.reduce((res, cur) => ({ ...res, [cur]: '' }), {}),
      oAuthProvider,
      samlLogin: false,
    };
  },
  computed: {
    title() {
      if (this.samlLogin) {
        return this.$t(`login.header.${loginMethod.sso}`);
      }
      return this.$t('login.header.password');
    },
  },
  emits: ['session-established'],
  methods: {
    passwordLogin({ email, password }) {
      this.passwordLoading = true;
      axios.post('/api/v1/msteams/password-login', {
        email,
        password,
      }).then((response) => {
        this.passwordLoading = false;
        if (response.status === 401 || response.status === 400) {
          this.$showSnackbar({ color: 'error', message: this.$t('login.emailPasswordCombinationWrong') });
          return;
        }
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$emit('session-established');
      }).catch((error) => {
        this.passwordLoading = false;
        if (error.response.status === 400 || error.response.status === 401) {
          this.$showSnackbar({ color: 'error', message: this.$t('login.emailPasswordCombinationWrong') });
          return;
        }

        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    login({ oauthProvider = '', samlEmail = '' }) {
      const startAuth = (context) => {
        const request = { action: 'init', provider: oauthProvider, samlEmail, loginHint: '', tenantId: context.user.tenant.id };
        const url = `${window.location.origin}/#/msteams/auth?${encodeURLValues(request)}`;

        microsoftTeams.authentication.authenticate({
          url,
          width: 600,
          height: 600,
        }).then((result) => {
          const success = JSON.parse(result);

          doMSTeamsLogin(success).then(() => {
            this.googleLoading = false;
            this.microsoftLoading = false;

            this.$emit('session-established');
          }).catch((error) => {
            this.googleLoading = false;
            this.microsoftLoading = false;

            if (error.response.status === 400) {
              this.emailNotRegistered[oauthProvider] = error.response.data;
              return;
            }

            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          });
        }).catch((error) => {
          this.googleLoading = false;
          this.microsoftLoading = false;

          if (error.message === 'CancelledByUser') {
            return;
          }

          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      };
      microsoftTeams.app.getContext().then((context) => startAuth(context));
    },
    googleLogin() {
      this.googleLoading = true;
      this.login({ oauthProvider: oAuthProvider.google });
    },
    msLogin() {
      this.microsoftLoading = true;
      this.login({ oauthProvider: oAuthProvider.microsoft });
    },
    samlLoginAction(email) {
      this.samlLoading = true;
      this.login({ samlEmail: email });
    },
  },
  created() {
    microsoftTeams.app.initialize();
  },
  beforeRouteLeave(to, from, next) {
    if ([routeName.logIn].includes(to.name)) {
      return;
    }
    if ([routeName.teamsAccountOkrs, routeName.teamsTeamOkrs].includes(to.name)) {
      next();
      return;
    }
    window.open(`/#${to.fullPath}`);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .ms-login {
    width: 100%;

    ._sso {
      ._btn {
        margin-bottom: .4rem;
      }
    }

    ._errors {
      color: map_get($orange, 'base');

      > * {
        padding: 0 .8rem;
        margin-top: .8rem;
      }
    }
  }

  ._saml-back {
    margin-top: 2rem;
    font-size: $font-size-3;
    color: $font-color-secondary;
    text-align: center;
    text-decoration: underline;
  }
</style>

<template>
  <div :class="['editor-file', showMenu ? '-visible' : '']">
    <m-content
      v-if="title === '' || uploadFailed"
      padding-xxs
      class="_add-file"
      @click="showPrompt"
    >
      <div class="_add-file-inner">
        <div class="_icon">
          <m-icon
            type="file-image"
            size="22"
            :color="$colors.grey.base"
          />
        </div>
        <div class="_title">
          {{ $t('editorFile.addAFile') }}
        </div>
        <input
          id="file"
          ref="fileInput"
          class="_file"
          type="file"
          :style="{ display: 'none' }"
          name="avatar"
          accept="*"
          @change="fileChanged"
        >
      </div>
    </m-content>
    <m-link
      v-else
      :href="link"
      target="_blank"
    >
      <m-content
        padding-xxs
        class="_wrapper"
        @click="prevent"
      >
        <div class="_inner">
          <div class="_icon">
            <m-icon
              type="paper-clip"
              size="18"
            />
          </div>
          <div class="_left">
            <div class="_title">
              {{ title }}
            </div>
            <div class="_size">
              <div class="_text">
                {{ size }}
              </div>
            </div>
            <div
              v-if="isEmpty(src)"
              class="_placeholder"
            >
              <m-spinner
                size="xxs"
                :color="$colors.grey.base"
                class="_spinner"
              />
              <div class="_progress">
                {{ progress }} %
              </div>
            </div>
          </div>
          <div class="_actions">
            <m-dropdown
              v-model:value="showMenu"
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
                small
                @click.prevent="showMenu = true"
              />
              <template #overlay>
                <m-card
                  list
                  no-padding
                >
                  <m-card-item
                    v-if="view.editable"
                    icon="delete"
                    @click.prevent="deleteNode"
                  >
                    {{ $t('general.delete') }}
                  </m-card-item>
                  <a
                    :download="title"
                    :href="link"
                  >
                    <m-card-item
                      icon="download"
                    >
                      {{ $t('general.download') }}
                    </m-card-item>
                  </a>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </div>
      </m-content>
    </m-link>
  </div>
</template>

<script>

import useFileInputDialog from '@/components/editor/file-input-dialog';
import { FILE_UPLOAD_LIMIT } from '@/lib/constants';
import { isEmpty } from 'shared/lib/object/object';
import { uploadFile } from '@/lib/image/image';

export default {
  name: 'EditorFile',
  props: {
    node: {
      type: Object,
      required: true,
    },
    updateAttrs: {
      type: Function,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const fileInputDialogHandler = useFileInputDialog();
    return { fileInputDialogHandler };
  },
  data() {
    return {
      showMenu: false,
      progress: 0,
      uploadFailed: false,
    };
  },
  computed: {
    link() {
      if (!isEmpty(this.src)) {
        return this.src;
      }
      return undefined;
    },
    src: {
      get() {
        return this.node.attrs.src;
      },
      set(src) {
        this.updateAttrs({ src });
      },
    },
    title: {
      get() {
        return this.node.attrs.title;
      },
      set(title) {
        this.updateAttrs({ title });
      },
    },
    file() {
      return this.node.attrs.file;
    },
    size: {
      get() {
        const size = this.node.attrs.size;
        if (size / 1000 < 1000) {
          return `${(size / 1000).toFixed(1)} kB`;
        }
        return `${(size / 1000000).toFixed(1)} MB`;
      },
      set(size) {
        this.updateAttrs({ size });
      },
    },
    autoOpen: {
      get() {
        return this.node.attrs.autoOpen;
      },
      set(value) {
        this.udpateAttrs({ autoOpen: value });
      },
    },
  },
  methods: {
    isEmpty,
    prevent(event) {
      if (isEmpty(this.src)) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    showPrompt() {
      if (!this.view.editable) {
        return;
      }

      this.fileInputDialogHandler.open(this.editor);
      this.$refs.fileInput.click();
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }

      const file = event.target.files[0];
      if (file.size > FILE_UPLOAD_LIMIT) {
        this.$showSnackbar({ color: 'error', message: this.$t('fileUpload.fileTooBig') });
        return;
      }

      this.updateAttrs({ title: file.name });
      this.updateAttrs({ size: file.size });
      this.upload(file);
    },
    deleteNode() {
      const tr = this.view.state.tr;
      const pos = this.getPos();
      tr.delete(pos, pos + this.node.nodeSize);
      this.view.dispatch(tr);
      this.showMenu = false;
    },
    onUploadProgress(event) {
      this.progress = Math.round((event.loaded / event.total) * 100);
    },
    upload(file) {
      this.uploadFailed = false;
      uploadFile(file, this.onUploadProgress).then((data) => {
        this.updateAttrs({ src: data.getURL });
      }).catch((e) => {
        this.uploadFailed = true;
        if (e.message.indexOf('bad input') > -1) {
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  mounted() {
    if (!isEmpty(this.src)) {
      return;
    }

    if (this.title === '') {
      if (this.autoOpen) {
        this.showPrompt();
        this.$nextTick(() => {
          this.updateAttrs({ autoOpen: false });
        });
      }
      return;
    }

    this.upload(this.file);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .editor-file {
    margin: .2rem 0;

    ._add-file {
      width: 100%;
      white-space: initial;
      background-color: map_get($grey, 'lighten-5');
      border-radius: $border-radius-sm;

      &:hover {
        border-color: $hover-color;
      }

      ._add-file-inner {
        display: flex;
        align-items: center;
        height: 2.8rem;

        ._title {
          margin-left: 1.2rem;
          color: $font-color-secondary;
        }
      }
    }

    ._wrapper {
      border-radius: $border-radius-sm;

      ._inner {
        display: flex;
        align-items: center;
        width: 100%;
        height: 2.8rem;
        color: $font-color-primary;

        ._icon {
          margin-right: .6rem;
        }

        ._left {
          display: flex;
          flex: 1 1 auto;
          align-items: flex-end;
          max-width: calc(100% - 6rem);

          ._title {
            max-width: calc(100% - 13rem);
            overflow: hidden;
            font-size: $font-size-4;
            line-height: 1.5;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          ._size {
            margin-left: .8rem;
            font-size: $font-size-2;
            line-height: 1.5;
            color: $font-color-secondary;
            white-space: initial;
          }

          ._placeholder {
            display: flex;
            color: $font-color-secondary;
            white-space: initial;

            ._spinner {
              margin-left: 1.2rem;
              height: 2rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            ._progress {
              display: flex;
              align-items: flex-end;
              margin-left: .6rem;
              font-size: $font-size-2;
            }
          }
        }

        ._actions {
          display: none;
          margin-left: auto;
        }
      }

      &:hover {
        background-color: $hover-color;

        ._actions {
          display: block;
        }
      }
    }

    &.-visible {
      ._wrapper {
        background-color: $hover-color;

        ._actions {
          display: block;
        }
      }
    }
  }
</style>

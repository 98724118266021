<template>
  <m-card
    no-padding
    list
  >
    <m-card-item
      v-for="action in gridPageService.createActions"
      :key="action.gridPageType"
      :icon="action.icon"
      :loading="loading === action.gridPageType"
      @click="add(action)"
    >
      {{ action.label }}
    </m-card-item>
  </m-card>
</template>

<script setup>
import useGridPage from '@/composables/grid-page/grid-page';
import useInlineEditing from '@/composables/inline-editing';
import useSnackBar from '@/composables/snackbar';
import { gridPageType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
  gridRow: {
    type: Object,
    required: true,
  },
  gridPage: {
    type: Object,
    required: true,
  },
});

const inlineEditingSvc = useInlineEditing();
const snackBar = useSnackBar();

const gridPageService = useGridPage();

const loading = ref('');
function add(action) {
  loading.value = action.gridPageType;
  gridPageService.addGridPageTile({ type: action.gridPageType, gridPageRow: { uid: props.gridRow.uid, gridPage: { uid: props.gridPage.uid } } }).then((res) => {
    if (![gridPageType.singleGoal, gridPageType.iframe].includes(action.gridPageType)) {
      inlineEditingSvc.set(res.uid);
    }
  }).catch(logCatch(() => { snackBar.error(); }))
    .finally(() => {
      loading.value = '';
    });
  emit('close');
}

</script>

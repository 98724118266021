<template>
  <div class="hubspot-form">
    <m-content
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <div class="_item">
        <div class="_label">
          {{ $t('hubspotForm.selectTrackMetric') }}
        </div>
        <m-select
          v-model:value="localQuery.trackMetric"
          :items="trackMetrics"
          value-key="id"
          item-text="title"
          full-width
          match-trigger-width
          show-search
        />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('hubspotForm.selectTimeRange') }}
        </div>
        <div class="_time-range">
          <m-date-picker
            :value="fromISO(localQuery.start)"
            :date-time="DateTime"
            class="_date"
            full-width
            include-time
            :placeholder="$t('hubspotForm.startPlaceholder')"
            clearable
            @input="updateStart"
          />
          <m-date-picker
            :value="fromISO(localQuery.end)"
            :min="localQuery.start"
            :date-time="DateTime"
            class="_date"
            full-width
            include-time
            :placeholder="$t('hubspotForm.endPlaceholder')"
            clearable
            @input="updateEnd"
          />
        </div>
      </div>
      <div class="_item">
        <always-sync-checkbox v-model:value="localQuery.alwaysSync" />
      </div>
    </m-content>
    <m-divider xs />
    <template v-if="localQuery.uid === undefined">
      <m-content padding-small>
        <div class="_actions">
          <div class="_btns">
            <m-btn
              class="_btn"
              @click="$emit('close')"
            >
              {{ $t('general.cancel') }}
            </m-btn>
            <m-btn
              color="primary"
              class="_btn"
              :loading="mutateLoading"
              @click="save"
            >
              {{ $t('general.save') }}
            </m-btn>
          </div>
        </div>
      </m-content>
    </template>
    <template v-else>
      <m-card-item
        icon="save"
        :loading="mutateLoading"
        :padding-x="8"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-card-item>
      <m-card-item
        icon="sync"
        :loading="syncLoading"
        :padding-x="8"
        @click="syncNow"
      >
        {{ $t('dataSource.syncNow') }}
      </m-card-item>
      <m-card-item
        icon="delete"
        :padding-x="8"
        :loading="deleteLoading"
        @click="deleteHubspotQuery"
      >
        {{ $t('general.delete') }}
      </m-card-item>
    </template>
  </div>
</template>

<script>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import useHubspot from '@/composables/integrations/hubspot/hubspot';
import { DateTime } from 'luxon';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { fromISO, toISO } from 'shared/lib/time';
import { hubspotTrackMetric } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'HubspotForm',
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
    goal: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['close', 'deleted'],
  components: { AlwaysSyncCheckbox },
  setup() {
    const { createQuery, createQueryLoading, updateQuery, updateQueryLoading, deleteQuery, deleteQueryLoading } = useHubspot();

    return {
      createQuery,
      updateQuery,
      deleteQuery,
      mutateLoading: computed(() => createQueryLoading.value || updateQueryLoading.value),
      deleteLoading: deleteQueryLoading,
    };
  },
  data() {
    return {
      syncLoading: false,
      localQuery: { trackMetric: hubspotTrackMetric.all[0], start: null, end: null },
      DateTime,
      fromISO,
    };
  },
  computed: {
    trackMetrics() {
      return hubspotTrackMetric.all.map((trackMetric) => ({
        id: trackMetric,
        title: this.$t(`hubspotForm.trackMetric.${camelCase(trackMetric)}`),
      }));
    },
  },
  methods: {
    updateStart(date) {
      this.localQuery.start = toISO(date);
    },
    updateEnd(date) {
      this.localQuery.end = toISO(date);
    },
    save() {
      if (this.localQuery.uid === undefined) {
        this.createHubspotQuery({ ...this.localQuery });
        return;
      }

      this.updateHubspotQuery(this.localQuery);
    },
    createHubspotQuery(hubspotQuery) {
      this.createQuery({
        ...hubspotQuery,
        dataSource: { uid: this.dataSource.uid },
        goal: { uid: this.goal.uid },
      }).then((query) => {
        this.localQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    updateHubspotQuery(hubspotQuery) {
      this.updateQuery({
        ...hubspotQuery,
        dataSource: { uid: this.dataSource.uid },
      }).then((query) => {
        this.localQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    syncNow() {
      this.syncLoading = true;
      this.updateQuery(this.goal.hubspotQuery).then((query) => {
        this.localQuery = copy(query);
        this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
    deleteHubspotQuery() {
      const deleteMethod = () => this.deleteQuery(this.goal.hubspotQuery.uid).then(() => {
        this.$emit('deleted');
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('dataSource.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
  created() {
    if (this.query !== null) {
      this.localQuery = copy(this.query);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .hubspot-form {
    margin-bottom: .6rem;

    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }

      ._time-range {
        display: flex;
        flex-direction: row;

        ._date {
          flex: 0 0 calc(50% - .6rem);

          &:first-of-type {
            margin-right: 1.2rem;
          }
        }
      }
    }

    ._actions {
      display: flex;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }
      }
    }
  }
</style>

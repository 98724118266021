import usePropertyRepo from '@/composables/property/repo';
import { computed } from 'vue';
import { propertyApplication, propertyStatus, propertyType } from 'shared/constants.json';

export default function useProperties() {
  const repo = usePropertyRepo();

  const editableProperties = computed(() => repo.entityList.value
    .filter((p) => (!p.isGoalType && p.type !== propertyType.status)));

  const goalProperties = computed(() => repo.entityList.value
    .filter((prop) => prop.usedFor.includes(propertyApplication.goal) && prop.status === propertyStatus.active));

  const planningProperties = computed(() => repo.entityList.value
    .filter((prop) => prop.usedFor.includes(propertyApplication.goal) && prop.status === propertyStatus.active && prop.type === propertyType.space));

  const userProperties = computed(() => repo.entityList.value
    .filter((prop) => prop.usedFor.includes(propertyApplication.user) && prop.status === propertyStatus.active));

  const updateProperties = computed(() => repo.entityList.value
    .filter((prop) => prop.usedFor.includes(propertyApplication.update) && prop.status === propertyStatus.active));

  const spaceProperties = computed(() => repo.entityList.value
    .filter((prop) => prop.usedFor.includes(propertyApplication.space) && prop.status === propertyStatus.active));

  const spaceProperty = computed(() => repo.entityList.value
    .find((prop) => prop.status === propertyStatus.active && prop.type === propertyType.space));

  const lookupProperties = computed(() => repo.entityList.value
    .filter((p) => (!p.isGoalType && p.type !== propertyType.lookup)));

  const createSingleWithOptions = (entity) => {
    if (entity.type === propertyType.space) {
      return repo.createSingleWithSpaces(entity);
    }
    return repo.createSingleWithOptions(entity);
  };
  const updateSingleWithOptions = (entity) => {
    if (entity.type === propertyType.space) {
      return repo.updateSingleWithSpaces(entity);
    }
    return repo.updateSingleWithOptions(entity);
  };

  return {
    editableProperties,
    goalProperties,
    planningProperties,
    userProperties,
    updateProperties,
    spaceProperties,
    spaceProperty,
    lookupProperties,

    properties: repo.entityList,
    createSingle: repo.createSingle,
    updateSingle: repo.updateSingle,
    deleteSingle: repo.deleteSingle,
    createSingleWithOptions,
    updateSingleWithOptions,
    selectSingle: repo.selectSingle,
    selectMultiple: repo.selectMultiple,
    getList: repo.getList,
  };
}

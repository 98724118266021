<template>
  <div
    :class="['settings-switch-item', disabled ? '-disabled' : '', clickable ? '-clickable' : '']"
    @click="handleClick"
  >
    <div class="_left">
      <div
        v-if="icon !== ''"
        class="_icon"
      >
        <m-icon
          :type="icon"
          :size="iconSize"
          :color="$colors.grey.darken1"
        />
      </div>
      <div class="_text">
        <div class="_title">
          <component
            :is="heading === '' ? 'span' : heading"
            class="_inner"
          >
            {{ title }}
            <slot name="after-title" />
          </component>
        </div>
        <div
          v-if="subTitle !== ''"
          class="_sub-title"
        >
          {{ subTitle }}
        </div>
      </div>
    </div>
    <div class="_right">
      <slot name="before-switch" />
      <m-switch
        class="_switch"
        :value="value"
        :loading="loading"
        small
        :disabled="disabled"
        @click="handleToggleClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsSwitchItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: '20',
    },
    subTitle: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click', 'toggle-clicked'],
  methods: {
    handleClick(event) {
      if (this.disabled) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      this.$emit('click', event);
    },
    handleToggleClick(event) {
      if (this.disabled) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      this.$emit('toggle-clicked', event);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .settings-switch-item {
    display: flex;

    &.-clickable {
      cursor: pointer;
    }

    &.-disabled {
      cursor: not-allowed;
    }

    ._left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      ._icon {
        display: flex;
        flex: 0 0 4rem;
        align-items: flex-start;
      }

      ._text {
        flex: 1 1 auto;

        ._title {
          ._inner {
            display: flex;
            align-items: center;
          }
        }

        ._sub-title {
          margin-top: .6rem;
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }
    }

    ._right {
      display: flex;
      align-items: center;
      margin-left: auto;

      ._switch {
        width: 3rem;
        margin-left: .4rem;
      }
    }
  }
</style>

<template>
  <div
    ref="container"
    :class="['list-header', $attrs.class]"
    :style="style"
  >
    <div
      id="list-header-left"
      ref="left"
      class="_left"
    >
      <slot
        name="left"
      />
    </div>
    <div
      ref="right"
      class="_right"
    >
      <slot name="right" />
    </div>
  </div>
  <m-divider
    v-if="divider"
    small
  />
</template>

<script>
import useDebounce from '@/composables/debounce';

const minimumSpace = 500;
export default {
  name: 'ListHeader',
  props: {
    style: {
      type: Object,
      default: () => {},
    },
    tabsShown: {
      type: Number,
      default: 0,
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { debounce } = useDebounce();
    return { debounce };
  },
  data() {
    return { tooTight: false };
  },
  emits: ['set-header-tight'],
  methods: {
    checkIfTooTight() {
      if (this.$refs.container === null || this.$refs.left === null || this.$refs.right === null) {
        return;
      }
      const containerWidth = this.$refs.container.getBoundingClientRect().width;
      const leftWidth = this.$refs.left.getBoundingClientRect().width;
      const rightWidth = this.$refs.right.getBoundingClientRect().width;

      // The left element should reduce to its width to its minimum before the right element
      // reduces in size.
      if (this.tabsShown > 1) {
        this.tooTight = false;
        this.$emit('set-header-tight', this.tooTight);
        return;
      }
      if (this.tooTight) {
        // If it is too tight, we need to make sure enough space to expand exists
        // otherwise it will expand and collapse again.
        if (containerWidth - leftWidth < minimumSpace) {
          return;
        }
        this.tooTight = false;
        this.$emit('set-header-tight', this.tooTight);
        return;
      }
      if (containerWidth < leftWidth + rightWidth) {
        this.tooTight = true;
        this.$emit('set-header-tight', this.tooTight);
      }
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => this.debounce(this.checkIfTooTight, 100));
    this.resizeObserver.observe(this.$refs.container);
  },
  unmounted() {
    this.resizeObserver.disconnect();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .list-header {
    display: flex;

    ._right {
      display: flex;
    }

    ._left {
      margin-right: auto;
      display: flex;
      align-items: center;
    }
  }
</style>

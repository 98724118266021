c<template>
  <div class="data-source-details">
    <m-content
      boxed-xs
      padding
    >
      <div class="_integration-header">
        <m-btn
          icon="left"
          hide-border
          small
          :to="{ name: routeName.integrationSettings }"
        >
          {{ $t('dataSourceDetails.back') }}
        </m-btn>
      </div>
      <div class="_content">
        <m-btn
          v-if="$t(`dataSourceDetails.${camelCase(type)}.help`) !== ''"
          small
          super-light
          :href="$t(`dataSourceDetails.${camelCase(type)}.helpLink`)"
          hide-border
          icon="question-circle"
          target="_blank"
          class="_help-btn"
        >
          {{ $t(`dataSourceDetails.${camelCase(type)}.help`) }}
        </m-btn>
        <settings-switch-item
          :title="$t(`dataSourceDetails.${camelCase(type)}.title`)"
          :icon="icon"
          icon-size="32"
          :sub-title="$t(`dataSourceDetails.${camelCase(type)}.description`)"
          :value="accountSettings[accountSettingsEdgeByFlag(type)]"
          :disabled="!userHasRight([accessGroupFlag.accountWriteAccess]) || !canToggleFlag(type)"
          heading="h3"
          class="_switch"
          @click="toggle"
        />
        <m-divider />
        <m-section
          heading-size="h4"
          :title="$t('dataSourceDetails.connections')"
          :sub-title="$t(descriptionKey, { type: $t(`dataSourceDetails.${camelCase(type)}.title`) })"
        >
          <data-source-item
            v-for="ds in dataSources"
            :key="ds.uid"
            :ref="el => { if (el) dataSourceItemRefs[ds.uid] = el }"
            :data-source="ds"
            :clickable="false"
            :show-expire-action="true"
          />
          <m-btn
            block
            hide-border
            light
            small
            icon="plus"
            :button-style="{ justifyContent: 'flex-start' }"
            class="_add-btn"
            @click="create"
          >
            {{ $t('general.add') }}
          </m-btn>
        </m-section>
      </div>
    </m-content>
  </div>
</template>

<script>
import DataSourceItem from '@/components/datasource/DataSourceItem.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useDataSources from '@/composables/integrations/datasources';
import { accessGroupFlag, routeName } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { dataSourceIcon } from '@/lib/data-source';
import { useRoute } from 'vue-router';

export default {
  name: 'DataSourceDetails',
  components: { SettingsSwitchItem, DataSourceItem },
  setup() {
    const route = useRoute();
    const type = route.params.type;
    const { userHasRight } = useAccess();

    const {
      accountSettings,
      accountSettingsEdgeByFlag,
      toggleFlag,
      canToggleFlag,
    } = useAccountSettings();

    const { dataSourcesByType, createDataSource } = useDataSources();
    const dataSources = dataSourcesByType(type);
    return {
      type,

      accountSettings,
      accountSettingsEdgeByFlag,
      toggleFlag,
      canToggleFlag,

      dataSources,
      createDataSource,

      userHasRight,
      accessGroupFlag,
    };
  },
  data() {
    return {
      routeName,
      camelCase,
      dataSourceItemRefs: {},
    };
  },
  emits: ['select'],
  computed: {
    descriptionKey() {
      return 'dataSourceDetails.connectionsDescription';
    },
    icon() {
      return dataSourceIcon({ type: this.type });
    },
  },
  methods: {
    create() {
      this.createDataSource({
        name: this.$t(`dataSourcePicker.defaultName.${camelCase(this.type)}`),
        shared: false,
        type: this.type,
      }).then((dataSource) => {
        this.$emit('select', dataSource);
        this.$nextTick(() => {
          this.dataSourceItemRefs[dataSource.uid].edit();
        });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    toggle() {
      this.toggleFlag(this.type).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .data-source-details {
    ._integration-header {
      padding: 3rem 0 1rem;
      border-bottom: 1px solid $border-color;

      ._title {
        margin-top: 3rem;
      }
    }

    ._help-btn {
      margin: 1rem 0 0;
    }

    ._switch {
      margin-top: 5rem;
    }

    ._add-btn {
      margin-top: .4rem;
    }
  }
</style>

<template>
  <m-content
    class="invite-users"
    padding
    padding-top="0"
  >
    <div class="_item">
      <div class="_label">
        {{ $t('inviteUsers.inviteUsersLabel') }}
      </div>
      <m-textarea
        v-model:value="emails"
        :rows="5"
        :placeholder="$t('inviteUsers.inviteUsersPlaceholder')"
      />
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('inviteUsers.spaceLabel') }}
      </div>
      <space-selector
        v-model:value="selectedSpaces"
        class="_select"
        :property-label="spaceProperty.label"
        :truncate="5"
        :with-archived="false"
        :placeholder="$t('inviteUsers.spacePlaceholder')"
        placement="onTopLeft"
        match-trigger-width
        full-width
        multiple
        wrap
      />
    </div>
    <div
      class="_item"
    >
      <div class="_label">
        {{ $t('inviteUsers.recognizedEmailsLabel') }}
      </div>
      <div class="_email-list">
        <m-tag
          v-if="recognizedEmails.length === 0"
          class="_email"
          :title="$t('inviteUsers.noEmails')"
        />
        <div
          v-for="(email, index) in recognizedEmails"
          v-else
          :key="index"
          class="_email"
        >
          <m-tag :title="email" />
        </div>
      </div>
    </div>
    <div
      class="_actions"
      hide-border
    >
      <div class="_btns">
        <m-btn
          class="_btn"
          @click="$emit('close')"
        >
          {{ $t('general.cancel') }}
        </m-btn>
        <m-btn
          color="primary"
          class="_btn"
          :loading="createAndInviteUsersLoading"
          :disabled="recognizedEmails.length == 0"
          @click="sendInvites"
        >
          {{ $t('inviteUsers.sendInvite') }}
        </m-btn>
      </div>
    </div>
  </m-content>
</template>

<script>
import SpaceSelector from '@/components/spaces/SpaceSelector.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useInvitations from '@/composables/user/invitations/invitations';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useProperties from '@/composables/property/property';
import useUsers from '@/composables/user/users';
import { emailRegex } from 'shared/lib/email-regex';
import { onboardingGuideSteps } from 'shared/constants.json';

export default {
  name: 'InviteUsers',
  components: { SpaceSelector },
  data() {
    return {
      emails: '',
      selectedSpaces: [],
      createAndInviteUsersLoading: false,
    };
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const accountSettingsSvc = useAccountSettings();
    const { spaceProperty } = useProperties();
    const { markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const { createUsers } = useUsers();
    const { inviteUsers } = useInvitations();
    return {
      loggedInUser,
      markAsDone,
      createUsers,
      inviteUsers,
      spaceProperty,
    };
  },
  emits: ['close'],
  computed: {
    recognizedEmails() {
      const s = this.emails.split(/,| |;|\n/);
      const r = RegExp(emailRegex());
      return s.filter((e) => r.test(e));
    },
  },
  methods: {
    sendInvites() {
      this.createAndInviteUsersLoading = true;
      const toCreate = this.recognizedEmails.map((e) => ({
        email: e,
        language: this.loggedInUser.language,
        values: [
          {
            property: { uid: this.spaceProperty.uid },
            spaces: this.selectedSpaces.map((o) => ({ uid: o.uid })),
          },
        ],
      }));
      this.createUsers(toCreate).then((createdUsers) => {
        this.inviteUsers(createdUsers).then(() => {
          this.markAsDone([onboardingGuideSteps.inviteOthers]).then(() => {
            this.$showSnackbar({ color: 'success', message: this.$t('inviteUsers.success') });
            this.$emit('close');
          }).finally(() => {
            this.createAndInviteUsersLoading = false;
          });
        }).finally(() => {
          this.createAndInviteUsersLoading = false;
        });
      }).catch((e) => {
        if (e.message.includes('already exists')) {
          const emails = this.parseDuplicateEmailError(e.message);
          this.$showSnackbar({ color: 'error', message: this.$t('inviteUsers.oneEmailAlreadyExists'), description: `- ${emails.join(', ')}`, duration: 30 });
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }).finally(() => {
        this.createAndInviteUsersLoading = false;
      });
    },
    parseDuplicateEmailError(message) {
      const emails = [];
      const lines = message.split('\n');
      lines.forEach((line) => {
        const s1 = 'user with email ';
        const s2 = ' already exists';
        const i1 = line.indexOf(s1);
        const i2 = line.indexOf(s2);
        if (i1 > -1 && i2 > -1) {
          emails.push(line.substring(i1 + s1.length, i2));
        }
      });
      return emails;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .invite-users {
    ._item {
      margin-bottom: 1.6rem;

      ._label {
        color: $font-color-secondary;
        margin-bottom: .4rem;
      }
    }

    ._email-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 12rem;
      margin: 0 -.2rem;
      overflow-y: auto;

      ._email {
        margin: .2rem;
      }
    }

    ._actions {
      display: flex;
      margin-top: 2rem;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>

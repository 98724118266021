import { accessGroup as accessGroupConfig } from 'shared/api/query/configs.json';
import { accessPolicyType } from 'shared/constants.json';
import { dn } from '@/nebula/denormalize';

/* eslint-disable no-param-reassign */
export const form = (state) => {
  if (state.form === null || typeof state.form.orderItems === 'undefined') {
    return state.form;
  }
  return {
    ...state.form,
    orderItems: state.form.orderItems.filter((item) => typeof item.deletedAt === 'undefined'),
  };
};

export const formTemplate = (state) => {
  if (state.formTemplate === null || typeof state.formTemplate.orderItems === 'undefined') {
    return state.formTemplate;
  }
  return {
    ...state.formTemplate,
    orderItems: state.formTemplate.orderItems.filter((item) => typeof item.deletedAt === 'undefined'),
  };
};

export const accessGroupsLoggedInUser = () => Object.values(dn.value[accessGroupConfig.model]).filter((ag) => {
  // We use only access policy type "read" to determine the "member" (all users) access group
  if (ag.accessPolicy.accountAccess === accessPolicyType.read) {
    return true;
  }

  return ag.accessPolicy.scopes.some((s) => s.userIsInScope === true);
});

// TODO: Can be removed if ComponentList.vue, duplicate.js and delete-goal.js can use setup function
export const loggedInUser = (state) => {
  if (state.loggedInUserId === 0) {
    return {};
  }

  return dn.value.user[state.loggedInUserId];
};

export const loggedInUserAccount = (_, getters) => {
  if (Object.keys(getters.loggedInUser).length === 0) {
    return {};
  }

  return dn.value.account[getters.loggedInUser.account.uid];
};

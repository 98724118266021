import { accessGroupFlag, onboardingGuideSteps, routeName } from 'shared/constants.json';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useOnboardingSteps(onboardingMarker, onboardingActions, accountSettingsSvc, accessSvc) {
  const { t } = useI18n();

  const groupedSteps = computed(() => {
    const groups = [];
    if (accountSettingsSvc.accountSettings.value.usesGoals) {
      groups.push(
        {
          title: t('onboardingGuide.gettingStarted.title'),
          steps: [
            {
              done: onboardingMarker.isDone(onboardingGuideSteps.productTour),
              title: t('onboardingGuide.gettingStarted.appTour.title'),
              onclick: onboardingActions.showWelcomeVideo,
            },
            {
              done: onboardingMarker.isDone(onboardingGuideSteps.whyMooncampIsUnique),
              title: t('onboardingGuide.gettingStarted.whyMooncampIsUnique.title'),
              onclick: onboardingActions.showWhyMooncampIsUnique,
            },
            {
              done: onboardingMarker.isDone(onboardingGuideSteps.videoTutorials),
              title: t('onboardingGuide.gettingStarted.videoTutorials.title'),
              link: { href: t('rcOverview.videos.watchDemoLink'), target: '_blank' },
              onclick: () => onboardingMarker.markAsDone([onboardingGuideSteps.videoTutorials]),
            },
            {
              done: onboardingMarker.isDone(onboardingGuideSteps.okrResources),
              title: t('onboardingGuide.gettingStarted.okrResources.title'),
              onclick: onboardingActions.checkOutOKRResources,
            },
          ],
        },
      );
    }
    if (accountSetupSteps.value.length > 0) {
      groups.push(
        {
          title: t('onboardingGuide.accountSetupNew.title'),
          steps: accountSetupSteps.value,
        },
      );
    }

    return groups;
  });

  const accountSetupSteps = computed(() => {
    const steps = [];
    if (accessSvc.userHasRight([accessGroupFlag.accountWriteAccess])) {
      steps.push(
        {
          done: onboardingMarker.isDone(onboardingGuideSteps.deleteDemoData),
          title: t('onboardingGuide.accountSetupNew.removeDemoData.title'),
          link: { to: { name: routeName.accountSettings, query: { showResetModal: true } } },
        },
      );
      steps.push(
        {
          done: onboardingMarker.isDone(onboardingGuideSteps.messengers),
          title: t('onboardingGuide.accountSetupNew.messengers.title'),
          link: { to: { name: routeName.integrationSettings } },
        },
      );
    }
    if (accessSvc.userHasRight([accessGroupFlag.userWriteAccess])) {
      steps.push(
        {
          done: onboardingMarker.isDone(onboardingGuideSteps.inviteOthers),
          title: t('onboardingGuide.accountSetupNew.inviteTeamMembers.title'),
          onclick: onboardingActions.inviteUsers,
        },
      );
    }
    if (accessSvc.userHasRight([accessGroupFlag.updateAdminAccess]) && accountSettingsSvc.accountSettings.value.usesUpdates) {
      steps.push(
        {
          done: onboardingMarker.isDone(onboardingGuideSteps.setupSchedule),
          title: t('onboardingGuide.accountSetupNew.checkins.title'),
          link: { to: { name: routeName.updateSchedules } },
        },
      );
    }
    return steps;
  });

  const progress = computed(() => {
    if (onboardingMarker.isDone(onboardingGuideSteps.markAllAsCompleted)) {
      return 100;
    }

    const r = groupedSteps.value.reduce((res, group) => {
      group.steps.forEach((step) => {
        res.total += 1;
        if (step.done) {
          res.done += 1;
        }
      });
      return res;
    }, { done: 0, total: 0 });
    return Math.round((r.done / r.total) * 100);
  });

  return { groupedSteps, progress };
}

<template>
  <sign-in
    :title="title"
  >
    <login-form @session-established="$emit('session-established')" />
  </sign-in>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
import SignIn from '@/components/SignIn.vue';
import okta from '@/mixins/okta';
import { loginMethod } from '@/constants.json';
import { routeName } from 'shared/constants.json';

export default {
  name: 'Login',
  components: { LoginForm, SignIn },
  emits: ['session-established'],
  computed: {
    title() {
      switch (this.$route.name) {
        case routeName.logIn:
          return this.$t(`login.header.${loginMethod.password}`);
        case routeName.ssoLogIn:
          if (this.isOkta) {
            return `${this.$t('login.header.ssoWithProvider')} Okta`;
          }

          return this.$t(`login.header.${loginMethod.sso}`);
        default:
          return this.$t(`login.header.${loginMethod.password}`);
      }
    },
  },
  created() {
    document.title = this.$t('login.documentHeader');
  },
  mixins: [okta],
};
</script>

<style scoped lang="scss" type="text/scss">

</style>

import { UID } from 'shared/api/query/constants';
import {
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
} from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const gridPageRowChildren = [
  { attr: UID },
  { attr: gridPageRowConfig.edges.height, default: null },
  { attr: gridPageRowConfig.edges.tileOrder, default: [] },
  {
    attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
    alias: 'tiles',
    model: gridPageTileConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
  {
    attr: gridPageRowConfig.edges.gridPage,
    model: gridPageConfig.model,
    children: [{ attr: UID }],
  },
];

<template>
  <editor-menu-bubble
    v-slot="{ commands, isActive, menu, getMarkAttrs }"
    :editor="editor"
    keep-in-bounds
  >
    <m-card
      class="menububble"
      no-padding
      :class="{ '-active': active(menu.isActive) }"
      :style="`left: ${menu.left}px; top: ${menu.top - 40}px;`"
    >
      <m-btn-group style="display: flex;">
        <m-dropdown
          v-model:value="showMenu"
          placement="bottomCenter"
          :title="$t('mobileMenuComponent.turnInto')"
        >
          <m-btn
            class="_btn -dropdown"
            :small="!$store.state.breakpoint.smAndDown"
            :large="$store.state.breakpoint.smAndDown"
            hide-border
            :button-style="{ 'border-top-left-radius': '4px', 'border-bottom-left-radius': '4px', 'border-right-width': '.5px' }"
            :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
            @click="showMenu = true"
          >
            <span :style="{ 'margin-right': '.4rem' }">{{ type(isActive) }}</span>
            <m-icon
              type="down"
              :color="$colors.grey.lighten1"
              size="12"
            />
          </m-btn>
          <template #overlay>
            <m-card
              list
              no-padding
            >
              <m-card-item
                key="1"
                :active="isActive.paragraph()"
                @click="transform({ commands, type: 'paragraph', isActive })"
              >
                {{ $t('mEditorMenuBubble.text') }}
              </m-card-item>

              <template v-if="headingsAllowed">
                <m-card-item
                  key="2"
                  :active="isActive.heading({ level: 1 })"
                  @click="transform({ commands, type: 'heading', params: { level: 1 }, isActive })"
                >
                  {{ $t('mEditorMenuBubble.heading1') }}
                </m-card-item>

                <m-card-item
                  key="3"
                  :active="isActive.heading({ level: 2})"
                  @click="transform({ commands, type: 'heading', params: { level: 2 }, isActive })"
                >
                  {{ $t('mEditorMenuBubble.heading2') }}
                </m-card-item>

                <m-card-item
                  key="4"
                  :active="isActive.heading({ level: 3})"
                  @click="transform({ commands, type: 'heading', params: { level: 3 }, isActive })"
                >
                  {{ $t('mEditorMenuBubble.heading3') }}
                </m-card-item>
              </template>

              <m-card-item
                v-if="bulletedListAllowed"
                key="5"
                :active="isActive.bullet_list()"
                @click="transform({ commands, type: 'bullet_list', isActive })"
              >
                {{ $t('mEditorMenuBubble.bulletedList') }}
              </m-card-item>

              <m-card-item
                v-if="orderedListAllowed"
                key="6"
                :active="isActive.ordered_list()"
                @click="transform({ commands, type: 'ordered_list', isActive })"
              >
                {{ $t('mEditorMenuBubble.orderedList') }}
              </m-card-item>

              <m-card-item
                v-if="blockquoteAllowed"
                key="7"
                :active="isActive.blockquote()"
                @click="transform({ commands, type: 'blockquote', isActive })"
              >
                {{ $t('mEditorMenuBubble.quote') }}
              </m-card-item>

              <m-card-item
                v-if="codeBlockAllowed"
                key="8"
                :active="isActive.code_block()"
                @click="transform({ commands, type: 'code_block', isActive })"
              >
                {{ $t('mEditorMenuBubble.code') }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
        <m-btn
          class="_btn"
          :active="isActive.bold()"
          :small="!$store.state.breakpoint.smAndDown"
          :large="$store.state.breakpoint.smAndDown"
          :style="$store.state.breakpoint.smAndDown ? { borderRight: `1px solid ${$colors.grey.lighten3}` } : {}"
          hide-border

          @click="commands.bold"
        >
          <m-icon type="bold" />
        </m-btn>
        <m-btn
          class="_btn"
          :active="isActive.italic()"
          :small="!$store.state.breakpoint.smAndDown"
          :large="$store.state.breakpoint.smAndDown"
          :style="$store.state.breakpoint.smAndDown ? { borderRight: `1px solid ${$colors.grey.lighten3}` } : {}"
          hide-border
          @click="commands.italic"
        >
          <m-icon type="italic" />
        </m-btn>
        <m-btn
          class="_btn"
          :active="isActive.underline()"
          hide-border
          :small="!$store.state.breakpoint.smAndDown"
          :large="$store.state.breakpoint.smAndDown"
          :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
          @click="commands.underline"
        >
          <m-icon type="underline" />
        </m-btn>
        <m-btn
          class="_btn"
          :active="isActive.code()"
          :small="!$store.state.breakpoint.smAndDown"
          :large="$store.state.breakpoint.smAndDown"
          :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
          hide-border
          @click="commands.code"
        >
          <m-icon type="code" />
        </m-btn>
        <m-dropdown
          v-model:value="linkMenuIsActive"
          :title="$t('mEditorMenuBubble.editLinkTitle')"
          class="_btn -link"
        >
          <m-btn
            class="_btn"
            :button-style="{ 'border-top-right-radius': '4px', 'border-bottom-right-radius': '4px', 'border-left-width': '.5px' }"
            :active="isActive.link()"
            :small="!$store.state.breakpoint.smAndDown"
            :large="$store.state.breakpoint.smAndDown"
            :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
            hide-border
            @click="showLinkMenu(getMarkAttrs('link'))"
          >
            <m-icon type="link" />
          </m-btn>
          <template #overlay>
            <m-card
              padding-xs
              class="_link-form"
            >
              <div class="_form-content">
                <m-text-field
                  ref="linkInput"
                  v-model:value="linkUrl"
                  auto-focus
                  class="_input"
                  placeholder="https://"
                  small
                  @keydown.esc="hideLinkMenu"
                />
                <m-btn
                  class="_form-btn"
                  small
                  :disabled="!linkUrlSafe"
                  @click="setLinkUrl(commands.link, { href: linkUrl })"
                >
                  Link
                </m-btn>
                <m-btn
                  class="_form-btn"
                  small
                  @click="setLinkUrl(commands.link, {})"
                >
                  Unlink
                </m-btn>
              </div>
            </m-card>
          </template>
        </m-dropdown>
      </m-btn-group>
    </m-card>
  </editor-menu-bubble>
</template>

<script>
import EditorMenuBubble from '@/tiptap/tiptap/Components/EditorMenuBubble';
import { editorNodeType } from 'shared/constants.json';

export default {
  name: 'MEditorMenuBubble',
  props: {
    editor: {
      type: Object,
      required: true,
    },
    allowedContent: {
      type: Array,
      required: true,
    },
    hideFor: {
      type: Array,
      default: () => [
        editorNodeType.image,
        editorNodeType.file,
      ],
    },
  },
  components: { EditorMenuBubble },
  data() {
    return {
      linkUrl: null,
      linkMenuIsActive: false,
      showMenu: false,
    };
  },
  computed: {
    linkUrlSafe() {
      try {
        /* eslint-disable no-script-url */
        const u = new URL(this.linkUrl);
        return !['javascript:'].includes(u.protocol);
        /* eslint-enable no-script-url */
      } catch {
        return true;
      }
    },
    bulletedListAllowed() {
      return this.allowedContent.indexOf(editorNodeType.bulletList) > -1;
    },
    headingsAllowed() {
      return this.allowedContent.indexOf(editorNodeType.heading) > -1;
    },
    orderedListAllowed() {
      return this.allowedContent.indexOf(editorNodeType.bulletList) > -1;
    },
    blockquoteAllowed() {
      return this.allowedContent.indexOf(editorNodeType.blockquote) > -1;
    },
    codeBlockAllowed() {
      return this.allowedContent.indexOf(editorNodeType.codeBlock) > -1;
    },
  },
  methods: {
    active(menuActive) {
      if (!menuActive) {
        return false;
      }

      if (this.editor.view.lastSelectedViewDesc === null || this.editor.view.lastSelectedViewDesc === undefined) {
        return true;
      }

      return !this.hideFor.includes(this.editor.view.lastSelectedViewDesc.node.type.name);
    },
    transform({ commands, type, params, isActive }) {
      if (this.codeBlockAllowed && type !== editorNodeType.codeBlock && isActive.code_block()) {
        commands.code_block();
      }
      if (this.blockquoteAllowed && type !== editorNodeType.blockquote && isActive.blockquote()) {
        commands.blockquote();
      }
      if (this.bulletedListAllowed && type !== editorNodeType.bulletList && isActive.bullet_list()) {
        commands.bullet_list();
      }
      if (this.orderedListAllowed && type !== editorNodeType.orderedList && isActive.ordered_list()) {
        commands.ordered_list();
      }

      if (this.headingsAllowed) {
        for (let i = 1; i < 4; i++) {
          if (type !== 'heading' && isActive.heading({ level: i })) {
            commands.heading({ level: i });
          }
        }
      }

      commands[type](params);
      this.showMenu = false;
    },
    type(isActive) {
      switch (true) {
        case typeof isActive.heading !== 'undefined' && isActive.heading({ level: 1 }):
          return this.$t('mEditorMenuBubble.heading1');
        case typeof isActive.heading !== 'undefined' && isActive.heading({ level: 2 }):
          return this.$t('mEditorMenuBubble.heading2');
        case typeof isActive.heading !== 'undefined' && isActive.heading({ level: 3 }):
          return this.$t('mEditorMenuBubble.heading3');
        case typeof isActive.bullet_list !== 'undefined' && isActive.bullet_list():
          return this.$t('mEditorMenuBubble.bulletedList');
        case typeof isActive.ordered_list !== 'undefined' && isActive.ordered_list():
          return this.$t('mEditorMenuBubble.orderedList');
        case typeof isActive.blockquote !== 'undefined' && isActive.blockquote():
          return this.$t('mEditorMenuBubble.quote');
        case typeof isActive.code_block !== 'undefined' && isActive.code_block():
          return this.$t('mEditorMenuBubble.code');
        case isActive.paragraph():
        default:
          return this.$t('mEditorMenuBubble.text');
      }
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command(url);
      this.hideLinkMenu();
    },
    validateUrl(url) {
      try {
        const u = new URL(url);
        return ['https:', 'http:'].includes(u.protocol);
      } catch {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .menububble {
    position: fixed;
    z-index: 20;
    display: flex;
    margin-bottom: .5rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
    transform: translateX(-50%);

    &.-active {
      visibility: visible;
      opacity: 1;
    }
  }

  ._link-form {
    ._form-content {
      display: flex;

      ._input {
        display: inline;
        margin-top: 0;
      }

      ._form-btn {
        margin-left: .4rem;
      }
    }
  }
</style>

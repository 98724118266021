<template>
  <div
    :class="classes"
    :data-id="dataId"
  >
    <div class="_m-card-body">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'MCard',
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
    paddingSmall: {
      type: Boolean,
      default: false,
    },
    paddingXs: {
      type: Boolean,
      default: false,
    },
    paddingXxs: {
      type: Boolean,
      default: false,
    },
    paddingXxxs: {
      type: Boolean,
      default: false,
    },
    paddingLarge: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 2,
    },
    borderRadiusSmall: {
      type: Boolean,
      default: false,
    },
    sharpCorners: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    classes() {
      return [
        'm-card',
        this.flat ? '-flat' : '',
        this.borderRadiusSmall ? '-border-radius-small' : '',
        this.sharpCorners ? '-sharp-corners' : '',
        this.list ? '-list' : '',
        this.hasHeader ? '-has-header' : '',
        this.noPadding ? '-no-padding' : '',
        this.paddingLarge ? '-padding-large' : '',
        this.smallPadding ? '-small-padding' : '',
        this.paddingSmall ? '-padding-small' : '',
        this.paddingXs ? '-padding-xs' : '',
        this.paddingXxs ? '-padding-xxs' : '',
        this.paddingXxxs ? '-padding-xxxs' : '',
        this.bordered ? '-bordered' : '',
        `-level-${this.level}`,
      ];
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";
  @import "shared/assets/scss/padding";

  .m-card {
    position: relative;
    display: flex;
    padding: 2.4rem;
    background-color: white;
    border: none;
    border-radius: $default-border-radius;

    ._m-card-body {
      width: 100%;
    }

    &.-sharp-corners {
      border-radius: 0;
    }

    &.-level-1 {
      @include box_shadow(1);
    }

    &.-level-2 {
      @include box_shadow(2);
    }

    &.-padding-small {
      padding: $small-container-padding-y;
    }

    &.-padding-xs {
      padding: $xs-container-padding-y;
    }

    &.-padding-xxs {
      padding: $xxs-container-padding-y;
    }

    &.-padding-xxxs {
      padding: $xxxs-container-padding-y;
    }

    &.-small-padding {
      padding: $base-container-padding-y;
    }

    &.-padding-large {
      padding: 3rem;
    }

    &.-border-radius-small {
      border-radius: $border-radius-sm;
    }

    &.-no-padding {
      padding: 0;
    }

    &.-bordered {
      border: 1px solid $border-color-dark;
    }

    &.-flat {
      box-shadow: none;
    }

    &.-list {
      padding: .4rem 0;
    }

    &.-has-header {
      padding-top: 0;
    }
  }
</style>

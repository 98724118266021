import { UID } from 'shared/api/query/constants';
import {
  gridPageTile as gridPageTileConfig,
  gridPageTileText as gridPageTileTextConfig,
} from 'shared/api/query/configs.json';

export const gridPageTileTextChildren = [
  { attr: UID },
  { attr: gridPageTileTextConfig.edges.content },
  { attr: gridPageTileTextConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
];

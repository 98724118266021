<template>
  <div
    class="sign-in"
    :style="componentStyle"
  >
    <div
      v-if="showNav"
      class="_nav"
    >
      <a href="https://mooncamp.com">
        <img
          class="_logo"
          src="@/assets/img/logo.svg"
        >
      </a>
    </div>
    <div class="_content">
      <div
        v-if="title !== ''"
        class="_title"
      >
        <h1>{{ title }}</h1>
      </div>
      <slot name="content" />
      <div class="_inner">
        <slot />
      </div>
    </div>
    <div
      v-if="showFooter"
      class="_footer"
    >
      <m-link
        :href="imprint"
        class="_link"
        target="_blank"
        inherit-color
      >
        {{ $t('signIn.imprint') }}
      </m-link>
      <m-link
        :href="terms"
        class="_link"
        target="_blank"
        inherit-color
      >
        {{ $t('signIn.terms') }}
      </m-link>
      <m-link
        :href="privacy"
        class="_link"
        target="_blank"
        inherit-color
      >
        {{ $t('signIn.privacy') }}
      </m-link>
    </div>
    <template v-if="showImages && !$store.state.breakpoint.smAndDown">
      <img
        class="_img-left"
        src="@/assets/img/illustrations/moon-3d.webp"
      >
      <img
        class="_img-right"
        src="@/assets/img/illustrations/moon-3d.webp"
      >
    </template>
  </div>
</template>

<script>
import { IMPRINT_URL, PRIVACY_URL, TERMS_URL } from 'shared/transformers';
import { browserLanguage } from '@/lib/language';
import { changeLocale } from '@/lang';
import { getQueryParam } from '@/lib/route';

export default {
  name: 'SignIn',
  props: {
    title: {
      type: String,
      default: '',
    },
    showNav: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    componentStyle: {
      type: Object,
      default: null,
    },
    showImages: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      privacy: PRIVACY_URL,
      terms: TERMS_URL,
      imprint: IMPRINT_URL,
    };
  },
  computed: {
    lang() {
      const lang = getQueryParam(this.$route, 'lang');
      if (lang !== '') {
        return lang;
      }
      return browserLanguage();
    },
  },
  watch: {
    lang() {
      if (this.lang !== '') {
        changeLocale(this.lang, this);
      }
    },
  },
  created() {
    if (this.lang !== '') {
      changeLocale(this.lang, this);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .sign-in {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background-color: $side-nav-background-color;

    ._nav {
      padding: 2rem 2.4rem;
      max-width: 160rem;
      margin-left: auto;
      margin-right: auto;

      ._logo {
        height: 2.4rem;
      }
    }

    ._img-left {
      position: absolute;
      top: 22.4rem;
      left: -15rem;
      height: 29.4rem;
    }

    ._img-right {
      position: absolute;
      right: -12rem;
      top: 44.8rem;
      height: 24.4rem;
      transform: rotate(45deg)
    }

    ._content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: calc(100vh - 11rem);
      padding-bottom: 10rem;

      ._title {
        padding-bottom: 2rem;
        text-align: center;
      }

      ._inner {
        width: 95%;
        max-width: 30rem;
      }

    }

    ._footer {
      position: relative;
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;
      font-size: $font-size-3;
      color: $font-color-secondary;

      ._link {
        margin: 0 .8rem;
        color: $font-color-secondary;
        text-decoration: underline;
      }
    }
  }
</style>

<template>
  <m-dropdown
    ref="dropdown"
    v-model:value="open"
    class="space-selector"
    :disabled="disabled || readOnly"
    :title="$t('spaceSelector.selectSpace', { title: spaceTitle })"
    :block="fullWidth"
    :placement="placement"
    :match-trigger-width="matchTriggerWidth"
    tabindex="0"
    @focus="open = true"
  >
    <m-focusable
      class="_input"
      :small="small"
      :hide-border="hideBorder"
      :read-only="readOnly"
      :disabled="disabled"
      :placeholder="placeholder"
      :full-width="fullWidth"
      :placeholder-icon="placeholderIcon"
      :show-placeholder="!hidePlaceholder && value.length === 0"
      type="clickable"
      :m-style="mStyle"
      has-tags
      :hide-hover="hideHover"
      @click="open = true"
    >
      <m-tag-list
        :small="small"
        :wrap="wrap"
      >
        <m-tag
          v-for="v in truncatedSelectedSpaces"
          :key="v.uid"
          :m-style="mStyle"
          :color="v.color"
          automatic-color-fallback
          :title="getTitle(v.title)"
          :icon="buildIconFromEntity(v)"
          :small="!small && !xs"
          :xs="small"
          :xxs="xs"
        />
        <m-tag
          v-if="mustTruncate && value.length > truncate"
          :m-style="mStyle"
          class="_item"
          :color="COLOR_NONE"
          :title="$t('spaceSelector.further', { amount: value.length - truncate })"
          :small="!small && !xs"
          :xs="small"
          :xxs="xs"
        />
      </m-tag-list>
      <template
        v-if="!hideArrow && !disabled && !readOnly"
        #suffix
      >
        <m-icon
          size="11"
          :color="$colors.grey.lighten1"
          type="down"
        />
      </template>
    </m-focusable>
    <template #overlay>
      <m-card
        ref="overlay"
        no-padding
        class="_overlay"
        :style="{ paddingBottom: '.4rem' }"
      >
        <m-focusable
          v-if="placement.includes('onTop')"
          hide-hover
          has-tags
          hide-border
          full-width
          :small="small"
          class="_input-overlay"
        >
          <m-tag-list
            wrap
            :small="small"
          >
            <m-tag
              v-for="v in selectedSpaces"
              :key="v.uid"
              :title="v.title"
              :icon="buildIconFromEntity(v)"
              :color="v.color"
              automatic-color-fallback
              small
              closeable
              @close="select(v)"
            />
            <div class="_search">
              <m-text-field
                ref="search"
                v-model:value="searchTerm"
                :placeholder="selectedSpaces.length === 0 ? $t('spaceSelector.selectPlaceholder', { title: userLang === 'de' ? spaceTitle : spaceTitle.toLowerCase() }) : null"
                inherit-styling
                auto-focus
                full-width
                @blur="focusDropdown"
              />
            </div>
          </m-tag-list>
        </m-focusable>
        <div
          v-else
          class="_top"
        >
          <m-content
            :padding-x="9"
            :padding-y="9"
          >
            <m-text-field
              ref="search"
              v-model:value="searchTerm"
              auto-focus
              :placeholder="$t('spaceSelector.searchPlaceholder', { title: userLang === 'de' ? spaceTitle : spaceTitle.toLowerCase() })"
              full-width
              has-background
              @blur="focusDropdown"
            />
          </m-content>
        </div>
        <m-card-item
          v-if="allItems.length === 0"
          light
          class="_empty"
        >
          {{ $t('spaceSelector.empty', { title: spaceTitle }) }}
        </m-card-item>
        <div
          ref="list"
          class="_item-list"
        >
          <m-card-group
            v-if="mySpacesItems.length > 0"
            class="_section -my-spaces"
            :title="$t('spaceSelector.mySpaces', { title: spaceTitle })"
            :expanded="true"
          >
            <space-selector-item
              v-for="item in mySpacesItems"
              :key="item.uid"
              class="_item"
              :space="item"
              :indentation="0"
              :selected="value"
              :focus-index="focusIndex"
              :multiple="multiple"
              @select="select"
              @select-all="selectAll"
              @deselect-all="deselectAll"
              @set-focus="setFocus"
              @toggle-expand="toggleExpand"
            />
          </m-card-group>
          <m-card-group
            v-if="!restrictForeignEntityReferences && activeSpacesItems.length > 0"
            class="_section -active-spaces"
            :title="$t('spaceSelector.activeSpaces', { title: spaceTitle })"
            :expanded="true"
          >
            <m-transition-expand
              v-for="item in activeSpacesItems"
              :key="item.uid"
            >
              <space-selector-item
                v-show="item.show"
                class="_item"
                :space="item"
                :selected="value"
                :indentation="item.indentation"
                :focus-index="focusIndex"
                :multiple="multiple"
                @select="select"
                @select-all="selectAll"
                @deselect-all="deselectAll"
                @set-focus="setFocus"
                @toggle-expand="toggleExpand"
              />
            </m-transition-expand>
          </m-card-group>
          <m-card-group
            v-if="withArchived && archivedSpacesItems.length > 0"
            class="_section -archived-spaces"
            :title="$t('spaceSelector.archivedSpaces', { title: spaceTitle })"
            :expanded="groupHasItem(archivedSpaces, value)"
          >
            <space-selector-item
              v-for="item in archivedSpacesItems"
              :key="item.uid"
              class="_item"
              :space="item"
              :indentation="0"
              :selected="value"
              :focus-index="focusIndex"
              @select="select"
              @select-all="selectAll"
              @deselect-all="deselectAll"
              @set-focus="setFocus"
              @toggle-expand="toggleExpand"
            />
          </m-card-group>
        </div>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import SpaceSelectorItem from '@/components/spaces/SpaceSelectorItem.vue';
import i18n from '@/lang';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSpaces from '@/composables/space/spaces';
import { COLOR_NONE } from 'shared/constants';
import { accessGroupFlag } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { copy } from 'shared/lib/copy';
import { findInArray, intersection } from 'shared/lib/array/array';
import { getColor } from 'shared/lib/color-map';
import { getSpaceTitle } from '@/lib/space';
import { mStyleProps } from 'shared/lib/m-style-props';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'SpaceSelector',
  props: {
    ...mStyleProps,
    value: {
      type: Array,
      required: true,
    },
    restrictedSpaces: {
      type: Array,
      default: undefined,
    },
    propertyLabel: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: () => i18n.t('general.empty'),
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    truncate: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    restrictForeignEntitySelection: {
      type: Boolean,
      default: false,
    },
    withArchived: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input', 'update:value', 'close'],
  components: { SpaceSelectorItem },
  setup(props) {
    const { userHasRight } = useAccess();
    const { myTeams: _myTeams, userLang } = useLoggedInUser();
    const searchTerm = ref('');
    const { spaces: _spaces, archivedSpaces: _archivedSpaces } = useSpaces(searchTerm);

    let myTeams = _myTeams;
    let activeSpaces = _spaces;
    let archivedSpaces = _archivedSpaces;
    if (props.restrictedSpaces !== undefined) {
      const rsIds = props.restrictedSpaces.map((rs) => rs.uid);
      activeSpaces = computed(() => _spaces.value.filter((s) => rsIds.includes(s.uid)));
      archivedSpaces = computed(() => _archivedSpaces.value.filter((s) => rsIds.includes(s.uid)));
      myTeams = computed(() => {
        const ss = _myTeams.value.filter((s) => rsIds.includes(s.uid));
        if (props.withArchived === true) {
          return ss;
        }
        return ss.filter((s) => !archivedSpaces.value.map(({ uid }) => uid).includes(s.uid));
      });
    }
    const allSpaces = computed(() => {
      if (props.withArchived === true) {
        return [...activeSpaces.value, ...archivedSpaces.value];
      }
      return [...activeSpaces.value];
    });
    return {
      userHasRight,
      userLang,
      searchTerm,
      myTeams,
      activeSpaces,
      archivedSpaces,
      allSpaces,
    };
  },
  data() {
    return {
      COLOR_NONE,
      textByLang,
      getColor,
      getSpaceTitle,
      open: false,
      focusIndex: 0,
      collapsedItems: [],
    };
  },
  computed: {
    mustTruncate() {
      return this.truncate >= 0;
    },
    restrictForeignEntityReferences() {
      if (!this.restrictForeignEntitySelection) {
        return false;
      }

      return !this.userHasRight([accessGroupFlag.foreignEntityReference]);
    },
    spaceTitle() {
      return getSpaceTitle(textByLang(this.propertyLabel, this.userLang));
    },
    selectedSpaces() {
      return this.value.map((v) => findInArray({ haystack: this.allSpaces, needle: v.uid }));
    },
    truncatedSelectedSpaces() {
      if (this.mustTruncate) {
        return this.selectedSpaces.slice(0, this.truncate);
      }
      return this.selectedSpaces;
    },
    mySpacesItems() {
      return this.myTeams.map((next) => {
        const match = this.searchTerm === '' || next.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;

        return { ...next, children: [], match, expanded: !this.collapsedItems.includes(next.uid) };
      })
        .filter((i) => i.match)
        .map((i, index) => ({ ...i, index }));
    },
    lastMySpacesIndex() {
      if (this.mySpacesItems.length === 0) {
        return 0;
      }
      return this.mySpacesItems[this.mySpacesItems.length - 1].index;
    },
    activeSpacesItems() {
      const reducer = (res, next) => {
        res.push({ ...next, hasChildren: next.children.length > 0 });
        if (next.children.length === 0) {
          return res;
        }

        res.push(...next.children.reduce(reducer, []));
        return res;
      };

      return this.activeSpaces.filter((o) => o.parents.length === 0)
        .reduce(this.reduceChildren(true), [])
        .reduce(this.reduceMatches, [])
        .map(this.setIndex(this.lastMySpacesIndex))
        .map(this.setIndentation(0))
        .reduce(reducer, []);
    },
    lastActiveSpacesIndex() {
      if (this.activeSpacesItems.length === 0) {
        return this.lastMySpacesIndex;
      }
      const f = this.activeSpacesItems.filter((s) => s.index !== undefined);
      return f[f.length - 1].index;
    },
    archivedSpacesItems() {
      return this.archivedSpaces.map((next) => {
        const match = this.searchTerm === '' || next.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;

        return { ...next, children: [], match, expanded: !this.collapsedItems.includes(next.uid) };
      })
        .filter((i) => i.match)
        .map((i, index) => ({ ...i, index: (this.lastActiveSpacesIndex + 1) + index }));
    },
    lastArchivedSpacesIndex() {
      if (this.archivedSpacesItems.length === 0) {
        return this.lastActiveSpacesIndex;
      }
      return this.archivedSpacesItems[this.archivedSpacesItems.length - 1].index;
    },
    allItems() {
      return [
        ...this.mySpacesItems,
        ...this.activeSpacesItems,
        ...this.archivedSpacesItems,
      ];
    },
    maxIndex() {
      return this.lastArchivedSpacesIndex;
    },
  },
  methods: {
    buildIconFromEntity,
    show() {
      this.$refs.dropdown.open();
    },
    getTitle(value) {
      if (this.maxTagTextLength !== 0 && value.length > this.maxTagTextLength) {
        return `${value.slice(0, this.maxTagTextLength)}...`;
      }

      return value;
    },
    setFocus(val) {
      if (typeof val === 'undefined') {
        return;
      }

      this.focusIndex = val;
    },
    setScrollPosition(val, oldVal) {
      const el = document.getElementById(`selector-item-${val}`);
      if (el === null) {
        return;
      }

      const headingsSize = () => {
        const h = 31;
        let res = 0;
        if (this.mySpacesItems.length > 0 && val > 0) {
          res += h;
        }
        if (this.activeSpacesItems.length > 0 && val > this.lastMySpacesIndex) {
          res += h;
        }
        if (this.archivedSpacesItems.length > 0 && val > this.lastActiveSpacesIndex) {
          res += h;
        }
        return res;
      };

      if (oldVal < val && el.clientHeight + el.offsetTop + 5 > this.$refs.list.clientHeight) {
        this.$refs.list.scrollTop = el.clientHeight + this.$refs.list.scrollTop;
        return;
      }
      if (el.offsetTop - el.clientHeight - headingsSize() < this.$refs.list.scrollTop) {
        this.$refs.list.scrollTop = this.$refs.list.scrollTop - el.clientHeight - headingsSize();
      }
    },
    toggleExpand({ expanded, uid }) {
      if (expanded) {
        this.collapsedItems = this.collapsedItems.filter((i) => i !== uid);
        return;
      }
      this.collapsedItems.push(uid);
    },
    moveFocusDown() {
      if (this.focusIndex === this.maxIndex) {
        return;
      }

      const oldVal = this.focusIndex;
      this.focusIndex += 1;
      this.setScrollPosition(this.focusIndex, oldVal);
    },
    moveFocusUp() {
      if (this.focusIndex === 0) {
        return;
      }

      const oldVal = this.focusIndex;
      this.focusIndex -= 1;
      this.setScrollPosition(this.focusIndex, oldVal);
    },
    findFocusedItem(index) {
      const focusedItem = this.allItems.find((item) => item.index === index);
      if (focusedItem === undefined) {
        return null;
      }
      return focusedItem;
    },
    handleKeyDown(event) {
      if (event.key === 'Backspace') {
        if (this.value.length === 0 || this.searchTerm !== '') {
          return;
        }
        this.select(this.value[this.value.length - 1]);
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      if (event.key === 'Enter') {
        if (this.allItems.length === 0) {
          return;
        }
        this.select(this.findFocusedItem(this.focusIndex));
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (event.code === 'ArrowDown' || event.which === 40) {
        event.preventDefault();
        event.stopPropagation();
        this.moveFocusDown();
        return;
      }

      if (event.code === 'ArrowUp' || event.which === 38) {
        event.preventDefault();
        event.stopPropagation();
        this.moveFocusUp();
      }

      if (event.code === 'ArrowRight' || event.which === 39) {
        event.preventDefault();
        event.stopPropagation();
        const item = this.findFocusedItem(this.focusIndex);
        this.toggleExpand({ expanded: true, uid: item.uid });
        return;
      }

      if (event.code === 'ArrowLeft' || event.which === 37) {
        event.preventDefault();
        event.stopPropagation();
        const item = this.findFocusedItem(this.focusIndex);
        this.toggleExpand({ expanded: false, uid: item.uid });
      }
    },
    setIndentation(indentation) {
      return (item) => ({
        ...item,
        indentation,
        children: item.children.map(this.setIndentation(indentation + 1)),
      });
    },
    setIndex(start) {
      let currentIndex = start;

      const mapFunc = (item) => {
        currentIndex += 1;

        if (!item.expanded) {
          return {
            ...item,
            index: currentIndex,
          };
        }

        return {
          ...item,
          index: currentIndex,
          children: item.children.map(mapFunc),
        };
      };

      return mapFunc;
    },
    focusDropdown() {
      if (typeof this.$refs.dropdown === 'undefined') {
        return;
      }

      this.$refs.dropdown.focus();
    },
    reduceChildren(parentExpanded) {
      return (res, c) => {
        const item = findInArray({ haystack: this.activeSpaces, needle: c.uid });
        if (item === null) {
          return res;
        }

        const match = this.searchTerm === '' || item.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
        const children = this.activeSpaces.filter((s) => s.parents.filter((p) => p.uid === item.uid).length > 0);

        const expanded = !this.collapsedItems.includes(item.uid);
        if (children.length === 0) {
          res.push({
            ...item,
            match,
            hasChildrenMatches: false,
            expanded,
            show: parentExpanded,
            children: [],
          });
          return res;
        }

        res.push({
          ...item,
          match,
          children: children.reduce(this.reduceChildren(expanded && parentExpanded), []),
          show: parentExpanded,
          expanded,
        });
        return res;
      };
    },
    hasMatch(res, next) {
      if (next.match) {
        return true;
      }

      if (next.children.length === 0) {
        return res;
      }

      return next.children.reduce(this.hasMatch, res);
    },
    reduceMatches(res, next) {
      if (next.children.length === 0 && !next.match) {
        return res;
      }

      const childrenMatch = next.children.reduce(this.hasMatch, false);
      if (next.match || childrenMatch) {
        res.push({
          ...next,
          children: next.children.reduce(this.reduceMatches, []),
        });
      }

      return res;
    },
    select(space) {
      if (!this.closeOnSelect) {
        this.searchTerm = '';
        this.$refs.search.focus();
      }
      const ids = this.value.map((i) => i.uid);
      if (ids.includes(space.uid)) {
        this.emitInput(this.value.filter((i) => i.uid !== space.uid));
        return;
      }

      this.emitInput([...this.value, space]);
    },
    emitInput(value) {
      switch (true) {
        case value.length === 0:
          this.$emit('input', []);
          this.$emit('update:value', []);
          break;
        case !this.multiple:
          this.$emit('input', value.slice(-1));
          this.$emit('update:value', value.slice(-1));
          break;
        default:
          this.$emit('input', value);
          this.$emit('update:value', value);
      }

      if (!this.closeOnSelect) {
        return;
      }

      this.open = false;
    },
    groupHasItem(group, item) {
      return intersection(item.map((c) => c.uid), group.map((c) => c.uid)).length > 0;
    },
    deselectAll(space) {
      const reducer = (res, next) => {
        res.push(next.uid);
        res.push(...next.children.reduce(reducer, []));
        return res;
      };

      const toRemove = space.children.reduce(reducer, [space.uid]);
      this.emitInput(this.value.filter((v) => !toRemove.includes(v.uid)));
      this.searchTerm = '';
      this.$refs.search.focus();
    },
    selectAll(space) {
      const findItem = (item) => findInArray({ haystack: this.activeSpaces, needle: item.uid });

      const reducer = (res, next) => {
        const n = findItem(next);
        res.push(...n.children);
        return n.children.reduce(reducer, res);
      };

      const all = space.children.reduce((res, next) => {
        const n = findItem(next);
        res.push(...n.children);
        return n.children.reduce(reducer, res);
      }, [space, ...space.children]);

      const newVal = all.reduce((res, next) => {
        if (res.filter((v) => v.uid === next.uid).length > 0) {
          return res;
        }

        res.push(next);
        return res;
      }, copy(this.value));

      this.emitInput(newVal);
      this.searchTerm = '';
      this.$refs.search.focus();
    },
  },
  watch: {
    open(value, oldValue) {
      if (this.disabled || this.readOnly) {
        return;
      }

      if (!value && oldValue) {
        this.$emit('close');
      }

      if (value) {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$refs.overlay.$el.addEventListener('keydown', this.handleKeyDown);
            });
          });
        });
        return;
      }

      this.$refs.overlay.$el.removeEventListener('keydown', this.handleKeyDown);
    },
    search() {
      this.focusIndex = 0;
    },
  },
  created() {
    if (this.autoFocus) {
      this.open = true;
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-selector {
    ._input {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      cursor: pointer;

      ._item {
        &.-further {
          flex: 0 0 auto;
          height: 2rem;
        }
      }
    }
  }

  ._section {
    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    ._heading {
      padding: .7rem 1.6rem;
      font-size: $font-size-1;
      font-weight: $font-weight-semibold;
      color: $font-color-secondary;
      text-transform: uppercase;
    }
  }

  ._overlay {
    ._input-overlay {
      background-color: map_get($grey, 'lighten-5');
      border-bottom: 1px solid $border-color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      ._search {
        flex: 1 1 10rem;
        margin-left: .4rem;
      }
    }

    ._item-list {
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $screen-size-md) {
        max-height: 100vh;
        overflow: auto;
      }
    }

    ._empty {
      margin-top: .4rem;
      color: $font-color-secondary;
    }
  }
</style>

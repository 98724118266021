<template>
  <div class="add-row-btn">
    <m-dropdown
      v-model:value="btnActivated"
      placement="bottomLeft"
      block
      :title="$t('general.select')"
    >
      <slot>
        <row-button
          class="_row-btn"
          icon="plus"
          :label="$t('gridPage.addRowBtn.addRow')"
          super-light
          hide-border
          block
        />
      </slot>
      <template #overlay>
        <m-card
          no-padding
          list
        >
          <m-card-item
            v-for="action in createActions"
            :key="action.gridPageType"
            :icon="action.icon"
            :loading="loading === action.gridPageType"
            @click="add(action)"
          >
            {{ action.label }}
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script type="text/javascript" setup>
import RowButton from '@/components/table/RowButton.vue';
import useGridPage from '@/composables/grid-page/grid-page';
import useInlineEditing from '@/composables/inline-editing';
import useSnackBar from '@/composables/snackbar';
import { computed, ref } from 'vue';
import { gridPageType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

const props = defineProps({
  gridPage: {
    type: Object,
    required: true,
  },
});

const inlineEditingSvc = useInlineEditing();
const snackBar = useSnackBar();

const btnActivated = ref(false);

const { createActions, selectSingleGridPage, addGridPageTile } = useGridPage();

const gridPage = computed(() => selectSingleGridPage(props.gridPage.uid));

const loading = ref('');
const add = (action) => {
  loading.value = action.gridPageType;
  addGridPageTile({ type: action.gridPageType, gridPageRow: { gridPage: gridPage.value } }).then((res) => {
    btnActivated.value = false;
    if (action.gridPageType !== gridPageType.singleGoal) {
      inlineEditingSvc.set(res.uid);
    }
  }).catch(logCatch(() => { snackBar.error(); }))
    .finally(() => {
      loading.value = '';
    });
};
</script>

<style scoped
  lang="scss"
  type="text/scss"
>
.add-row-btn {
  ._row-btn {
    width: calc(100% - 2rem);
  }
}
</style>

<template>
  <div class="account-infos">
    <div class="_company-image">
      <div class="_avatar">
        <img
          v-if="companyImage !== ''"
          :src="companyImage"
          :alt="`${companyName} Logo`"
        >
        <div
          v-else
          class="_img-placeholder"
        >
          <div class="_text">
            {{ companyName === '' ? 'M' : companyName.charAt(0) }}
          </div>
        </div>
        <input
          ref="fileinput"
          type="file"
          :style="{display: 'none'}"
          accept="image/*"
          @change="fileChanged"
        >
        <m-dialog
          v-model:value="showCropImage"
          :title="$t('accountSettings.setCompanyPicture')"
          hide-footer
          keep-open-on-mask-click
          no-padding
        >
          <image-crop
            v-if="file !== null"
            class="_image-crop"
            :file="file"
            @cropped="uploadImage"
            @cancel="cancelImageCrop"
            @wrong-file-type="handleWrongFileType"
          />
        </m-dialog>
      </div>
      <div class="_upload-btn">
        <m-btn
          hide-border
          small
          light
          @click="triggerInput"
        >
          {{ $t('accountInfos.uploadImage') }}
        </m-btn>
      </div>
    </div>
    <div class="_form">
      <div class="_item">
        <div class="_label">
          {{ $t('accountInfos.companyNameLabel') }}
        </div>
        <m-text-field
          v-model:value="companyName"
          auto-focus
          @keydown.enter="update"
        />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('accountInfos.companySize') }}
        </div>
        <m-select
          v-model:value="companySize"
          :items="items"
          full-width
          match-trigger-width
          :style="{ background: 'white' }"
        />
      </div>
      <m-btn
        color="black"
        :disabled="disabled"
        block
        :loading="loading"
        @click="update"
      >
        <template v-if="!isLastStep">
          {{ $t('onboarding.continue') }}
        </template>
        <template v-else>
          {{ $t('onboarding.launchMooncamp') }}
        </template>
      </m-btn>
    </div>
    <overlay-spinner
      v-if="loading && showFullScreenSpinner"
      :text="$t('moduleSelectionInfos.creatingYourAccount')"
    />
  </div>
</template>

<script>
import ImageCrop from '@/components/ImageCrop.vue';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { DateTime } from 'luxon';
import { copy } from 'shared/lib/copy';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'AccountInfos',
  props: {
    showFullScreenSpinner: {
      type: Boolean,
      default: false,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const accountSvc = useLoggedInUserAccount();
    return { loggedInUser, personalAppSettings, loggedInUserAccount: accountSvc.loggedInUserAccount, accountSvc };
  },
  emits: ['done'],
  components: { OverlaySpinner, ImageCrop },
  data() {
    return {
      companyName: '',
      signUpPathName: '',
      file: null,
      loading: false,
      showCropImage: false,
      companySize: null,
      items: [
        {
          text: '1-50',
          value: '1-20',
        },
        {
          text: '51-100',
          value: '21-100',
        },
        {
          text: '101-500',
          value: '101-500',
        },
        {
          text: '501-1000',
          value: '501-1000',
        },
        {
          text: '1001-5000',
          value: '1001-5000',
        },
        {
          text: '5000+',
          value: '5000+',
        },
      ],
    };
  },
  computed: {
    disabled() {
      return this.companyName === '' || this.companySize === null;
    },
    companyImage() {
      if (this.loggedInUserAccount.companyImage === null) {
        return '';
      }

      return this.loggedInUserAccount.companyImage.getURL;
    },
  },
  methods: {
    pathFormatter(value) {
      let v = value;
      v = v.toLowerCase();
      v = v.replace('ö', 'oe');
      v = v.replace('ä', 'ae');
      v = v.replace('ü', 'ue');
      v = v.replace('ß', 'ss');
      v = v.replace(/\s/g, '-');
      v = v.replace(/[^a-zA-Z0-9_-]/g, '');
      return v;
    },
    update() {
      if (this.disabled) {
        return;
      }

      const now = DateTime.local().toISO();
      this.loading = true;
      const update = copy(this.loggedInUserAccount);
      update.companyName = this.companyName;
      update.signUpPathName = this.pathFormatter(this.companyName);
      update.accountSettings = {
        ...update.accountSettings,
        accountInfosCompleted: now,
        moduleSelectionCompleted: now,
        usesSurveys: false,
        usesUpdates: true,
        usesGoals: true,
      };
      update.numberOfEmployees = this.companySize;

      if (update.appIntegration !== null && typeof update.appIntegration !== 'undefined' && update.appIntegration.slackBotAccessToken !== null) {
        delete update.appIntegration.slackBotAccessToken;
      }

      this.accountSvc.updateSingle(
        update,
        { optimistic: false },
        { forceToSetSignUpPathName: true },
      ).then(() => {
        this.$emit('done');
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.loading = false;
      });
    },
    triggerInput() {
      this.$refs.fileinput.click();
    },
    uploadImage(blob) {
      this.accountSvc.uploadImage(blob).then(() => {
        this.file = null;
        this.showCropImage = false;
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    cancelImageCrop() {
      this.showCropImage = false;
      this.file = null;
    },
    handleWrongFileType() {
      this.showCropImage = false;
      this.file = null;
      this.$showSnackbar({ color: 'error', message: this.$t('error.notAnImage') });
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.file = event.target.files[0];
      this.showCropImage = true;
      this.$refs.fileinput.value = '';
    },
  },
  watch: {
    showCropImage(newValue) {
      if (!newValue) {
        this.file = null;
      }
    },
  },
  created() {
    this.companyName = this.loggedInUserAccount.companyName;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .account-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ._company-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 2rem;

      ._avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.6rem;
        text-align: center;

        img {
          width: 6.4rem;
          height: 6.4rem;
          border-radius: .4rem;

          @include box_shadow(1);
        }

        ._img-placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6.4rem;
          height: 6.4rem;
          background-color: map_get($grey, 'darken-1');
          border-radius: .4rem;

          ._text {
            font-size: $font-size-8;
            color: white;
          }
        }
      }
    }

    ._form {
      width: 100%;
      max-width: 28rem;

      ._item {
        margin-bottom: 1.6rem;

        ._label {
          font-size: $font-size-3;
          color: $font-color-secondary;
        }
      }
    }

  }
</style>

<template>
  <div :class="['goals-explorer-list', bordered ? '-bordered' : '']">
    <full-screen-spinner
      v-if="initiallyLoading"
      class="_spinner"
    />
    <template v-else>
      <m-hover-menu
        v-if="showSideMenu && selectedGoalIds.length < 2"
        :item-class="itemClass"
        :mousemove-area-class="mousemoveAreaClass"
        :recreate-key="sortedGoals"
        :force-show="showHoverMenu || showCreateDropdown"
        @set-active-element="setActiveElement"
      >
        <m-card
          flat
          no-padding
          :style="{ marginLeft: '-5rem', marginTop: '.3rem', maxWidth: 'fit-content'}"
          :bordered="viewApplication.gridPageTileGoal === viewApp"
        >
          <div class="_hover-menu-inner">
            <m-tooltip v-if="canCreate && allowedSubItemGoalTypes.length > 0">
              <goal-create-dropdown
                :goal="activeGoal"
                @create-beneath="create"
                @hide="showCreateDropdown = false"
              >
                <m-btn
                  icon="plus"
                  hide-border
                  small
                  super-light
                  fab
                  icon-size="18"
                  :button-style="{ width: '2.2rem' }"
                  :loading="createLoading"
                  :disabled="createLoading"
                  @click="showCreateDropdown = true"
                />
              </goal-create-dropdown>
              <template #title>
                {{ $t('goalsCascadeTable.addTooltip') }}
              </template>
            </m-tooltip>
            <m-dropdown
              v-model:value="showHoverMenu"
              :title="$t('general.actions')"
              placement="leftCenter"
            >
              <m-tooltip>
                <span>
                  <m-btn
                    icon="more"
                    hide-border
                    small
                    fab
                    super-light
                    icon-size="16"
                    :button-style="{ width: '2rem' }"
                    @click="handleHoverMoreClick"
                  />
                </span>
                <template #title>
                  {{ $t('goalsCascadeTable.moreTooltip') }}
                </template>
              </m-tooltip>
              <template #overlay>
                <goal-context-menu
                  :goal="activeGoal"
                  component-type="div"
                  :create-loading="createLoading"
                  :can-create="canCreate"
                  @hide="hideHoverMenu"
                  @goals-duplicated="retrieveGoals(false)"
                  @prop-edited="retrieveGoals(false)"
                  @create-beneath="create"
                />
              </template>
            </m-dropdown>
          </div>
        </m-card>
      </m-hover-menu>
      <m-endless-scroll-list
        @visible="handleAutoPaginate"
      >
        <template
          v-for="item in sortedGoals"
          :key="item.uid"
        >
          <goal-list-item
            :goal="item"
            :props="viewProps"
            :class="['_item', itemClass]"
            :selected="selectedGoalIds.includes(item.uid)"
            :selected-secondary="selectedGoalIds.includes(item.uid) && item.accessRight === 'read'"
            :selected-goal-ids="selectedGoalIds"
            :create-loading="createLoading"
            :can-create="canCreate"
            :read-only="readOnly"
            :show-progress-diff="showProgressDiff"
            :progress-display-option="progressDisplayOption"
            @select-row="$emit('select-goal-row', $event)"
            @selection-right-click="$emit('selection-right-click', $event)"
            @goals-duplicated="retrieveGoals(false)"
            @create="create"
          />
          <m-divider
            v-if="bordered || $store.state.breakpoint.smAndDown"
            :key="`divider_${item.uid}`"
            small
          />
        </template>
      </m-endless-scroll-list>
      <m-content
        v-if="sortedGoals.length === 0 && !canCreate && (!initiallyLoading && !listLoading)"
        :padding-x="6"
        :padding-y="6"
        class="_empty"
      >
        {{
          $t('goalsCascadeTable.emptyGoalList', { title: loggedInUserAccount.goalSettings.featureNamePlural })
        }}
      </m-content>
      <m-btn
        v-if="!initiallyLoading && hasMore"
        hide-border
        light
        full-width
        block
        :style="{ justifyContent: 'flex-start' }"
        :loading="listLoading"
        icon="arrow-down"
        @click="loadMore"
      >
        {{ $t('general.loadMore') }}
      </m-btn>
      <m-btn
        v-if="canCreate"
        hide-border
        light
        full-width
        block
        :style="{ justifyContent: 'flex-start' }"
        :loading="createLoading"
        icon="plus"
        @click="create()"
      >
        {{ $t('general.new') }}
      </m-btn>
    </template>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GoalContextMenu from '@/components/goal/GoalContextMenu.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import GoalListItem from '@/components/goal/GoalListItem.vue';
import useAccess from '@/composables/access/access';
import useCascadeViewParams from '@/composables/goal/cascade/view-params';
import useCustomFuncCtx from '@/composables/custom-func/custom-func-ctx';
import useDebounce from '@/composables/debounce';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useGoalsSorting from '@/composables/goal/sort';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import useSnackBar from '@/composables/snackbar';
import useUsers from '@/composables/user/users';
import { AND } from 'shared/api/query/filter';
import { COUNT, RESULT } from 'shared/api/query/constants';
import { MAX_AUTO_LOAD_LIMIT, VIEWS_SERVICE } from '@/lib/constants';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { computed, inject, toRef } from 'vue';
import { createViewId } from '@/lib/saved-view/saved-view';
import { getAllowedSubItemGoalTypes, goalTypeOption } from '@/composables/goal/allowed-sub-items';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalList } from '@/api/query/nebula/goal';
import { logCatch } from '@/lib/logger/logger';
import { showCompareTo } from '@/lib/compare-to';
import { viewApplication } from 'shared/constants.json';

export default {
  name: 'GoalsExplorerList',
  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    selectedGoalIds: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showSideMenu: {
      type: Boolean,
      default: false,
    },
    goalCreator: {
      type: Object,
      default: () => ({ addChild: () => {} }),
    },
    searchTerm: {
      type: String,
      default: '',
    },
    selectedCycles: {
      type: Array,
      default: () => [],
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    defaultFilter: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['select-goal-row', 'selection-right-click', 'loading-changed', 'data-loading', 'data-loaded'],
  components: { GoalCreateDropdown, GoalListItem, FullScreenSpinner, GoalContextMenu },
  setup(props) {
    const snackbar = useSnackBar();
    const { debounce } = useDebounce();
    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));
    const viewsService = inject(VIEWS_SERVICE);
    const { loggedInUserAccount } = useLoggedInUserAccount();

    const progressDisplayOption = computed(() => viewsService.currentView.value.params.props.find((p) => p.key === goalConfig.edges.cachedCalculatedCurrent).progressDisplayOption);

    const viewParamsService = useCascadeViewParams(
      viewsService,
      props.defaultFilter,
      loggedInUserAccount,
      toRef(props, 'searchTerm'),
      toRef(props, 'selectedCycles'),
    );

    const { userHasRight } = useAccess();
    const { goalTypeProperty } = useGoalTypeProperty();

    const { getGoals, selectSingle, selectMultiple } = useGoals();

    const viewApp = computed(() => viewsService.currentView.value.viewApplication);

    const mousemoveAreaClass = computed(() => {
      switch (viewApp.value) {
        case viewApplication.gridPageTileGoal:
          return 'grid-item';
        default:
          return 'scroll-container';
      }
    });

    const customFuncCtxKey = computed(() => `${createViewId(viewsService.currentView.value)}_compareTo`);
    const customFuncCtx = useCustomFuncCtx(customFuncCtxKey);

    const { properties: goalProperties } = useGoalProperty();
    const userSvc = useUsers();
    const { sort } = useGoalsSorting(goalProperties, userSvc);

    return {
      getGoals,
      selectSingle,
      selectMultiple,
      snackbar,
      debounce,
      peekModeSvc,
      userHasRight,

      selectedView: viewsService.currentView,
      viewProps: viewParamsService.props,
      applyFilterOnFirstLevelOnly: viewParamsService.applyFilterOnFirstLevelOnly,
      queryFilter: viewParamsService.queryFilter,
      gqlFilterObject: viewParamsService.gqlFilterObject,
      order: viewParamsService.order,
      compareTo: viewParamsService.compareTo,

      goalTypeProperty,
      loggedInUserAccount,

      mousemoveAreaClass,
      viewApp,
      viewApplication,
      customFuncCtx,
      sort,
      progressDisplayOption,
    };
  },
  data() {
    return {
      totalAmount: 0,
      itemsPerPage: 50,
      page: 1,
      loadMoreAmount: 10,
      showCreateDropdown: false,
      showHoverMenu: false,
      activeGoalId: -1,

      initiallyLoading: true,
      listLoading: false,
      goalListItems: [],
    };
  },
  computed: {
    createLoading() {
      return this.goalCreator.createLoading.value;
    },
    showProgressDiff() {
      return showCompareTo(this.selectedView.params.compare);
    },
    allowedSubItemGoalTypes() {
      if (this.activeGoal === null) {
        return [];
      }
      return getAllowedSubItemGoalTypes(goalTypeOption(this.activeGoal, this.goalTypeProperty), this.goalTypeProperty);
    },
    activeGoal() {
      return this.selectSingle(this.activeGoalId);
    },
    sortedGoals() {
      const ids = this.goalListItems.map((g) => g.uid);
      const elements = this.selectMultiple(ids).map((g) => {
        if (this.customFuncCtx.state.value !== undefined && this.customFuncCtx.state.value[g.uid] !== undefined) {
          return { ...g, progressCourseDiff: this.customFuncCtx.state.value[g.uid] };
        }
        return g;
      });
      return this.sort(elements, [...this.order]);
    },
    hasMore() {
      return this.totalAmount > this.itemsPerPage;
    },
  },
  methods: {
    handleAutoPaginate() {
      if (this.itemsPerPage >= MAX_AUTO_LOAD_LIMIT) {
        return;
      }

      this.loadMore();
    },
    create({ parent, option } = { parent: { uid: 0 }, option: null }) {
      this.goalCreator.modifiers.setOption(option);
      this.goalCreator.addChild([parent.uid]).then((newGoal) => {
        this.goalCreator.modifiers.setOption(null);
        this.goalListItems.push(newGoal);
        this.peekModeSvc.openGoal({ goalId: newGoal.uid });
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.showCreateDropdown = false;
        this.showHoverMenu = false;
      });
    },
    hideHoverMenu() {
      this.showHoverMenu = false;
      this.activeGoalId = -1;
    },
    handleHoverMoreClick() {
      this.showHoverMenu = true;
      this.$emit('select-goal-row', { goal: { uid: this.activeGoalId } });
    },
    setActiveElement(uid) {
      this.activeGoalId = uid;
    },
    loadMore() {
      if (!this.hasMore) {
        return;
      }

      // load more amount set in GoalCascade and GoalList
      this.itemsPerPage += this.loadMoreAmount;
      this.retrieveGoals();
    },
    retrieveGoals(toggleLoading = true) {
      if (toggleLoading) {
        this.listLoading = true;
      }

      let progressCourseAlias;
      let customFuncHandler;
      if (this.showProgressDiff) {
        progressCourseAlias = this.customFuncCtx.alias.value;
        customFuncHandler = [this.customFuncCtx];
      }
      this.getGoals({
        queries: applyBaseFilter({
          queries: goalList({
            pagination: {
              page: this.page,
              itemsPerPage: this.itemsPerPage,
              countAlias: COUNT,
            },
            progressCourse: this.compareTo,
            progressCourseAlias,
            goalCycles: this.selectedCycles,
            searchTerm: this.searchTerm,
            filter: this.gqlFilterObject.filterTree,
            varBlocks: this.gqlFilterObject.varBlocks,
            order: this.order,
            alias: RESULT,
          }),
          operator: AND,
          baseFilter: this.baseFilter,
        }),
        allAlias: RESULT,
        customFuncHandler,
      }).then((data) => {
        this.$emit('data-loaded');
        this.goalListItems = data[RESULT];
        this.totalAmount = data[COUNT][0][COUNT];
      }).finally(() => {
        this.initiallyLoading = false;
        this.listLoading = false;
      });
    },
    apply(wait) {
      if (!this.initiallyLoading) {
        this.$emit('data-loading');
      }
      this.debounce(this.retrieveGoals, wait);
    },
  },
  watch: {
    order(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.apply(700);
    },
    searchTerm(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      this.apply(700);
    },
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.apply(1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.apply(1000);
      },
      deep: true,
    },
    progressCourse: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.apply(1000);
      },
      deep: true,
    },
    listLoading(val) {
      this.$emit('loading-changed', val);
    },
    applyFilterOnFirstLevelOnly(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      this.apply(1000);
    },
  },
  created() {
    this.apply(0);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goals-explorer-list {
    padding-bottom: .8rem;

    ._empty {
      margin: .8rem 0 .8rem .6rem;
    }

    ._item {
      margin: .4rem 0;
    }

    &.-bordered {
      ._item {
        margin: 0;
      }
    }

    ._hover-menu-inner {
      display: flex;
    }
  }
</style>

import useRepo from '@/nebula/repo';
import useSetParents from '@/composables/goal/set-parents';
import useSnackBar from '@/composables/snackbar';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { RESULT } from 'shared/api/query/constants';
import { goalById, goalChildren, goalParents } from '@/api/query/nebula/goal';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { intersection, unique } from 'shared/lib/array/array';
import { uniqBy } from 'lodash-es';
import { updateListForGoal } from '@/api/query/nebula/update';
import { useI18n } from 'vue-i18n';

export default function useGoals() {
  const repo = useRepo(goalConfig.model);
  const i18n = useI18n();
  const snackbar = useSnackBar();

  const getGoals = ({
    queries,
    customFuncHandler = [],
  }) => repo.query(queries, { interceptors: customFuncHandler });

  const getGoal = (id) => repo.query(
    [
      ...goalById(id, RESULT),
      ...goalParents(id, 'goalParents'),
      ...goalChildren(id, 'children'),
      ...updateListForGoal(id, 'updates'),
    ],
  ).then((data) => {
    if (data[RESULT].length === 0) {
      return data;
    }

    if (typeof data[RESULT][0] === 'undefined') {
      return data;
    }

    repo.updateSingle({ uid: id, updatesLoaded: true }, { commitToRemote: false });

    return data;
  });

  const progressRecalculationTriggers = [
    goalConfig.edges.progressMeasurement,
    goalConfig.edges.start,
    goalConfig.edges.end,
    goalConfig.edges.threshold,
    goalConfig.edges.thresholdTargetArea,
    goalConfig.edges.disableStatusAutoUpdate,
    goalConfig.edges.goalCycle,
    goalConfig.edges.paysOnto,
    goalConfig.edges.publishedAt,
    goalConfig.edges.unboundedProgress,
  ];
  const updateSingle = (entity, options, hookParameter) => {
    if (intersection(Object.keys(entity), progressRecalculationTriggers).length > 0) {
      return repo.updateSingle(entity, { ...options, ignoreResponse: false }, hookParameter);
    }
    return repo.updateSingle(entity, options, hookParameter);
  };

  const updateMultiple = (entities, options, hookParameter) => {
    if (intersection(unique(entities.map((e) => Object.keys(e))), progressRecalculationTriggers).length > 0) {
      return repo.updateMultiple(entities, { ...options, ignoreResponse: false }, hookParameter);
    }
    return repo.updateMultiple(entities, options, hookParameter);
  };

  const { changeParents } = useSetParents({
    goalsSvc: { selectMultiple: repo.selectMultiple, updateMultiple },
    i18n,
    EventBus,
    snackbar,
  });

  const createMultiple = (goals) => repo.createMultiple(goals).then((goals) => {
    EventBus.$emit(EVENTS.GOAL.GOALS_CREATED, { goals });
    return goals;
  });

  const mutateMultiple = (goals) => {
    const goalsToCreate = goals.filter((g) => g.uid === undefined || g.uid === 0);
    return repo.mutateMultiple(goals).then((goals) => {
      EventBus.$emit(EVENTS.GOAL.GOALS_CREATED, { goals: goalsToCreate });
      return goals;
    });
  };

  const getEdgeName = (property) => {
    if (property.edgeName !== undefined) {
      return property.edgeName;
    }
    if (property.type !== undefined) {
      return property.type;
    }
    throw new Error('edge name of property not found');
  };

  const updateProperty = (value, property, goal) => {
    const edge = getEdgeName(property);
    if ([goalConfig.edges.description, goalConfig.edges.goalCycle].includes(edge)) {
      return updateSingle({ uid: goal.uid, [edge]: value });
    }
    if (edge === goalConfig.edges.parents) {
      return changeParents({ goals: [goal], parents: value.map((uid) => ({ uid })) }).then(() => goal);
    }
    return updateSingle(
      {
        uid: goal.uid,
        properties: uniqBy([value, ...goal.properties], (item) => item.property.uid),
      },
    );
  };

  return {
    ...repo,
    updateSingle,
    updateMultiple,
    goals: repo.entityList,
    getGoals,
    getGoal,
    createMultiple,
    mutateMultiple,
    updateProperty,
    changeParents,
  };
}

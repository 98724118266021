import { denormalize as denormalizeFn } from 'normalizr';
import { getType } from '@/nebula/type';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { isReferenced } from '@/nebula/references';
import { ref } from 'vue';

export const addToDenormalize = (uid, model, toDenormalize) => {
  if (toDenormalize[model] === undefined) {
    toDenormalize[model] = { [uid]: true };
    return;
  }
  toDenormalize[model][uid] = true;
};

export const mergeToDenormalize = (onto, from) => {
  if (isNullOrUndefined(onto) || Object.keys(onto) === 0) {
    return from;
  }

  const keys = Object.keys(from);
  for (let i = 0; i < keys.length; i++) {
    onto[keys[i]] = mergeToDenormalize(onto[keys[i]], from[keys[i]]);
  }
  return onto;
};

export const dn = ref({});
const findReverseReference = (uid, references, res, schema) => {
  if (references[uid] === undefined) {
    return;
  }

  Object.values(references[uid]).forEach((r) => {
    const rEdge = r.edges.find((e) => e.edge.includes('~'));
    if (rEdge === undefined) {
      return;
    }
    if (res[rEdge.type] !== undefined && res[rEdge.type][r.uid] === true) {
      return;
    }

    const edge = rEdge.edge.substring(1);
    const edgeType = getType(schema[rEdge.type].schema[edge]);
    if (Object.keys(schema[rEdge.type].excludeFromDenormalization).includes(edge)) {
      return;
    }

    if (!isReferenced(edgeType, schema) && schema[edgeType].reverseEdges === undefined) {
      return;
    }

    if (res[rEdge.type] === undefined) {
      res[rEdge.type] = { };
    }
    res[rEdge.type][r.uid] = true;
    findReverseReference(r.uid, references, res, schema);
  });
};

export const findReverseReferences = (res, references, schema) => {
  const uids = Object.keys(res).reduce((r, model) => {
    r.push(...Object.keys(res[model]));
    return r;
  }, []);
  uids.forEach((uid) => {
    findReverseReference(uid, references, res, schema);
  });
  return res;
};

export const denormalize = (toDenormalize, state, schema, applyFacets) => {
  Object.keys(toDenormalize).forEach((m) => {
    if (schema[m].exported === false) {
      return;
    }
    Object.keys(toDenormalize[m]).forEach((uid) => {
      if (Object.keys(schema[m].schema).length === 0) {
        const val = applyFacets(state[m][uid], m);
        if (val === undefined) {
          return;
        }
        dn.value[m][uid] = val;
        return;
      }

      const val = applyFacets(denormalizeFn(uid, schema[m], state), m);
      if (val === undefined) {
        return;
      }
      dn.value[m][uid] = val;
    });
  });
};

import { dn } from '@/nebula/denormalize';

export const resetState = (state, schema) => {
  Object.values(schema).forEach((s) => {
    state[s.key] = {};
    if (s.exported) {
      dn.value[s.key] = {};
    }
  });
  return state;
};
export const initState = (schema) => resetState({}, schema);

<template>
  <m-content
    :padding="fullscreen"
    :padding-x="fullscreen ? 'layout' : -1"
    :padding-y="0"
    class="goals-list-wrapper-header"
  >
    <goals-list-header
      :ref="(el) => headerRef = el"
      class="_goals-list-header"
      :slots="slotsWithDefaults"
      :allowed-view-types="allowedViewTypes"
      :show-views-selector="showViewsSelector"
      :filter-props="defaultProps"
      :show-create-view="showCreateView"
      show-saved-view-info
      :read-only="readOnly"
      :change-selected-goal-cycles="changeSelectedGoalCycles"
      :selected-goal-cycles="selectedCycles"
      :data-loading="dataLoading"
      show-apply-filter-on-first-level-only
      @create="addChild"
      @string-filter-changed="updateSearchTerm"
      @expand-next-level="handleExpandNextLevel"
      @expand-all="handleExpandAll"
      @collapse-next-level="handleCollapseNextLevel"
      @collapse-all="handleCollapseAll"
    />
    <m-divider
      v-if="[viewType.list].includes(currentView.viewType)"
      none
    />
  </m-content>
  <div
    ref="wrapper"
    :class="['goals-list-wrapper', fullscreen ? '-fullscreen' : '']"
  >
    <div
      v-if="currentView.viewType === viewType.cascade"
      :class="['_padded-container', '-pre-select-area']"
    >
      <div :ref="(el) => headerPortalTarget = el" />
    </div>
    <m-selectable
      v-if="currentView !== null && ![viewType.tree, viewType.atlas].includes(currentView.viewType)"
      v-model:value="selectedGoalIds"
      :class="[
        '_padded-container',
      ]"
      :selector-class="selectorClass"
      :disabled="readOnly"
    >
      <r-goals-cascade-table
        v-if="currentView !== null && currentView.viewType === viewType.cascade && headerPortalTarget !== null"
        ref="goalCascadeTable"
        class="_cascade-table"
        :show-errors="showErrors"
        :style="contentStyle"
        :goal-creator="goalCreator"
        :header-width="headerWidth"
        :base-filter="baseFilter"
        :default-filter="defaultFilter"
        :create-loading="createLoading"
        :search-term="searchTerm"
        :selected-goal-ids="selectedGoalIds"
        :item-class="selectorClass"
        :show-right-click-menu="showRightClickMenu"
        :allow-prop-actions="!readOnly"
        show-saved-view-info
        :read-only="readOnly"
        :show-side-menu="!readOnly"
        :inline-editable="!$store.state.breakpoint.smAndDown"
        :can-create="canCreateGoalsRestricted"
        :header-portal-target="headerPortalTarget"
        :selected-cycles="selectedCycles"
        :expand-local-storage-key-maker="expandLocalStorageKeyMaker"
        @show-menu="handleShowMenuClick"
        @data-loading="dataLoading = true"
        @data-loaded="dataLoading = false"
        @loading-changed="setHeaderWidth"
        @select-goal-row="selectGoalRow"
        @selection-right-click="handleSelectionRightClick"
      />
      <goals-explorer-list
        v-else-if="currentView !== null && currentView.viewType === viewType.list"
        ref="goalsExplorerList"
        :style="listStyle"
        :default-filter="defaultFilter"
        :selected-goal-ids="selectedGoalIds"
        :bordered="bordered"
        :create-loading="createLoading"
        :search-term="searchTerm"
        :item-class="selectorClass"
        :read-only="readOnly"
        :base-filter="baseFilter"
        :goal-creator="goalCreator"
        :show-side-menu="!readOnly"
        :can-create="canCreateGoalsRestricted"
        :selected-cycles="selectedCycles"
        @data-loading="dataLoading = true"
        @data-loaded="dataLoading = false"
        @loading-changed="setHeaderWidth"
        @select-goal-row="selectGoalRow"
        @selection-right-click="handleSelectionRightClick"
      />
    </m-selectable>
    <goal-tree
      v-if="currentView !== null && currentView.viewType === viewType.tree"
      ref="goalTree"
      :search-term="searchTerm"
      :base-filter="baseFilter"
      :read-only="readOnly"
      :goal-creator="goalCreator"
      :goal-detail-sorter="goalDetailSorter"
      :view-sorter="goalViewSorter"
      :default-filter="defaultFilter"
      :can-create="canCreateGoalsRestricted"
      :selected-cycles="selectedCycles"
      @data-loading="dataLoading = true"
      @data-loaded="dataLoading = false"
      @selection-right-click="handleSelectionRightClick"
      @update-selected-goal-ids="goalIds => selectedGoalIds = goalIds"
    />
    <atlas-wrapper
      v-if="currentView !== null && currentView.viewType === viewType.atlas"
      :search-term="searchTerm"
      :base-filter="baseFilter"
      :default-filter="defaultFilter"
      :selected-cycles="selectedCycles"
      @data-loading="dataLoading = true"
      @data-loaded="dataLoading = false"
    />
    <goals-context-menu
      v-if="!readOnly"
      ref="contextMenu"
      :goal-ids="selectedGoalIds"
      :can-create="canCreateGoalsRestricted"
      :show-expand-buttons="currentView !== null && (currentView.viewType === viewType.cascade || currentView.viewType === viewType.tree)"
      @expand-all="handleExpandAll"
      @collapse-all="handleCollapseAll"
    />
  </div>
</template>

<script>
import AtlasWrapper from '@/components/goal/atlas/AtlasWrapper.vue';
import GoalTree from '@/components/goal/GoalTree.vue';
import GoalsContextMenu from '@/components/goal/GoalsContextMenu.vue';
import GoalsExplorerList from '@/components/goal/GoalsExplorerList.vue';
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import useAccess from '@/composables/access/access';
import useGoalCreator from '@/composables/goal/creator';
import useGoalDetailSort from '@/composables/sort/goal-detail-sort';
import useGoalModifiers from '@/composables/goal/modifiers';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoalViewSort from '@/composables/sort/goal-view-sort';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import useSnackBar from '@/composables/snackbar';
import useViewCascadeExpandKeyMaker from '@/composables/local-storage/view-cascade-expand-key-maker';
import { EventBus } from '@/lib/event-bus';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { goal as goalConfig } from 'shared/api/query/configs.json';

import {
  accessGroupFlag,
  viewType,
} from 'shared/constants.json';
import { inject, nextTick, ref, toRef } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { useStore } from 'vuex';

export default {
  name: 'GoalsListWrapper',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    changeSelectedGoalCycles: {
      type: Function,
      default: () => {
      },
    },
    canCreateGoals: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    selectedCycles: {
      type: Array,
      default: () => [],
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    stretchContent: {
      type: Boolean,
      default: false,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [
        viewType.cascade,
        viewType.list,
      ],
    },
    showRightClickMenu: {
      type: Boolean,
      default: true,
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    goalModifiers: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    defaultFilter: {
      type: Object,
      default: () => null,
    },
    headerSlots: {
      type: Array,
      default: () => [],
    },
    defaultProps: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const snackbar = useSnackBar();
    const { userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();

    const { properties: goalProperties } = useGoalProperty();

    const { myTeamsIds } = useLoggedInUser();
    const { goalTypeProperty } = useGoalTypeProperty();
    const store = useStore();
    const goalsSvc = useGoals();
    const goalModifiers = useGoalModifiers(goalTypeProperty, goalsSvc.selectMultiple, toRef(props, 'goalModifiers'));

    const headerRef = ref(null);
    const headerWidth = ref(0);

    const setHeaderWidth = () => {
      nextTick(() => {
        nextTick(() => {
          if (headerRef.value === null || headerRef.value === undefined) {
            return;
          }
          headerWidth.value = headerRef.value.$el.clientWidth;
        });
      });
    };

    const viewSvc = inject(VIEWS_SERVICE);

    setHeaderWidth();

    const expandLocalStorageKeyMaker = useViewCascadeExpandKeyMaker(viewSvc.currentView);

    const goalViewSorter = useGoalViewSort(viewSvc);
    const goalDetailSorter = useGoalDetailSort(goalsSvc);

    const goalCreator = useGoalCreator(
      store,
      goalsSvc,
      goalViewSorter,
      goalDetailSorter,
      goalTypeProperty,
      goalModifiers,
      viewSvc,
      toRef(props, 'selectedCycles'),
    );

    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));

    return {
      snackbar,
      goalProperties,
      userHasRight,
      myTeamsIds,
      goalCreator,
      goalDetailSorter,
      goalViewSorter,
      currentView: viewSvc.currentView,

      loggedInUserAccount,

      headerRef,
      headerWidth,
      setHeaderWidth,

      expandLocalStorageKeyMaker,
      peekModeSvc,
    };
  },
  components: {
    AtlasWrapper,
    GoalsContextMenu,
    GoalsExplorerList,
    GoalsListHeader,
    GoalTree,
  },
  data() {
    return {
      viewType,
      createLoading: false,
      searchTerm: '',
      selectedGoalIds: [],

      dataLoading: false,
      selectorClass: 'goal-item',

      headerPortalTarget: null,
      resizeObserver: null,
    };
  },
  computed: {
    slotsWithDefaults() {
      let slots = [];
      slots.push(...this.headerSlots);
      if (this.currentView.viewType === viewType.tree) {
        slots.push({ name: SLOTS.CARD_WIDTH, inMenu: true }, { name: SLOTS.EXPAND_BUTTONS, inMenu: true });
        // TODO: Remove when compare to can be shown on the goal card
        slots = slots.filter((s) => ![SLOTS.COMPARE_TO, SLOTS.PROGRESS_DISPLAY_OPTION].includes(s.name));
      }
      if (this.currentView.viewType === viewType.atlas) {
        const index = slots.findIndex((s) => s.name === SLOTS.FILTER);
        slots.splice(index + 1, 0, { name: SLOTS.GROUPING });
        // We cannot sort on goals atlas
        slots = slots.filter((s) => s.name !== SLOTS.SORTER);
        // TODO: Remove when compare to can be shown on the goal card
        slots = slots.filter((s) => ![SLOTS.COMPARE_TO, SLOTS.PROGRESS_DISPLAY_OPTION].includes(s.name));
      }
      if ([viewType.cascade].includes(this.currentView.viewType)) {
        slots.push({ name: SLOTS.WRAP_CELLS });
        if (this.currentView.params.props.map((p) => p.key).includes(goalConfig.edges.parents)) {
          slots.push({ name: SLOTS.SHOW_PARENTS });
        }
        slots.push({ name: SLOTS.EXPAND_BUTTONS });
      }
      if (this.canCreateGoals) {
        slots.push({ name: SLOTS.CREATE });
      }
      return slots;
    },
    hasForeignEntityReference() {
      return this.userHasRight([accessGroupFlag.foreignEntityReference]);
    },
    canCreateGoalsRestricted() {
      if (!this.canCreateGoals) {
        return false;
      }
      if (this.hasForeignEntityReference) {
        return true;
      }
      if (this.currentView.space === null || this.currentView.space === undefined) {
        return true;
      }
      return this.myTeamsIds.includes(this.currentView.space.uid);
    },
    contentStyle() {
      if (this.stretchContent) {
        return { minWidth: `${this.headerWidth}px` };
      }
      return {};
    },
    listStyle() {
      return { width: `${this.headerWidth}px` };
    },
    expandRefTarget() {
      switch (this.currentView.viewType) {
        case viewType.cascade:
          return this.$refs.goalCascadeTable;
        case viewType.tree:
          return this.$refs.goalTree;
        default:
          return undefined;
      }
    },
  },
  methods: {
    selectGoalRow({ goal }) {
      this.selectedGoalIds = [goal.uid];
    },
    handleSelectionRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
    updateSearchTerm(term) {
      this.searchTerm = term;
    },
    addChild() {
      this.createLoading = true;
      this.goalCreator.addChild().then((newGoal) => {
        this.peekModeSvc.openGoal({ goalId: newGoal.uid });
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.selectedGoalIds = [];
        this.createLoading = false;
      });
    },
    handleExpandNextLevel() {
      EventBus.$emit('expand-next-level', { target: this.expandRefTarget });
    },
    handleExpandAll() {
      EventBus.$emit('expand-all', { target: this.expandRefTarget });
    },
    handleCollapseNextLevel() {
      EventBus.$emit('collapse-next-level', { target: this.expandRefTarget });
    },
    handleCollapseAll() {
      EventBus.$emit('collapse-all', { target: this.expandRefTarget });
    },
    handleShowMenuClick() {
      this.headerRef.openMenu();
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.setHeaderWidth);
    this.resizeObserver.observe(this.$refs.wrapper);
    window.addEventListener('resize', this.setHeaderWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setHeaderWidth);
    this.resizeObserver.disconnect();
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
@import 'shared/assets/scss/padding';

.goals-list-wrapper-header {
  position: sticky;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 2;

  ._goals-list-header {
    padding-bottom: .6rem;
  }
}

.goals-list-wrapper {
  display: block;

  ._padded-container {
    position: relative;
    float: left;
  }

  &.-fullscreen {
    ._padded-container {
      &:not(.-pre-select-area) {
        padding-bottom: 30vh;
      }

      @include layoutPaddingX();
    }
  }
}
</style>

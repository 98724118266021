import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import { AND, CREATED_AT, RESULT, UID, UID_IN, VAR } from 'shared/api/query/constants';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { computed, ref } from 'vue';
import { goal as goalConfig, propertyValue as propertyValueConfig, user as userConfig } from 'shared/api/query/configs.json';
import { goalDetailChildren } from '@/api/query/nebula/goal';
import { propertyType } from 'shared/constants.json';
import { reverseEdge } from 'shared/api/query/edges';

export default function useQuickActions(goalsSvc, updatesSvc, goalProperties, selectedGoalCycles, loggedInUser) {
  const lastUserUpdate = ref(null);
  const userProps = goalProperties.value.filter((p) => p.type === propertyType.user);
  const userPropIds = userProps.map((p) => p.uid);
  const { publishedAtFilter } = usePublishedAtFilter();
  const init = () => {
    const p1 = fetchGoals();
    const p2 = updatesSvc.fetchUpdatesForUser(
      {
        pagination: { itemsPerPage: 1, page: 1 },
        order: [{ attr: CREATED_AT, desc: true }],
      },
    ).then((res) => {
      if (res.length === 0) {
        return;
      }
      lastUserUpdate.value = res[0];
    });

    return Promise.all([p1, p2]);
  };

  const goalsByUserProperties = ref({});
  const fetchGoals = () => {
    // We don't recommend any goals if the user does not have any goal cycle selected
    // or goals which do not have a goal cycle.
    if (selectedGoalCycles.value.length === 0) {
      return new Promise((resolve) => { resolve(); });
    }
    const queries = applyBaseFilter({
      queries: [
        {
          alias: VAR,
          model: userConfig.model,
          func: { name: UID },
          uid: [loggedInUser.value.uid],
          children: [
            {
              attr: reverseEdge(propertyValueConfig.edges.users),
              model: propertyValueConfig.model,
              children: [
                {
                  attr: reverseEdge(goalConfig.edges.properties),
                  model: goalConfig.model,
                  var: 'property_goals',
                },
              ],
            },
          ],
        },
        {
          func: {
            name: UID,
            needsVar: [{ name: 'property_goals', typ: 1 }],
          },
          alias: RESULT,
          model: goalConfig.model,
          needsVar: [{ name: 'property_goals', typ: 1 }],
          filter: {
            op: AND,
            child: [
              {
                func: {
                  name: UID_IN,
                  attr: goalConfig.edges.goalCycle,
                  args: selectedGoalCycles.value.map((c) => ({ value: `${c.uid}` })),
                },
              },
            ],
          },
          children: [
            ...goalDetailChildren({ childrenFilter: null, childrenOrder: [], customFuncs: [] }),
          ],
        },
      ],
      operator: AND,
      baseFilter: publishedAtFilter,
    });
    return goalsSvc.query(queries).then((data) => {
      const result = userProps.reduce((r, n) => {
        r[n.uid] = { property: n, goals: [] };
        return r;
      }, {});

      goalsByUserProperties.value = goalsSvc.selectMultiple(data[RESULT].map((g) => g.uid)).reduce((res, next) => {
        const propIds = next.properties
          .filter((p) => userPropIds.includes(p.property.uid)
              && p.users.map((u) => u.uid).includes(loggedInUser.value.uid))
          .map((p) => p.property.uid);

        propIds.forEach((id) => {
          res[id].goals.push(next);
        });
        return res;
      }, result);
    });
  };

  const goalsFromLastUpdate = computed(() => {
    if (lastUserUpdate.value === null) {
      return [];
    }
    const goalIds = lastUserUpdate.value.goalActivities
      .filter((ga) => ga.referenced === undefined)
      .map((ga) => ga.goal.uid);
    return goalsSvc.selectMultiple(goalIds);
  });

  return {
    init,
    goalsFromLastUpdate,
    goalsByUserProperties: computed(() => Object.values(goalsByUserProperties.value).filter((g) => g.goals.length > 0)),
  };
}

<template>
  <div class="redeem-coupon-code">
    <div class="_form">
      <m-text-field
        v-model:value="couponCode"
        :placeholder="$t('redeemCouponCode.placeholder')"
        class="_input"
        auto-focus
        @keydown.enter="submit"
      />
      <m-btn
        color="black"
        class="_btn"
        block
        :disabled="couponCode.length < 19"
        :loading="loading"
        @click="submit"
      >
        <template v-if="!isLastStep">
          {{ $t('onboarding.continue') }}
        </template>
        <template v-else>
          {{ $t('onboarding.launchMooncamp') }}
        </template>
      </m-btn>
      <m-btn
        hide-border
        class="_continue-without"
        block
        light
        @click="$emit('continue-without-coupon-code')"
      >
        {{ $t('redeemCouponCode.continueWithoutCoupon') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { RESULT, UID } from 'shared/api/query/constants';
import { couponCode as couponCodeConfig } from 'shared/api/query/configs.json';
import { dogma } from '@/api';
import { mapActions } from 'vuex';
import { notHasFilter } from 'shared/api/query/filter';

export default {
  name: 'RedeemCouponCode',
  props: {
    isLastStep: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUser, getLoggedInUser } = useLoggedInUser();
    return { loggedInUser, getLoggedInUser };
  },
  emits: ['continue-without-coupon-code', 'continue'],
  data() {
    return { couponCode: '', loading: false };
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    onSubmit() {
      this.$emit('continue');
    },
    submit() {
      this.loading = true;
      dogma.query([
        {
          alias: RESULT,
          func: { name: 'eq', attr: couponCodeConfig.edges.code, args: [{ value: this.couponCode }] },
          model: couponCodeConfig.model,
          filter: notHasFilter(couponCodeConfig.edges.redeemedAt),
          children: [
            { attr: UID },
            { attr: couponCodeConfig.edges.planId },
          ],
        },
      ]).then((response) => {
        if (response.status !== 200) {
          this.loading = false;
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        if (response.data[RESULT].length === 0) {
          this.loading = false;
          this.$showSnackbar({ color: 'error', message: this.$t('couponCodeAlert.notFound') });
          return;
        }

        this.updateEntityV2({
          entity: {
            ...response.data[RESULT][0],
            redeemedBy: { uid: this.loggedInUser.uid },
            code: this.couponCode,
          },
          model: couponCodeConfig.model,
          attributes: [{ attr: UID }, { attr: couponCodeConfig.edges.planId }],
          mutation: 'DO_NOTHING',
        }).then((r) => {
          this.loading = false;
          if (r.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.$showSnackbar({ color: 'success', message: this.$t('couponCodeAlert.codeApplied') });
          this.getLoggedInUser();
          this.onSubmit();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .redeem-coupon-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ._form {
      max-width: 28rem;

      ._btn {
        margin: 1.2rem 0 2.4rem;
      }
    }
  }
</style>

<template>
  <div class="metric-form-range">
    <div class="_form-item">
      <div class="_label">
        {{ $t('metricForm.start') }}
      </div>
      <m-input-number
        :value="goal.start"
        class="_number-input _input-field"
        :placeholder="$t('general.empty')"
        :read-only="props.readOnly"
        :formatter="formatter"
        :parser="parser"
        @input="updateStartEnd($event, goal.end)"
      />
      <div
        v-if="startError !== ''"
        class="_error"
      >
        {{ $t(`metricForm.${startError}`) }}
      </div>
    </div>
    <div class="_form-item">
      <div class="_label">
        {{ $t('metricForm.end') }}
      </div>
      <m-input-number
        :value="goal.end"
        class="_number-input _input-field"
        :placeholder="$t('general.empty')"
        :read-only="props.readOnly"
        :formatter="formatter"
        :parser="parser"
        @input="updateStartEnd(goal.start, $event)"
      />
      <div
        v-if="endError !== ''"
        class="_error"
      >
        {{ $t(`metricForm.${endError}`) }}
      </div>
    </div>
    <div class="_form-item">
      <div class="_label">
        {{ $t('metricForm.metric') }}
      </div>
      <m-text-field
        class="_input-field"
        :value="goal.metric"
        :max-length="metricMaxLen"
        :read-only="props.readOnly"
        @change="updateMetric($event)"
      />
    </div>
  </div>
  <div
    v-if="continuousError !== ''"
    class="_error"
  >
    {{ $t(`metricForm.${continuousError}`) }}
  </div>
  <div
    v-if="metricError !== ''"
    class="_error"
  >
    {{ $t(`metricForm.${metricError}`, {length: metricMaxLen}) }}
  </div>
</template>

<script setup>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { computed, ref, watch } from 'vue';
import { numberFormat } from 'shared/constants.json';
import { numberFormatter, numberParser } from '@/lib/props/number';

const props = defineProps({
  goal: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const emit = defineEmits(['update-metric', 'update-start-end']);

const metricMaxLen = 16;

const { userLang } = useLoggedInUser();

const formatter = numberFormatter(numberFormat.number, userLang);
const parser = numberParser(numberFormat.number, userLang);

const startError = ref('');
const endError = ref('');
const metricError = ref('');
const continuousError = ref('');

const goal = ref({
  start: props.goal.start,
  end: props.goal.end,
  metric: props.goal.metric,
});

watch(computed(() => props.goal), (val) => {
  goal.value = { start: val.start, end: val.end, metric: val.metric };
});

const updateStartEnd = (start, end) => {
  goal.value.start = start;
  goal.value.end = end;

  if (goal.value.start === '' || goal.value.start === null || Number.isNaN(start)) {
    startError.value = 'notEmpty';
    return;
  }

  if (goal.value.end === '' || goal.value.end === null || Number.isNaN(end)) {
    endError.value = 'notEmpty';
    return;
  }

  if (goal.value.start === goal.value.end) {
    continuousError.value = 'notEqual';
    return;
  }

  startError.value = '';
  endError.value = '';
  continuousError.value = '';

  emit('update-start-end', goal.value.start, goal.value.end);
};

const updateMetric = (value) => {
  goal.value.metric = value;

  if (goal.value.metric.length === metricMaxLen) {
    metricError.value = 'metricMaxLen';
    return;
  }

  metricError.value = '';
  emit('update-metric', goal.value.metric);
};

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .metric-form-range {
    width: 100%;
    display: flex;

    ._input-field {
        width: 10rem;
        margin-right: .8rem;
      }

    ._label {
        margin-bottom: .4rem;
        font-size: $font-size-2;
        color: $font-color-tertiary;
      }
  }

  ._error {
     margin-top: .8rem;
     font-size: $font-size-2;
     color: $error-color;
   }
</style>

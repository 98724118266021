<template>
  <m-card
    class="space-nav-menu"
    no-padding
    list
  >
    <m-card-item
      v-if="pin === null"
      class="_pin"
      icon="pushpin"
      small
      :disabled="!removable"
      :loading="pinLoading"
      @click="pinItem"
    >
      {{ $t('spaceNavMenu.pin') }}
    </m-card-item>
    <m-tooltip
      v-else
    >
      <span>
        <m-card-item
          class="_pin"
          icon="pushpin-filled"
          small
          :disabled="!removable"
          :loading="unpinLoading"
          @click="unpinItem"
        >
          {{ $t('spaceNavMenu.unpin') }}
        </m-card-item>
      </span>
      <template
        v-if="!removable"
        #title
      >
        {{ $t('spaceItem.disabledHelp', { name: pin.space.title, title: spacePropertyTitle }) }}
      </template>
    </m-tooltip>
    <m-card-item
      hide-border
      small
      icon="settings"
      @click.stop="editCardVisible = true"
    >
      {{ $t('spaceNavMenu.settings', { title: spacePropertyTitle }) }}
    </m-card-item>
    <m-dialog
      :value="editCardVisible"
      :title="$t('spaceEditor.edit')"
      hide-footer
      no-padding
      @close="editCardVisible = false"
    >
      <space-editor
        :space="space"
        @updated="handleSpaceUpdated"
        @deleted="handleSpaceDeleted"
        @close="editCardVisible = false"
      />
    </m-dialog>
  </m-card>
</template>

<script>
import SpaceEditor from '@/components/spaces/SpaceEditor.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePins from '@/composables/pin/pin';
import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_VIEW } from '@/route-params';
import { pinType, routeName } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'SpaceNavMenu',
  props: {
    space: {
      type: Object,
      required: true,
    },
    pin: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['close'],
  components: { SpaceEditor },
  setup() {
    const { accountHasFeature, userHasRight } = useAccess();
    const { linkToRoute } = useViewNavigator();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { createPinLoading, createPin, deletePinLoading, deletePin } = usePins();
    const { spaceProperty } = useProperties();
    const spacesSvc = useSpaces();
    return {
      userLang,
      accountHasFeature,
      userHasRight,
      linkToRoute,
      account: loggedInUserAccount,
      personalAppSettings,
      pinLoading: createPinLoading,
      createPin,
      unpinLoading: deletePinLoading,
      deletePin,
      property: spaceProperty,
      spacesSvc,
    };
  },
  data() {
    return {
      editCardVisible: false,
      textByLang,
    };
  },
  computed: {
    removable() {
      if (this.pin === null) {
        return true;
      }

      return this.pin.removable;
    },
    spacePropertyTitle() {
      return textByLang(this.property.label, this.userLang);
    },
  },
  methods: {
    pinItem() {
      this.createPin({
        space: { uid: this.space.uid },
        personalAppSettings: { uid: this.personalAppSettings.uid },
        type: pinType.space,
      }).then(() => {
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    unpinItem() {
      this.deletePin(this.pin).then(() => {
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    handleSpaceUpdated() {
      this.editCardVisible = false;
      this.$emit('close');
    },
    handleSpaceDeleted() {
      if (this.$route.name !== routeName.spaceDetails || parseInt(this.$route.params.optionId, 10) !== this.space.uid) {
        return;
      }
      const link = this.linkToRoute(undefined, routeName.home, GOAL_VIEW);
      this.$router.push(link.to);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-nav-menu {
    min-width: 15rem;
  }
</style>

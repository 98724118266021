<template>
  <breadcrumbs
    :breadcrumbs="breadcrumbs"
  >
    <template #last>
      <div class="_team-selection">
        <m-select
          :value="selectedTeam"
          :items="teams"
          value-key="uid"
          item-text="title"
          return-object
          hide-border
          tags
          btn
          small
          @change="mutateSelectedTeam"
        />
      </div>
    </template>
  </breadcrumbs>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useMSTeamsConfiguration from '@/composables/logged-in-user/ms-teams-configuration';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed } from 'vue';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'TeamSelection',
  components: { Breadcrumbs },
  setup() {
    const { myTeams } = useLoggedInUser();
    const { updateSingle, createSingle, msTeamsConfiguration, selectedTeam } = useMSTeamsConfiguration();
    const teams = computed(() => myTeams.value.map((s) => ({ ...s, icon: buildIconFromEntity(s) })));
    return { teams, updateSingle, createSingle, msTeamsConfiguration, selectedTeam };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          title: this.$t('msTeamsTeamDetails.myTeams'),
          disabled: true,
        },
        {
          title: '',
          disabled: false,
        },
      ];
    },
  },
  methods: {
    mutateSelectedTeam(team) {
      const update = { selectedTeam: { uid: team.uid } };
      if (this.msTeamsConfiguration !== null) {
        update.uid = this.msTeamsConfiguration.uid;
      }

      if (this.msTeamsConfiguration === null || this.msTeamsConfiguration.uid === undefined) {
        this.createSingle({ selectedTeam: { uid: team.uid } }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
        return;
      }

      this.updateSingle(
        { uid: this.msTeamsConfiguration.uid, selectedTeam: { uid: team.uid } },
      ).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._team-selection {
    display: flex;
  }
</style>

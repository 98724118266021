<template>
  <m-dialog
    v-model:value="showOnboarding"
    :title="$t('demoDataModal.title')"
    hide-header
    hide-footer
    :max-width="$modalSizes.lg"
    keep-open-on-mask-click
  >
    <div class="_content demo-data-modal">
      <h3>
        {{ $t('demoDataModal.title') }}
      </h3>
      <div
        class="_description"
        v-html="$t('demoDataModal.description')"
      />
      <div :style="videoStyle">
        <iframe
          :src="$t('demoDataModal.introVideo')"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style="width: 100%; height: 100%;"
        />
      </div>
      <div class="_cta">
        <m-btn
          class="_btn"
          @click="updateWelcome({ showTour: false })"
        >
          {{ $t('demoDataModal.skipTour') }}
        </m-btn>
        <m-btn
          v-if="!$store.state.breakpoint.smAndDown"
          color="primary"
          class="_btn -primary"
          @click="updateWelcome({ showTour: true })"
        >
          {{ $t('demoDataModal.takeTour') }}
        </m-btn>
      </div>
    </div>
  </m-dialog>
</template>

<script>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import { EventBus } from '@/lib/event-bus';
import { onboardingGuideSteps, routeName } from 'shared/constants.json';

export default {
  name: 'DemoDataModal',
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const accountSettingsSvc = useAccountSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { isDone, markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    return {
      loggedInUser,
      isDone,
      markAsDone,
    };
  },
  data() {
    return { showOnboarding: false };
  },
  computed: {
    videoStyle() {
      const width = parseInt(this.$modalSizes.lg, 10) - 48; // modalSize - padding
      const height = width * (9 / 16); // video in 16 : 9 format
      return { height: `${height}px`, width: `${width}px`, position: 'relative' };
    },
  },
  methods: {
    updateWelcome({ showTour }) {
      this.markAsDone([onboardingGuideSteps.welcomeDialog])
        .then(() => {
          this.showOnboarding = false;
          if (showTour) {
            this.$router.push({ name: routeName.goalsExplorer, query: { showTour: true } });
          }
        })
        .catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
    },
    showVideo() {
      this.showOnboarding = true;
    },
  },
  created() {
    EventBus.$on('show-welcome-video', this.showVideo);
  },
  beforeUnmount() {
    EventBus.$off('show-welcome-video', this.showVideo);
  },
  mounted() {
    if (!this.isDone(onboardingGuideSteps.welcomeDialog)) {
      this.showOnboarding = true;
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h3 {
      margin-bottom: 1.2rem;
    }

    ._description {
      margin-bottom: 2rem;
      font-size: $font-size-4;
    }

    ._cta {
      display: flex;
      width: 100%;
      margin-top: 3rem;

      ._btn {
        &.-primary {
          margin-left: auto;
        }
      }
    }
  }
</style>

<template>
  <m-dialog
    v-model:value="showModal"
    :title="$t('onboardingGuide.inviteUserModal')"
    hide-footer
    no-padding
  >
    <invite-users @close="showModal = false" />
  </m-dialog>
</template>

<script>
import { EventBus } from '@/lib/event-bus';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'InviteUsersDialog',
  components: { InviteUsers: defineAsyncComponent(() => import('@/components/onboarding/InviteUsers.vue')) },
  data() {
    return { showModal: false };
  },
  methods: {
    show() {
      this.showModal = true;
    },
  },
  created() {
    EventBus.$on('show-invite-users-dialog', this.show);
  },
  beforeUnmount() {
    EventBus.$off('show-invite-users-dialog', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>

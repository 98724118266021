<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      fill="#185c37"
      d="M21.744,57H11.256A2.256,2.256,0,0,1,9,54.744V31.25l7.91667-2.33333L24,31.25V54.744A2.256,2.256,0,0,1,21.744,57Z"
    />
    <path
      fill="#33c481"
      d="M38,7H51.67A2.33,2.33,0,0,1,54,9.33V36.67A2.33,2.33,0,0,1,51.67,39H38L35.33333,21.91667Z"
    />
    <path
      fill="#107c41"
      d="M9,9.33V32H24V48H36.67A2.33,2.33,0,0,0,39,45.67V7H11.33A2.33,2.33,0,0,0,9,9.33Z"
    />
    <path
      fill="#21a366"
      d="M24 7H39V24H24z"
    />
  </svg>
</template>

<script>
export default { name: 'MSPlanner' };
</script>

<style scoped lang="scss" type="text/scss">

</style>

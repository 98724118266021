import { UID } from 'shared/api/query/constants';
import { computed } from 'vue';
import { dogma } from '@/api';
import { findInArray } from 'shared/lib/array/array';
import {
  goal as goalConfig,
  update as updateConfig,
} from 'shared/api/query/configs.json';
import { routeName } from 'shared/constants.json';
import { useStore } from 'vuex';

export default function usePageVisits() {
  const key = 'MC_PAGE_VISITS';
  const store = useStore();
  const pageVisits = computed(() => store.state.pageVisit);
  const pageVisitTypes = [
    { type: 'goal', routeParamKey: 'goalId', storeKey: 'goals' },
    { type: 'update', routeParamKey: 'updateId', storeKey: 'update' },
  ];

  const getLastPageVisit = () => {
    if (pageVisits.value.length > 0) {
      return pageVisits.value[0];
    }

    return null;
  };

  const createPageVisit = (pageVisit) => new Promise((resolve) => {
    const ignorePage = (pageVisit) => {
      if ([
        routeName.logIn,
        routeName.signUp,
        routeName.signUpLandingPage,
        routeName.forgetPassword,
        routeName.ssoLogIn,
        routeName.setPassword,
        routeName.teamsLogin,
        routeName.teamsAuth,
        routeName.teamsAccountOkrs,
        routeName.teamsTeamOkrs,
        routeName.formDetails,
        routeName.logout,
        routeName.success,
        routeName.publishedView,
        routeName.accountSpace,
      ].includes(pageVisit.routeName)) {
        return true;
      }

      if (![
        routeName.goalsExplorer,
        routeName.updatesExplorer,
        routeName.profile,
        routeName.spaceDetails,
        routeName.goalInsights,
      ].includes(pageVisit.routeName)) {
        return false;
      }

      return Object.keys(pageVisit.query).length === 0;
    };
    const enrichPageVisit = (pageVisit) => {
      let node;

      for (let i = 0; i < pageVisitTypes.length; i++) {
        const type = pageVisitTypes[i].type;
        const key = pageVisitTypes[i].routeParamKey;
        if (typeof pageVisit.params[key] !== 'undefined') {
          node = { uid: parseInt(pageVisit.params[key], 10), type };
        }
        if (typeof pageVisit.query[key] !== 'undefined') {
          node = { uid: parseInt(pageVisit.query[key], 10), type };
        }
      }

      if (typeof node !== 'undefined') {
        return { ...pageVisit, node };
      }

      return pageVisit;
    };

    if (ignorePage(pageVisit)) {
      resolve(pageVisits.value);
      return;
    }

    store.commit('PAGE_VISIT_CREATED', { entity: enrichPageVisit(pageVisit) });
    storePageVisits(pageVisits.value);
    resolve(pageVisits.value);
  });

  const storePageVisits = (pvs) => {
    if (pvs.length > 0) {
      const userId = pvs[0].user.uid;
      localStorage.setItem(`${key}_${userId}`, JSON.stringify(pvs));
    }
  };

  const fetchLocalPageVisits = ({ user }) => {
    const userId = user.uid;
    store.commit('PAGE_VISIT_RETRIEVED', { entities: JSON.parse(localStorage.getItem(`${key}_${userId}`)) || [] });
    return pageVisits.value;
  };

  const getEnrichedPageVisits = () => new Promise((resolve) => {
    const enrichPageVisitFromCollection = (pv, collection) => {
      const node = { ...pv.node };

      if (typeof pv.node.title === 'undefined' && typeof pv.node.icon === 'undefined') {
        const fullNode = findInArray({ haystack: collection, needle: pv.node.uid });
        if (fullNode !== null) {
          node.title = fullNode.title;
          node.icon = fullNode.icon;
          store.commit('PAGE_VISIT_NODE_ENRICHED', { node });
          return {
            ...pv,
            node,
          };
        }
      }

      return pv;
    };

    // Local search
    const fromLocal = pageVisits.value.map((pv) => {
      if (pv.node) {
        const pvType = pageVisitTypes.find((t) => t.type === pv.node.type);
        const collection = store.state[pvType.storeKey];

        return enrichPageVisitFromCollection(pv, collection);
      }
      return pv;
    });
    const fromRemote = fromLocal.filter((pv) => pv.node && typeof pv.node.title === 'undefined' && typeof pv.node.icon === 'undefined');

    if (fromRemote.length === 0) {
      storePageVisits(fromLocal);
      resolve(fromLocal);
      return;
    }
    const nodeIds = fromRemote.map((pv) => pv.node.uid);
    fetchRemotePageVisitsNodesByID(nodeIds).then((remotePageVisits) => {
      const res = fromLocal.map((pv) => {
        if (pv.node) {
          return enrichPageVisitFromCollection(pv, remotePageVisits);
        }
        return pv;
      });
      storePageVisits(res);
      resolve(res);
    }).catch(() => {
      resolve(fromLocal.filter((pv) => typeof pv.node === 'undefined' || !(typeof pv.node.title === 'undefined' && typeof pv.node.icon === 'undefined')));
    });
  });

  const fetchRemotePageVisitsNodesByID = (ids) => dogma.query([
    {
      alias: 'goals',
      uid: ids,
      func: { name: UID },
      model: goalConfig.model,
      children: [{ attr: UID }, { attr: 'title' }, { attr: 'icon' }],

    },
    {
      alias: 'updates',
      uid: ids,
      func: { name: UID },
      model: updateConfig.model,
      children: [{ attr: UID }, { attr: 'title' }, { attr: 'icon' }],
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error('fail to retrieve page visit details');
    }
    return Object.values(response.data).flat();
  });

  return {
    pageVisits,
    getEnrichedPageVisits,
    createPageVisit,
    fetchPageVisits: fetchLocalPageVisits,
    getLastPageVisit,
  };
}

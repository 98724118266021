<template>
  <export-top-bar
    v-if="isPrint"
    :title="page.title"
  />
  <grid-page-loading-error
    v-if="error !== null && !loading"
    class="_error"
    @retry="load"
  />
  <not-found-page
    v-else-if="!loading && page === undefined"
    :to="{ name: routeName.dashboards }"
    :title="$t('notFoundPage.title', { type: $t('notFoundPage.dashboard')})"
    :anchor-text="$t('notFoundPage.buttonTextGoto', { name: $t('navigation.dashboards')})"
  />
  <page-layout
    v-else
    id="dashboard"
    :class="['dashboard-details', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
    :loading="loading"
    :hide-top-bar="isPrint"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <app-feedback-dropdown
            topic="customPages"
            small
          />
          <share-dropdown
            :grid-page="page"
            :disabled="page.accessRight !== accessPolicyType.full"
          />
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown"
            :title-suggestion="titleSuggestion"
            :icon-suggestion="buildIconFromEntity(page)"
          />
          <m-dropdown
            v-if="$store.state.breakpoint.smAndDown || canEdit"
            v-model:value="showActionMenu"
            :title="$t('general.actions')"
            placement="bottomRight"
          >
            <m-btn
              icon="ellipsis"
              hide-border
              fab
              small
            />
            <template #overlay>
              <m-card
                list
                no-padding
              >
                <favorite-button
                  v-if="$store.state.breakpoint.smAndDown"
                  :title-suggestion="titleSuggestion"
                />
                <m-card-item
                  icon="download"
                  @click="exportAsPDF"
                >
                  {{ $t('general.saveAsPDF') }}
                </m-card-item>
                <m-card-item
                  v-if="canEdit"
                  icon="delete"
                  @click="onDeleteGridPage"
                >
                  {{ $t('general.delete') }}
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </page-top-bar>
    </template>
    <overlay-spinner
      v-if="pdfExportLoading"
      :text="$t('general.exporting')"
    />
    <scroll-container>
      <m-content
        v-if="!isPrint"
        class="_content"
        padding-x="layout"
      >
        <grid-page-header
          :grid-page="page"
          :read-only="!canEdit"
        />
      </m-content>
      <m-content :full-height="isEmpty">
        <empty
          v-if="isEmpty"
          :grid-page="page"
          :read-only="!canEdit"
        />
        <grid-page
          v-else
          :grid-page="page"
          :read-only="!canEdit"
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script setup>
import AppFeedbackDropdown from '@/components/app-feedback/AppFeedbackDropdown.vue';
import Empty from '@/components/custom-grid/Empty.vue';
import ExportTopBar from '@/components/dashboard/ExportTopBar.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GridPage from '@/components/custom-grid/GridPage.vue';
import GridPageHeader from '@/components/custom-grid/GridPageHeader.vue';
import GridPageLoadingError from '@/components/custom-grid/GridPageLoadingError.vue';
import NotFoundPage from '@/components/NotFoundPage.vue';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import ShareDropdown from '@/components/custom-grid/ShareDropdown.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useConfirmDialog from '@/composables/confirm-dialog';
import useExport from '@/composables/export/export';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageSlideShow from '@/composables/grid-page/slide-show';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useSnackBar from '@/composables/snackbar';
import { accessPolicyType, printablePage, routeName } from 'shared/constants.json';
import { buildIcon, buildIconFromEntity } from 'shared/lib/icon';
import { computed, onMounted, ref, watch } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

const { t } = useI18n();
const gridPageService = useGridPage();

const { isPrint, saveAsPDF, pdfExportLoading } = useExport();

const { accountHasFeature } = useAccess();

const showActionMenu = ref(false);
const { loggedInUser } = useLoggedInUser();

const exportAsPDF = () => {
  showActionMenu.value = false;
  saveAsPDF({ page: printablePage.dashboard, gridPage: { uid: page.value.uid } });
};

const route = useRoute();
const gridPageId = computed(() => {
  const res = route.params.pageId;
  if (res === undefined) {
    return null;
  }

  return parseInt(res, 10);
});
const page = computed(() => {
  if (gridPageId.value === null) {
    return null;
  }
  return gridPageService.selectSingleGridPage(gridPageId.value);
});
const rows = computed(() => {
  if (page.value === null) {
    return [];
  }
  return page.value.rowOrder.map(({ uid }) => gridPageService.gridPageRows.value.find((row) => row.uid === uid)).filter((row) => row !== undefined);
});
const isEmpty = computed(() => rows.value.length === 0);

const loading = ref(true);
const error = ref(null);

const load = () => {
  if (gridPageId.value === null) {
    return;
  }
  loading.value = true;
  error.value = null;
  gridPageService.queryGridPage(gridPageId.value)
    .catch(logCatch((e) => {
      error.value = e;
    }))
    .finally(() => {
      loading.value = false;
    });
};

watch(gridPageId, () => {
  load();
}, { immediate: true });

const confirmDialog = useConfirmDialog();
const snackbar = useSnackBar();
const router = useRouter();
const onDeleteGridPage = () => {
  const deleteMethod = () => {
    gridPageService.deleteGridPage(page.value).then(() => {
      router.push({ name: routeName.dashboards });
    }).catch(logCatch(() => snackbar.error()));
  };

  confirmDialog.confirm({
    onOk() {
      deleteMethod();
    },
  });
};

const viewTitle = computed(() => (page.value != null && page.value.title !== '' ? page.value.title : t('list.noTitle')));
const titleSuggestion = computed(() => `${t('navigation.dashboards')} - ${viewTitle.value}`);

const breadcrumbs = computed(() => [
  {
    title: t('navigation.dashboards'),
    icons: [{ value: buildIcon('AppstoreOutlined') }],
    disabled: false,
    to: { name: routeName.dashboards },
  },
  {
    title: viewTitle.value,
    icons: [{ value: buildIconFromEntity(page.value) }],
    disabled: true,
  },
]);
const canEdit = computed(() => {
  if (page.value === null) {
    return false;
  }
  return [accessPolicyType.full, accessPolicyType.write].includes(page.value.accessRight);
});

const { loggedInUserAccount } = useLoggedInUserAccount();
const accountSettingsSvc = useAccountSettings();
const onboardingMarker = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
const { showGridPageSlideShow } = useGridPageSlideShow(onboardingMarker);

onMounted(() => {
  if (loggedInUser.value.gridPageSlideShowShown === null) {
    showGridPageSlideShow();
  }
});

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
._error {
  width: 100%;
}

.dashboard-details {
  ._content {
    position: sticky;
    left: 0;
    width: 100%;
  }
}

</style>

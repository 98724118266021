import { defaultClient } from '@/lib/client-type';
import { objectifyCookie } from 'shared/lib/cookie';
import { setBreakpoints } from 'shared/breakpoints';

export const getDefaultState = () => ({
  breakpoint: setBreakpoints(),
  loggedInUserId: 0,
  isLoggedIn: false,
  forms: [],
  form: null,
  formResultData: {},
  formResultLoading: false,
  formResultFilterTree: null,
  formResultIntervalConfig: null,
  formResultAnswers: null,
  formResultAnswerCount: 0,
  unsavedFormChangesExist: false,
  unsavedSurveyProcessChangesExist: false,
  surveyProcess: null,
  origSurveyProcess: null,
  formTemplates: [],
  formTemplate: null,
  unsavedFormTemplateChangesExist: false,
  registerURLs: {
    google: null,
    microsoft: null,
  },
  loginURLs: {
    google: null,
    microsoft: null,
  },
  surveyForm: null,
  appVersion: '',
  semverVersion: '',
  foreignLogin: typeof objectifyCookie(document.cookie).foreign_login !== 'undefined',
  feedbackAnswer: [],
  pageVisit: [],
  publicAccountData: null,
  clientInfo: defaultClient(),
  provisioningStatus: null,
  maintenanceMode: false,
  trashItems: { goal: [], count: { goal: undefined } },
  customFuncCtx: {},
});

<template>
  <m-card-item
    :key="template.uid"
    class="template-list-item"
    :icon="template.empty ? 'file' : 'file-text'"
    :super-light="superLight"
    max-width-left="30rem"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click.stop="select(template)"
  >
    <item-title
      :title="title"
      :icons="[{ value: icon }]"
    />
    <template #right>
      <m-dropdown
        v-if="!template.empty && canEdit"

        v-model:value="showDropdown"
        :title="$t('general.actions')"
      >
        <m-btn
          v-if="alwaysShowActions || hover || showDropdown"
          icon="ellipsis"
          fab
          hide-border
          xs
          :super-light="superLight"
          @click.stop="showDropdown = true"
        />
        <template #overlay>
          <m-card

            list
            no-padding
          >
            <m-card-item
              icon="edit"
              @click.stop="edit(template)"
            >
              {{ $t('general.edit') }}
            </m-card-item>
            <m-card-item
              icon="delete"
              @click.stop="deleteM(template)"
            >
              {{ $t('general.delete') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </template>
  </m-card-item>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import { accessPolicyType } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { mapActions } from 'vuex';

export default {
  name: 'TemplateListItem',
  props: {
    template: {
      type: Object,
      required: true,
    },
    superLight: {
      type: Boolean,
      default: false,
    },
    alwaysShowActions: {
      type: Boolean,
      default: false,
    },
  },
  components: { ItemTitle },
  emits: ['edit', 'delete', 'select-template'],
  data() {
    return {
      showDropdown: false,
      hover: false,
    };
  },
  computed: {
    title() {
      return this.template.title === '' ? this.$t('list.noTitle') : this.template.title;
    },
    icon() {
      return buildIconFromEntity(this.template);
    },
    canEdit() {
      return [accessPolicyType.full, accessPolicyType.write].includes(this.template.accessRight);
    },
  },
  methods: {
    ...mapActions(['createEntity']),
    edit(template) {
      this.$emit('edit', template);
      this.showDropdown = false;
    },
    deleteM(template) {
      this.$emit('delete', template);
    },
    select() {
      this.$emit('select-template', { template: this.template });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>

<template>
  <grid-page-loading-error
    v-if="error !== null && !loading"
    @retry="init"
  />
  <div
    v-else-if="gridPageTileSingleGoal === null || loading"
    class="_spinner"
  >
    <m-spinner />
  </div>
  <div
    v-else
    class="grid-page-tile-single-goal"
  >
    <div
      v-if="goal !== null"
      class="_goal"
    >
      <div
        v-if="gridPageTileSingleGoal.type === gridPageTileSingleGoalType.number"
        class="_number"
      >
        <div class="_metric">
          <m-tooltip
            placement="rightBottom"
            :disabled="!hasNumberTooltip(current)"
          >
            <div class="_number">
              {{ formatCompactMetricWithUnit(current) }}
            </div>
            <template #title>
              {{ formatMetric(current) }}
            </template>
          </m-tooltip>
          <div class="_unit">
            <span v-if="!isInlineUnit">{{ metric }}</span>
          </div>
        </div>
        <div class="_delta-container">
          <div
            v-if="hasDiff"
            :class="['_delta', diffPercent === 0 ? '-neutral' : '', diffPercent > 0 ? '-positive' : '', diffPercent < 0 ? '-negative' : '']"
          >
            <m-tooltip
              placement="rightBottom"
              :disabled="!hasNumberTooltip(expectedNow)"
            >
              <span v-if="diff !== null"><span v-if="diff > 0">+</span>{{ formatCompactMetricWithUnit(diff) }} </span>(<span v-if="diffPercent > 0">+</span><span v-else-if="diffPercent < 0" /><span v-else>±</span>{{ diffPercent }}%)
              <template #title>
                {{ formatMetric(diff) }}
              </template>
            </m-tooltip>
          </div>
        </div>
        <div class="_secondary">
          <m-tooltip
            placement="rightBottom"
            :disabled="!hasNumberTooltip(expectedNow)"
          >
            {{ $t('gridPageSingleGoal.goal.expected') }} {{ isPercentageUnit ? formatCompactMetricWithUnit(expectedNow) : formatCompactMetric(expectedNow) }}
            <template #title>
              {{ formatMetric(expectedNow) }}
            </template>
          </m-tooltip>
        </div>
      </div>
      <div
        v-if="gridPageTileSingleGoal.type === gridPageTileSingleGoalType.line"
        class="_line"
      >
        <goal-progress-chart
          :goal="goal"
          :x-axis="xAxis"
          :height="height"
          :goal-updates="goalUpdates"
        />
      </div>
    </div>
    <div
      v-else
      class="_empty"
    >
      <m-icon
        type="inbox"
        size="24"
        :color="$colors.grey.lighten2"
        :style="{paddingBottom: '1rem'}"
      />
      <div
        class="_tertiary"
        :style="{paddingBottom: '1rem'}"
      >
        {{ $t('gridPageSingleGoal.empty.noGoal') }}
      </div>
      <m-btn
        v-if="!props.readOnly"
        super-light
        icon="settings"
        @click="openGoalEditor($event)"
      >
        {{ $t('gridPage.tile.header.singleGoal.settings') }}
      </m-btn>
      <m-content :padding-y="9" />
    </div>
  </div>
  <m-context-menu
    :ref="(el) => contextMenuRef = el"
    :relocate-key="showGoalEditor"
    placement="center"
    @hide="showGoalEditor = false"
  >
    <grid-page-tile-single-goal-editor :grid-page-tile-single-goal="gridPageTileSingleGoal" />
  </m-context-menu>
</template>

<script setup>
import GoalProgressChart from '@/components/goal/GoalProgressChart.vue';
import GridPageLoadingError from '@/components/custom-grid/GridPageLoadingError.vue';
import GridPageTileSingleGoalEditor from '@/components/custom-grid/GridPageTileSingleGoalEditor.vue';
import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
import useCustomFuncCtx from '@/composables/custom-func/custom-func-ctx';
import useGoal from '@/composables/goal/goal';
import useGoals from '@/composables/goal/goals';
import useGridPage from '@/composables/grid-page/grid-page';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useTileSingleGoalFetcher from '@/composables/goal/tile-single-goal-fetcher';
import { DateTime } from 'luxon';
import { compareToDateTime } from '@/lib/compare-to';
import { computed, ref, watch } from 'vue';
import { customFunc, goalProgressMeasurement, gridPageTileSingleGoalType } from 'shared/constants.json';
import { getPointInLineBounded } from '@/lib/charts/line-chart';
import { getRoundedValue } from '@/lib/charts/format';
import { logCatch } from '@/lib/logger/logger';
import { current as progressCurrent } from '@/lib/goal/progress';
import { translateDynamicTimeRange } from '@/lib/filter/dynamic-date';

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const gridPageSvc = useGridPage();
const row = computed(() => gridPageSvc.gridPageRows.value.find((row) => row.tiles.some((tile) => tile.uid === props.gridPageTile.uid)));
const height = computed(() => {
  const heightConfig = gridPageSvc.rowsWithDiff.value[row.value.uid];
  let height = heightConfig.height;
  if (heightConfig.diff !== null) {
    height += heightConfig.diff;
  }
  // height of the card - height of the header (static, 54px) and a buffer
  return height - 75;
});

const gridPageTileSingleGoal = computed(() => {
  const res = gridPageSvc.gridPageTileSingleGoal.value.find((singleGoalTile) => singleGoalTile.tile.uid === props.gridPageTile.uid);
  if (res === undefined) {
    return null;
  }
  return res;
});

const goalSelector = useGoals();
const progressCourse = computed(() => (progressCourseCtx.state.value !== undefined ? progressCourseCtx.state.value[gridPageTileSingleGoal.value.goal.uid] : []));
const goal = computed(() => {
  if (gridPageTileSingleGoal.value === null || gridPageTileSingleGoal.value.goal === null) {
    return null;
  }

  const res = goalSelector.selectSingle(gridPageTileSingleGoal.value.goal.uid);
  if (res === undefined) {
    return null;
  }
  return { ...res, progressCourse: progressCourse.value };
});

const current = computed(() => {
  if (goal.value.progressMeasurement === goalProgressMeasurement.alignedItems) {
    return Math.round(Math.min(100, goal.value.cachedCalculatedCurrent));
  }
  return progressCurrent(goal.value, goal.value.cachedCurrent);
});
const metric = computed(() => {
  if ([goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.value.progressMeasurement)) {
    return goal.value.metric;
  }
  return '%';
});
const isPercentageUnit = computed(() => metric.value === '%');
const isInlineUnit = computed(() => metric.value.length === 1);
const hasNumberTooltip = (value) => formatCompactMetric(value) !== `${value}`;
const { userLang } = useLoggedInUser();
const formatMetric = (value) => new Intl.NumberFormat(userLang.value, { style: 'decimal' }).format(value);
const formatCompactMetric = (value) => new Intl.NumberFormat('en-US', { notation: 'compact' }).format(value);
const formatCompactMetricWithUnit = (value) => {
  const valueToString = formatCompactMetric(value);
  if (isInlineUnit.value) {
    return `${valueToString}${metric.value}`;
  }
  return `${valueToString}`;
};

const customFuncCtxAlias = computed(() => (gridPageTileSingleGoal.value !== null ? `${gridPageTileSingleGoal.value.uid}_compareTo` : ''));
const customFuncCtx = useCustomFuncCtx(customFuncCtxAlias);
const progressCourseDiff = computed(() => (customFuncCtx.state.value !== undefined ? customFuncCtx.state.value[gridPageTileSingleGoal.value.goal.uid][0] : null));
const hasDiff = computed(() => goal.value !== null && progressCourseDiff.value !== null);
const diff = computed(() => {
  if (!hasDiff.value) {
    return null;
  }

  return Math.round(current.value - progressCourseDiff.value.value);
});

const diffPercent = computed(() => {
  if (!hasDiff.value) {
    return 0;
  }

  return Math.round(goal.value.cachedCalculatedCurrent - progressCourseDiff.value.progress);
});

const hasCycle = computed(() => goal.value.goalCycle.length > 0);
const cycleStart = computed(() => {
  if (goal.value.goalCycle.length === 0) {
    return { start: DateTime.now().minus({ months: 3 }).toISO() };
  }
  return minBy(goal.value.goalCycle, (c) => Date.parse(c.start));
});

const cycleEnd = computed(() => {
  if (goal.value.goalCycle.length === 0) {
    return { end: DateTime.now().toISO() };
  }
  return maxBy(goal.value.goalCycle, (c) => Date.parse(c.end));
});

const parseDateCycle = (cycle, key) => {
  if (cycle === null) {
    return Date.now();
  }
  return Date.parse(cycle[key]);
};

const baseLine = computed(() => {
  if (!hasCycle.value) {
    return [
      { x: Date.parse(goal.value.createdAt), y: 0 },
      { x: Date.parse(goal.value.createdAt), y: 0 },
    ];
  }
  if (goal.value.progressMeasurement === goalProgressMeasurement.binary) {
    return [
      { x: parseDateCycle(cycleStart.value, 'start'), y: 0 },
      { x: parseDateCycle(cycleEnd.value, 'end'), y: 100 },
    ];
  }
  if (goal.value.progressMeasurement === goalProgressMeasurement.threshold) {
    return [
      { x: parseDateCycle(cycleStart.value, 'start'), y: goal.value.threshold },
      { x: parseDateCycle(cycleEnd.value, 'end'), y: goal.value.threshold },
    ];
  }
  return [
    { x: parseDateCycle(cycleStart.value, 'start'), y: goal.value.start },
    { x: parseDateCycle(cycleEnd.value, 'end'), y: goal.value.end },
  ];
});

const expectedNow = computed(() => getRoundedValue(getPointInLineBounded(baseLine.value, Date.now()).y, 0));

const { goalUpdates } = useGoal('', computed(() => goal.value?.uid), false);

const xAxis = computed(() => {
  const cStart = DateTime.fromISO(cycleStart.value.start).toMillis();
  const cEnd = DateTime.fromISO(cycleEnd.value.end).toMillis();
  const xAxis = { minTickInterval: null, min: cStart, max: cEnd };

  if (gridPageTileSingleGoal.value === null || ![gridPageTileSingleGoalType.line].includes(gridPageTileSingleGoal.value.type)) {
    return xAxis;
  }

  const { start, end } = translateDynamicTimeRange(gridPageTileSingleGoal.value.timeRange);
  if (start === undefined && end === undefined) {
    return xAxis;
  }
  if (start !== null) {
    xAxis.min = DateTime.fromISO(start).toMillis();
  }
  if (end !== null) {
    xAxis.max = DateTime.fromISO(end).toMillis();
  }
  return xAxis;
});

const progressCourseCtx = useCustomFuncCtx(ref(customFunc.progressCourse));

const compareTo = computed(() => {
  if (gridPageTileSingleGoal.value === null || ![gridPageTileSingleGoalType.number].includes(gridPageTileSingleGoal.value.type)) {
    return [];
  }
  const cmp = compareToDateTime(gridPageTileSingleGoal.value.compareTo);
  if (cmp === null) {
    return [];
  }
  return [cmp];
});
const goalFetcher = useTileSingleGoalFetcher(progressCourseCtx);
const loadingGoal = ref(false);
const loadingTile = ref(true);
const loading = computed(() => loadingGoal.value || loadingTile.value);
const retrieveGoal = () => {
  loadingGoal.value = true;
  let customFuncHandler;
  if (compareTo.value.length !== 0) {
    customFuncHandler = customFuncCtx;
  }
  goalFetcher.getGoalById(gridPageTileSingleGoal.value.goal.uid, customFuncHandler, compareTo).finally(() => {
    loadingGoal.value = false;
  });
};
const error = ref(null);

const init = () => {
  loadingTile.value = true;
  error.value = null;
  gridPageSvc.queryGridPageTileSingleGoalByTile(props.gridPageTile)
    .catch(logCatch((e) => {
      error.value = e;
    })).finally(() => {
      loadingTile.value = false;
    });
};

init();

watch(gridPageTileSingleGoal, (val) => {
  if (error.value !== null) {
    return;
  }
  if (val === null || val.goal === null) {
    return;
  }
  retrieveGoal();
});

const contextMenuRef = ref(null);
const showGoalEditor = ref(false);
const openGoalEditor = (event) => {
  const rect = event.srcElement.getBoundingClientRect();
  showGoalEditor.value = true;
  contextMenuRef.value.show({ ...event, pageX: rect.x + (rect.width / 2), pageY: rect.top + rect.height + 5 });
};

</script>

<style scoped lang="scss" type="text/scss">
._spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._error {
  margin-top: -6rem;
  height: 100%;
}

.grid-page-tile-single-goal {
  padding: 0 1.6rem .8rem 1.6rem;
  overflow: hidden;
  width: 100%;
  height: 100%;

  ._goal {
    height: 100%;

    ._number {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      ._metric {
        text-align: center;

        ._number {
          font-size: $font-size-10;
          font-weight: $font-weight-bold;
        }

        ._unit {
          height: 2.1rem;
          color: $font-color-secondary;
          font-size: $font-size-4;
        }
      }

      ._secondary {
        color: $font-color-secondary;
      }

      ._delta-container{
        height: 2.4rem;
        display: flex;
        align-items: center;
        font-size: $font-size-4;
        white-space: nowrap;
      }

      ._delta {
        white-space: pre;

        &.-negative {
          color: $error-color;
        }

        &.-positive {
          color: $success-color;
        }

        &.-neutral {
          color: $font-color-secondary;
        }
      }
    }
  }

  ._empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ._tertiary {
      color: $font-color-tertiary;
    }
  }
}

</style>

import { UID } from 'shared/api/query/constants';
import {
  gridPageTile as gridPageTileConfig,
  gridPageTileIframe as gridPageTileIframeConfig,
} from 'shared/api/query/configs.json';

export const gridPageTileIframeChildren = [
  { attr: UID },
  { attr: gridPageTileIframeConfig.edges.url },
  { attr: gridPageTileIframeConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
];

<template>
  <sign-in-content class="password-forget-form">
    <div class="_hint">
      <p>
        {{ $t('passwordForgetForm.hint1') }}
      </p>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('general.email') }}
      </div>
      <m-text-field
        v-model:value="email"
        auto-focus
        :placeholder="$t('loginForm.enterEmail')"
        @keydown.enter="submit"
      >
        <template #prefix>
          <m-icon
            type="mail"
            :color="$colors.grey.lighten2"
          />
        </template>
      </m-text-field>
    </div>
    <div class="_action">
      <m-btn
        :loading="loading"
        :button-style="{ width: '100%' }"
        :style="{ width: '100%' }"
        color="black"
        @click="submit"
      >
        {{ $t('passwordForgetForm.buttonText') }}
      </m-btn>
    </div>
    <div class="_bottom">
      <div>
        {{ $t('login.haveAccount') }}
        <m-link
          :to="login"
          inherit-color
          underlined
        >
          {{ $t('login.logIn') }}
        </m-link>
      </div>
    </div>
  </sign-in-content>
</template>

<script>
import SignInContent from '@/components/SignInContent.vue';
import { doSendPasswordForgetMail } from '@/api';
import { routeName } from 'shared/constants.json';

export default {
  name: 'PasswordForgetForm',
  components: { SignInContent },
  data() {
    return {
      email: '',
      loading: false,
    };
  },
  computed: {
    login() {
      return { name: routeName.logIn };
    },
  },
  methods: {
    submit() {
      this.loading = true;
      doSendPasswordForgetMail({ email: this.email })
        .then((response) => {
          this.loading = false;
          if (response.status === 403) {
            this.$showSnackbar({ color: 'error', message: this.$t('login.passwordLoginRestricted') });
            return;
          }
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('passwordForgetForm.error') });
            return;
          }

          this.$showSnackbar({ color: 'success', message: this.$t('passwordForgetForm.success') });
        });
    },
  },
  created() {
    this.email = this.$route.query.email;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .password-forget-form {
    ._hint {
      margin-bottom: 2rem;
    }

    ._item {
      margin-bottom: 1rem;

      ._label {
        font-size: $font-size-3;
        color: $font-color-secondary;
      }
    }

    ._bottom {
      margin-top: 5rem;
      font-size: $font-size-4;
      color: $font-color-secondary;
      text-align: center;
    }
  }
</style>

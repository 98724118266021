<template>
  <div class="hubspot-summary">
    <div class="_item">
      <div class="_label">
        {{ $t('hubspotSummary.trackMetric') }}:
      </div>
      <div class="_content">
        {{ $t(`hubspotForm.trackMetric.${camelCase(query.trackMetric)}`) }}
      </div>
    </div>
    <div
      v-if="query.start !== null"
      class="_item"
    >
      <div class="_label">
        {{ $t('hubspotSummary.startDate') }}:
      </div>
      <div class="_content">
        {{ formatDate(query.start) }}
      </div>
    </div>
    <div
      v-if="query.end !== null"
      class="_item"
    >
      <div class="_label">
        {{ $t('hubspotSummary.endDate') }}:
      </div>
      <div class="_content">
        {{ formatDate(query.end) }}
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { camelCase } from 'lodash-es';

export default {
  name: 'HubspotSummary',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { camelCase };
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .hubspot-summary {
    ._item {
      display: flex;

      ._label {
        flex: 0 0 10rem;
        color: $font-color-secondary;
      }
    }
  }
</style>

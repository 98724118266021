import { DEFAULT_ATTRIBUTES } from 'shared/api/query/constants';
import {
  accessGroup as accessGroupConfig,
  accessPolicy as accessPolicyConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';

export const accessGroupBaseChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: accessGroupConfig.edges.name, default: '' },
  { attr: accessGroupConfig.edges.accessGroupWriteAccess, default: false },
  { attr: accessGroupConfig.edges.formWriteAccess, default: false },
  { attr: accessGroupConfig.edges.formTemplateWriteAccess, default: false },
  { attr: accessGroupConfig.edges.userWriteAccess, default: false },
  { attr: accessGroupConfig.edges.propertyWriteAccess, default: false },
  { attr: accessGroupConfig.edges.spaceWriteAccess, default: false },
  { attr: accessGroupConfig.edges.goalCycleWriteAccess, default: false },
  { attr: accessGroupConfig.edges.accountWriteAccess, default: false },
  { attr: accessGroupConfig.edges.createGoalAccess, default: false },
  { attr: accessGroupConfig.edges.goalSuperAccess, default: false },
  { attr: accessGroupConfig.edges.goalDashboardViewAccess, default: false },
  { attr: accessGroupConfig.edges.publicSavedViewAccess, default: false },
  { attr: accessGroupConfig.edges.foreignEntityReferenceAccess, default: false },
  { attr: accessGroupConfig.edges.instantFeedbackTemplateAccess, default: false },
  { attr: accessGroupConfig.edges.updateAdminAccess, default: false },
  { attr: accessGroupConfig.edges.createDashboardAccess, default: false },
  { attr: accessGroupConfig.edges.dashboardSuperAccess, default: false },
];
export const accessGroupFullChildren = [
  ...accessGroupBaseChildren,
  { attr: accessGroupConfig.edges.isNotDeletable, default: false },
  { attr: accessGroupConfig.edges.isAdminGroup, default: false },
  { attr: accessGroupConfig.edges.isDefaultGroup, default: false },
  {
    attr: accessGroupConfig.edges.accessPolicy,
    model: accessPolicyConfig.model,
    default: [],
    children: accessPolicyChildren,
  },
];

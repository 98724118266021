<template>
  <div :class="classes">
    <m-editor-menu-bubble
      v-if="editor !== null && !disabled"
      :editor="editor"
      :allowed-content="allowedContent"
    />
    <m-floating-menu
      v-if="editor !== null && !disabled && !$store.state.breakpoint.smAndDown"
      :editor="editor"
      :style="{ fontSize: defaultFontSize }"
    />
    <editor-content
      v-if="editor !== null"
      :editor="editor"
      :class="['_editor', scrollable ? '-scrollable' : '']"
      :style="{ fontSize: defaultFontSize }"
    />
    <m-mention-menu
      v-if="allowedContent.includes(editorNodeType.mention) || allowedContent.includes(editorNodeType.goalMention)"
      ref="mentionMenu"
      :allowed-content="allowedContent"
      :editor="editor"
    />
    <m-emoji-suggestion
      ref="emoji"
      :editor="editor"
    />
    <m-context-menu
      v-if="!$store.state.breakpoint.smAndDown"
      ref="contextMenu"
      :allowed-content="allowedContent"
      :custom-items="customFloatingButtons"
      :editor="editor"
    />
    <mobile-menu-component
      v-if="editor !== null && !disabled && $store.state.breakpoint.smAndDown"
      :editor="editor"
      :focused="focused"
      :allowed-content="allowedContent"
      :custom-items="customFloatingButtons"
      @blur="blur"
    />
  </div>
</template>

<script>
import Editor from '@/tiptap/tiptap/Editor';
import EditorContent from '@/tiptap/tiptap/Components/EditorContent';
import Emoji from '@/components/editor/emoji';
import EmojiSuggestion from '@/components/editor/emoji-suggestion';
import EnterExtension from '@/components/editor/enter-extension';
import FileUpload from '@/components/editor/file-upload';
import GoalMention from '@/components/editor/goal-mention';
import HardBreak, {
  Blockquote,
  BulletList,
  CodeBlock,
  Heading,
  ListItem,
  Mention,
  OrderedList,
} from '@/tiptap/extensions/nodes';
import ImageUpload from '@/components/editor/image';
import Link from '@/components/editor/link';
import MContextMenu from '@/components/editor/MContextMenu.vue';
import MEditorMenuBubble from '@/components/editor/MEditorMenuBubble.vue';
import MEmojiSuggestion from '@/components/editor/MEmojiSuggestion.vue';
import MFloatingMenu from '@/components/editor/MFloatingMenu.vue';
import MMentionMenu from '@/components/editor/MMentionMenu.vue';
import MobileMenuComponent from '@/components/editor/MobileMenuComponent.vue';
import TodoItem from '@/components/editor/todo-item';
import TodoList from '@/components/editor/todo-list';
import Underline from '@/components/editor/underline';
import useFileInputDialog from '@/components/editor/file-input-dialog';
import { Bold, Code, Italic, Strike } from '@/tiptap/extensions/marks';
import { History, Placeholder, TrailingNode } from '@/tiptap/extensions/extensions';
import { editorNodeType } from 'shared/constants.json';
import { markRaw } from 'vue';
import { uploadFile } from '@/lib/image/image';

export default {
  name: 'MEditor',
  props: {
    placeholder: {
      type: [String, Function],
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    allowedContent: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: Object,
      default: () => ({}),
    },
    customFloatingButtons: {
      type: Array,
      default: () => [],
    },
    lightPlaceholder: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    defaultFontSize: {
      type: String,
      default: '1.4rem',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    headingLevels: {
      type: Array,
      default: () => [1, 2, 3],
    },
    enterHandlers: {
      type: Array,
      default: () => [],
    },
    ctrlEnterHandlers: {
      type: Array,
      default: () => [],
    },
    modEnterHandlers: {
      type: Array,
      default: () => [],
    },
    shiftEnterHandlers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input', 'update:value', 'top-reached', 'bottom-reached', 'focus', 'blur'],
  components: {
    MobileMenuComponent,
    MFloatingMenu,
    MEditorMenuBubble,
    EditorContent,
    MMentionMenu,
    MContextMenu,
    MEmojiSuggestion,
  },
  setup() {
    const fileInputDialogHandler = useFileInputDialog();
    return { fileInputDialogOpen: (editor) => fileInputDialogHandler.isOpen(editor) };
  },
  data() {
    return {
      editor: null,
      editorNodeType,
      focused: false,
    };
  },
  computed: {
    classes() {
      return [
        'm-editor',
        this.lightPlaceholder ? '-light-placeholder' : '',
      ];
    },
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'ArrowUp' && this.editor.selection.from === 1 && this.editor.selection.to === 1) {
        this.$emit('top-reached');
      }
      if (event.key === 'ArrowDown'
          && this.editor.selection.to + 1 === this.editor.view.state.doc.content.size
          && this.editor.selection.from + 1 === this.editor.view.state.doc.content.size) {
        this.$emit('bottom-reached');
      }
    },
    clearContent() {
      this.editor.clearContent();
    },
    focus() {
      this.editor.focus();
    },
    blur() {
      this.editor.blur();
    },
    onFocus() {
      this.$emit('focus');
      this.focused = true;
    },
    onBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
      setTimeout(() => {
        if (typeof this.$refs === 'undefined') {
          return;
        }
        if (typeof this.$refs.mentionMenu !== 'undefined' && this.$refs.mentionMenu !== null) {
          this.$refs.mentionMenu.blur();
        }
        if (typeof this.$refs.contextMenu !== 'undefined' && this.$refs.contextMenu !== null) {
          this.$refs.contextMenu.blur();
        }
      }, 300);
    },
    updateContent(event) {
      const content = event.getJSON();
      this.$emit('input', content);
      this.$emit('update:value', content);
    },
    setContent(content) {
      this.editor.setContent(content);
      this.$emit('input', content);
      this.$emit('update:value', content);
    },
    contentAllowed(nodeType) {
      return this.allowedContent.indexOf(nodeType) > -1;
    },
    extensions() {
      return [
        this.contentAllowed(editorNodeType.blockquote) ? new Blockquote() : null,
        this.contentAllowed(editorNodeType.codeBlock) ? new CodeBlock() : null,
        this.contentAllowed(editorNodeType.heading) ? new Heading({ levels: this.headingLevels }) : null,
        this.contentAllowed(editorNodeType.orderedList) ? new OrderedList() : null,
        this.contentAllowed(editorNodeType.orderedList) ? new ListItem() : null,
        this.contentAllowed(editorNodeType.bulletList) ? new BulletList() : null,
        this.contentAllowed(editorNodeType.todoList) ? new TodoItem({ nested: true }) : null,
        this.contentAllowed(editorNodeType.todoList) ? new TodoList() : null,
        this.$refs.emoji.newEmoji(),
        new EmojiSuggestion(),
        new Emoji(),
        new HardBreak(),
        this.initContextMenu(),
        this.initMentionMenu(),
        new GoalMention(),
        new Link(),
        new EnterExtension({
          enterHandlers: this.enterHandlers,
          ctrlEnterHandlers: this.ctrlEnterHandlers,
          modEnterHandlers: this.modEnterHandlers,
          shiftEnterHandlers: this.shiftEnterHandlers,
        }),
        new Mention(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        this.hidePlaceholder ? null : new Placeholder({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.placeholder,
          showOnlyWhenEditable: false,
          showOnlyCurrent: true,
        }),
        new TrailingNode({
          node: 'paragraph',
          notAfter: [
            'paragraph',
            'heading',
            'ordered_list',
            'bullet_list',
            'blockquote',
          ],
        }),
        new ImageUpload({
          uploader: uploadFile,
          imageTypes: [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/bmp',
            'image/gif',
            'image/svg',
          ],
          snackbar: this.$showSnackbar,
          translator: (key) => this.$t(key),
        }),
        // has to come after ImageUpload since we only call event.preventDefault once
        new FileUpload({
          uploader: uploadFile,
          excludedTypes: [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/bmp',
            'image/gif',
            'image/svg',
          ],
          fileUploadClass: 'file-upload',
          snackbar: this.$showSnackbar,
          translator: (key) => this.$t(key),
        }),
      ].filter((e) => e !== null);
    },
    initContextMenu() {
      if (this.$store.state.breakpoint.smAndDown) {
        return null;
      }
      return this.$refs.contextMenu.newMenu();
    },
    initMentionMenu() {
      if (this.$store.state.breakpoint.smAndDown || (!this.allowedContent.includes(editorNodeType.mention) && !this.allowedContent.includes(editorNodeType.goalMention))) {
        return null;
      }
      return this.$refs.mentionMenu.newMenu();
    },
  },
  watch: {
    autoFocus(val) {
      if (!this.focused && val === true) {
        this.editor.focus();
      }
    },
    disabled() {
      this.editor.setOptions({ editable: !this.disabled });
    },
    initialValue(newVal) {
      if (!this.focused && this.editor !== null && !this.fileInputDialogOpen(this.editor)) {
        this.editor.setContent(newVal);
      }
    },
  },
  mounted() {
    if (this.initialValue !== null && Object.keys(this.initialValue).length > 0) {
      this.editor = markRaw(new Editor({
        editable: !this.disabled,
        content: this.initialValue,
        onUpdate: this.updateContent,
        extensions: this.extensions(),
        onFocus: this.onFocus,
        onBlur: this.onBlur,
      }));
      if (this.autoFocus) {
        this.editor.focus();
      }
      return;
    }

    this.editor = markRaw(new Editor({
      editable: !this.disabled,
      onUpdate: this.updateContent,
      extensions: this.extensions(),
      onFocus: this.onFocus,
      onBlur: this.onBlur,
    }));
    if (this.autoFocus) {
      this.editor.focus();
    }
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
};
</script>

<style
    lang="scss"
    type="text/scss"
>
  .m-editor {
    position: relative;

    ._editor {
      &.-scrollable {
        max-height: 25rem;
        overflow: auto;
      }
    }

    p {
      min-height: 3rem;
      padding: .3rem 0;
      margin-bottom: 0;
      line-height: 1.5;

      code {
        padding: $xxs-container-padding-x $xxs-container-padding-y;
        font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
        font-size: $font-size-3;
        color: map_get($red, 'base');
        background-color: map_get($grey, 'lighten-4');
        border-radius: $border-radius-sm;
      }

      .mention {
        color: $font-color-secondary;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol,
    ul {
      padding-inline-start: 2rem;
      margin-bottom: 0;

      li {
        p {
          margin-bottom: 0;
        }
      }
    }

    pre {
      padding: 30px 16px 30px 20px;
      font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
      font-size: $font-size-4;
      white-space: pre;
      background-color: map_get($grey, 'lighten-4');
    }

    blockquote {
      padding: 0 .8rem;
      font-size: $font-size-6;
      border-left: 3px solid currentColor;
    }

    &.-light-placeholder {
      p.is-editor-empty:first-child::before {
        color: map_get($grey, 'lighten-1') !important;
      }
    }

    .ProseMirror {
      p {
        &.is-editor-empty:first-child {
          &::before {
            float: left;
            height: 0;
            color: $font-color-secondary;
            pointer-events: none;
            content: attr(data-empty-text);
          }
        }
      }

      &.ProseMirror-focused {
        border: none;
        outline: none;

        p.is-editor-empty:first-child::before {
          content: "";
        }
      }
    }

    a {
      color: $font-color-secondary;

      &:hover {
        color: $font-color-primary;
      }
    }

    h1 {
      font-size: $font-size-9;
      font-weight: $font-weight-semibold;
      line-height: 1.3;
    }

    h2 {
      font-size: $font-size-8;
      font-weight: $font-weight-semibold;
      line-height: 1.3;
    }

    h3 {
      font-size: $font-size-7;
      font-weight: $font-weight-semibold;
      line-height: 1.3;
    }

    strong {
      font-weight: $font-weight-semibold;
    }

    ul[data-type="todo_list"] {
      padding-left: 0;
    }
  }
</style>

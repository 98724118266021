<template>
  <div class="grid-page-header">
    <editable-page-header
      class="_title"
      :title="gridPage.title"
      :icon-types="iconTypes"
      :icon="icon"
      :show-icon="!isEmptyIcon(icon) || iconAdded"
      :placeholder="$t('list.noTitle')"
      :auto-focus="!props.readOnly && gridPage.title === ''"
      size="small"
      :disabled="props.readOnly"
      @change-title="updateTitle"
      @change-icon="updateIcon"
    >
      <template
        v-if="!props.readOnly && !isPrint"
        #actions
      >
        <div class="_header-actions">
          <m-btn
            v-if="isEmptyIcon(icon) && !iconAdded"
            hide-border
            class="_item"
            icon="smile"
            light
            small
            @click="addIcon"
          >
            {{ $t('page.addEmoji') }}
          </m-btn>
          <m-btn
            v-if="isEmptyDescription && !descriptionAdded"
            hide-border
            class="_item"
            icon="align-left"
            light
            small
            @click="addDescription"
          >
            {{ $t('gridPage.header.addDescription') }}
          </m-btn>
        </div>
      </template>
    </editable-page-header>
    <div class="_editor-content -condensed">
      <m-editor
        v-if="!isEmptyDescription || descriptionAdded"
        ref="descriptionRef"
        :initial-value="gridPage.description"
        :disabled="props.readOnly"
        :placeholder="$t('gridPage.header.addADescription')"
        :allowed-content="allowedContent"
        light-placeholder
        @input="updateDescription"
        @blur="blurDescription"
      />
    </div>
    <m-content :padding-y="9" />
  </div>
</template>

<script setup>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useDebounce from '@/composables/debounce';
import useExport from '@/composables/export/export';
import useGridPage from '@/composables/grid-page/grid-page';
import useSnackBar from '@/composables/snackbar';
import { buildIconFromEntity, isEmptyIcon, replaceIconOnEntity } from 'shared/lib/icon';
import { computed, nextTick, ref } from 'vue';
import { editorNodeType, iconType } from 'shared/constants.json';
import { isZero } from '@/lib/editor/editor';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  gridPage: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const { isPrint } = useExport();

const gridPageService = useGridPage();
const { t } = useI18n();
const snackBar = useSnackBar();
const debounce = useDebounce();

const iconTypes = [iconType.emoji, iconType.icon, iconType.custom];

const gridPage = computed(() => gridPageService.selectSingleGridPage(props.gridPage.uid));

const updateTitle = (title) => {
  if (title === gridPage.value.title) {
    return;
  }
  const fn = () => gridPageService.updateGridPage({ uid: gridPage.value.uid, title }).catch(() => snackBar.error(t('error.default')));
  debounce.debounce(fn, 500);
};

const icon = computed(() => buildIconFromEntity(gridPage.value));
const iconAdded = ref(false);
const addIcon = () => {
  iconAdded.value = true;
};

const updateIcon = (icon) => {
  const entity = replaceIconOnEntity({ ...gridPage.value }, icon);
  gridPageService.updateGridPage({ uid: entity.uid, icon: entity.icon, image: entity.image }).catch(() => snackBar.error(t('error.default')));
  if (isEmptyIcon(icon)) {
    iconAdded.value = false;
  }
};

const descriptionRef = ref(null);
const allowedContent = [editorNodeType.bulletList, editorNodeType.orderedList];
const descriptionAdded = ref(false);
const addDescription = () => {
  descriptionAdded.value = true;
  nextTick(() => {
    descriptionRef.value.focus();
  });
};
const isEmptyDescription = computed(() => {
  if (gridPage.value.description === null) {
    return true;
  }
  return isZero(gridPage.value.description);
});
const updateDescription = (description) => {
  if (JSON.stringify(description) === JSON.stringify(gridPage.value.description)) {
    return;
  }

  const fn = () => gridPageService.updateGridPage({ uid: gridPage.value.uid, description }).catch(() => snackBar.error(t('error.default')));
  debounce.debounce(fn, 500);
};
const blurDescription = () => {
  if (isEmptyDescription.value) {
    descriptionAdded.value = false;
  }
};

</script>

<style scoped lang="scss" type="text/scss">
.grid-page-header {
  ._title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ._header-actions {
      display: flex;
    }
  }

  ._editor-content {
    margin-top: -1rem;
    font-size: $font-size-1;
    color: $font-color-secondary;

    &.-condensed {
      p {
        min-height: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>

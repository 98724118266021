import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import { comment as commentConfig, update as updateConfig, user as userConfig } from 'shared/api/query/configs.json';

export const commentEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: commentConfig.edges.message, default: null },
  {
    attr: commentConfig.edges.creator,
    model: userConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: commentConfig.edges.update,
    model: updateConfig.model,
    children: [{ attr: UID }],
    default: null,
  },
];

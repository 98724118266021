<template>
  <div class="jira-overall-progress">
    <div class="_overall-count">
      <div class="_label">
        {{ $t('jiraOverallProgress.countIssues') }}
      </div>
      <jira-jql-editor
        :data-source="dataSource"
        :jql="jiraQuery.overallCountJql"
        class="_jql"
        @change="updateOverallCountJql"
      />
    </div>
    <div class="_divider">
      /
    </div>
    <div class="_total">
      <div class="_label">
        {{ $t('jiraOverallProgress.totalIssues') }}
      </div>
      <jira-jql-editor
        :data-source="dataSource"
        :jql="jiraQuery.totalJql"
        class="_jql"
        @change="updateTotalJql"
      />
    </div>
  </div>
</template>

<script>
import JiraJqlEditor from '@/components/datasource/JiraJqlEditor.vue';
import { jqlLink } from '@/lib/jira';

export default {
  name: 'JiraOverallProgressVue',
  props: {
    jiraQuery: {
      type: Object,
      required: true,
    },
    dataSource: {
      type: Object,
      required: true,
    },
  },
  emits: ['overall-count-jql-submit', 'total-jql-submit'],
  components: { JiraJqlEditor },
  data() {
    return { jqlLink };
  },
  methods: {
    updateOverallCountJql(val) {
      this.$emit('overall-count-jql-submit', val);
    },
    updateTotalJql(val) {
      this.$emit('total-jql-submit', val);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .jira-overall-progress {
    display: grid;
    grid-template-columns: calc(50% - 1.5rem) 3rem calc(50% - 1.5rem);

    ._jql {
      width: 100%;
    }

    ._divider {
      align-self: end;
      justify-self: center;
      margin-bottom: .4rem;
    }

    ._label {
      margin-bottom: .4rem;
      color: $font-color-secondary;
    }
  }
</style>

<template>
  <div>
    <i18n-t
      :keypath="`notificationFeed.${camelCase(feedEntry.type)}`"
      tag="div"
    >
      <template
        v-if="feedEntry.type === feedEntryType.updateNotification"
        #checkinTitle
      >
        <strong>
          <m-link
            :to="link()"
            inherit-color
            background-on-hover
          >
            {{ feedEntry.notification.title }}
          </m-link>
        </strong>
      </template>
      <template
        v-else-if="[feedEntryType.updateMention, feedEntryType.updateComment].includes(feedEntry.type)"
        #checkInTitle
      >
        <strong>
          <m-link
            :to="link()"
            inherit-color
            background-on-hover
          >
            <template v-if="feedEntry.update.creator === null">
              {{
                $t('feedEntryTitle.checkIn', { creator: $t('user.deleted.name') })
              }}
            </template>
            <template v-else>
              {{
                $t('feedEntryTitle.checkIn', { creator: `${feedEntry.update.creator.firstName} ${feedEntry.update.creator.lastName}` })
              }}
            </template>
          </m-link>
        </strong>
      </template>
      <template
        v-if="[feedEntryType.survey].includes(feedEntry.type)"
        #formTitle
      >
        <strong>
          <m-link
            :to="link()"
            inherit-color
            background-on-hover
          >
            {{ feedEntry.form.title }}
          </m-link>
        </strong>
      </template>
      <template
        v-if="[feedEntryType.goalPropertyValueReferenced, feedEntryType.goalComment, feedEntryType.goalUpdateMention, feedEntryType.goalUpdate, feedEntryType.updateMention, feedEntryType.updateComment, feedEntryType.goalUpdateComment, feedEntryType.feedbackInquiry, feedEntryType.feedbackDeclined, feedEntryType.feedbackReceived].includes(feedEntry.type)"
        #actor
      >
        <strong>
          <template v-if="feedEntry.actor === null">
            {{ $t('user.deleted.name') }}
          </template>
          <m-link
            v-else
            :to="userLink"
            inherit-color
            background-on-hover
          >
            {{ feedEntry.actor.firstName }} {{ feedEntry.actor.lastName }}
          </m-link>
        </strong>
      </template>
      <template
        v-if="[feedEntryType.goalPropertyValueReferenced, feedEntryType.goalComment, feedEntryType.goalUpdateMention, feedEntryType.goalUpdateComment, feedEntryType.goalUpdate].includes(feedEntry.type)"
        #goalTitle
      >
        <strong>
          <m-link
            inherit-color
            background-on-hover
            :to="link()"
          >
            {{ feedEntry.goal.title }}
          </m-link>
        </strong>
      </template>
      <template
        v-if="[feedEntryType.goalPropertyValueReferenced].includes(feedEntry.type)"
        #propertyTitle
      >
        <template v-if="feedEntry.propertyValue === null || feedEntry.propertyValue.property === null">
          {{ $t('feedEntryTitle.genericPropertyLabel') }}
        </template>
        <strong v-else>
          {{ textByLang(feedEntry.propertyValue.property.label, userLang) }}
        </strong>
      </template>
    </i18n-t>
  </div>
</template>

<script>
import camelCase from 'lodash-es/camelCase';
import notificationFeedMixin from '@/mixins/notification-feed';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import {
  FORM_DETAILS, GOAL_DETAILS, PROFILE, UPDATES_EXPLORER,
} from '@/route-names';
import { feedEntryType, routeName } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'FeedEntryTitle',
  props: {
    feedEntry: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  data() {
    return { textByLang, camelCase, feedEntryType };
  },
  computed: {
    userLink() {
      if (this.feedEntry.actor === null) {
        return null;
      }

      return {
        name: PROFILE,
        params: { userId: this.feedEntry.actor.uid },
      };
    },
  },
  methods: {
    getUpdateTemplateId(updateTemplate) {
      if (updateTemplate === null) {
        return '';
      }

      return updateTemplate.uid;
    },
    link() {
      switch (this.feedEntry.type) {
        case feedEntryType.goalPropertyValueReferenced:
        case feedEntryType.goalComment:
        case feedEntryType.goalUpdateMention:
        case feedEntryType.goalUpdate:
        case feedEntryType.goalUpdateComment:
          if (this.feedEntry.goal === null) {
            throw new Error(`missing goal reference on feed entry: ${this.feedEntry.uid}`);
          }

          return {
            name: GOAL_DETAILS,
            params: { goalId: this.feedEntry.goal.uid },
          };
        case feedEntryType.survey:
          if (this.feedEntry.form === null) {
            throw new Error(`missing form reference on feed entry: ${this.feedEntry.uid}`);
          }

          return {
            name: FORM_DETAILS,
            params: { id: this.feedEntry.form.uid },
          };
        case feedEntryType.updateNotification:
          if (this.feedEntry.notification === null) {
            throw new Error(`missing notification reference on feed entry: ${this.feedEntry.uid}`);
          }

          if (this.feedEntry.notification.updateTemplate === null) {
            return {
              name: UPDATES_EXPLORER,
              query: {
                create: '',
                notificationId: this.feedEntry.notification.uid,
              },
            };
          }

          return {
            name: UPDATES_EXPLORER,
            query: {
              create: '',
              templateId: this.getUpdateTemplateId(this.feedEntry.notification.updateTemplate),
              notificationId: this.feedEntry.notification.uid,
            },
          };
        case feedEntryType.updateMention:
        case feedEntryType.updateComment:
          return {
            name: routeName.updateDetails,
            params: { id: this.feedEntry.update.uid },
          };
        default:
          return null;
      }
    },
  },
  mixins: [notificationFeedMixin],
};
</script>

/* eslint-disable max-len */
export default {
  error: {
    default: 'An error occurred',
    unauthorized: 'You don\'t have access to view this site.',
    unauthorizedAction: 'You don\'t have the appropriate access rights for this action.',
    userExists: 'User already exists',
    sessionExpired: 'Your session has expired. Please login again.',
    emailNotRegistered: 'This email is not registered.',
    emailNotMatching: 'The emails do not match.',
    duringCopying: 'Error during copying',
    emailAlreadyExists: 'The provided email has already joined another account. The user needs to me removed from the other account before you can add them here. Our customer support can help you resolve this situation.',
    invalidDateFormat: 'Date format is not valid',
    invalidDateRange: 'Date range is not valid',
    dateOutOfRange: 'Time is outside the allowed period',
    notAnImage: 'Only images are allowed.',
    pdfExport: 'PDF export failed. Please try again.',
  },
  success: {
    created: 'Successfully created',
    updated: 'Successfully updated',
    added: 'Successfully added',
    deleted: 'Successfully deleted',
    archived: 'Successfully archived',
    restored: 'Successfully restored',
    movedToTrash: 'Moved to trash',
    saved: 'Successfully saved',
    duplicated: 'Successfully duplicated',
    copied: 'Successfully copied',
    sent: 'Successfully sent',
  },
  general: {
    none: 'None',
    cancel: 'Cancel',
    okay: 'Okay',
    archive: 'Archive',
    delete: 'Delete',
    deletePermanently: 'Delete permanently',
    restore: 'Restore',
    search: 'Search',
    typeToSearch: 'Type to search...',
    add: 'Add',
    create: 'Create',
    update: 'Update',
    save: 'Save',
    saved: 'Saved',
    submit: 'Submit',
    invite: 'Invite',
    send: 'Send',
    edit: 'Edit',
    apply: 'Apply',
    view: 'View',
    back: 'Back',
    close: 'Close',
    empty: 'Empty',
    reset: 'Reset',
    email: 'Email',
    workMail: 'Work email',
    password: 'Password',
    and: 'and',
    or: 'or',
    retry: 'Retry',
    settings: 'Settings',
    change: 'Change',
    preview: 'Preview',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    share: 'Share',
    public: 'Public',
    private: 'Private',
    custom: 'Custom',
    loadMore: 'Load more',
    Settings: 'Settings',
    yesDelete: 'Yes, delete',
    yesRemove: 'Yes, remove',
    yesUpdate: 'Yes, update',
    yesDisconnect: 'Yes, disconnect',
    yesDiscard: 'Yes, discard',
    new: 'New',
    show: 'Show',
    remove: 'Remove',
    feed: 'Feed',
    list: 'List',
    createdAt: 'Created at',
    creator: 'Creator',
    title: 'Title',
    actions: 'Actions',
    all: 'All',
    duplicate: 'Duplicate',
    moreActions: 'More actions',
    download: 'Download',
    notFound: 'Not found',
    deletePrompt: 'Are you sure?',
    emptyList: 'No elements available',
    noEntries: 'No entries yet',
    open: 'Open',
    hide: 'Hide',
    select: 'Select',
    discardChanges: 'Discard changes',
    skip: 'Skip',
    discardEditPrompt: 'Do you want to discard your unsaved changes?',
    untitled: 'Untitled',
    loading: 'loading',
    value: 'Value',
    copy: 'Copy',
    learnMore: 'Learn more',
    supportEmail: 'support{\'@\'}mooncamp.com',
    websiteBaseUrl: 'https://mooncamp.com',
    helpCenterBaseUrl: 'https://help.mooncamp.com/en',
    default: 'Default',
    saveAsPDF: 'Save as PDF',
    exporting: 'Exporting...',
  },
  validation: {
    invalidEmail: 'This is not a valid email',
    required: 'This value is required',
    notEmpty: 'This value cannot be empty',
    minLengthEightChars: 'Minimum of 8 characters',
    textTooLong: 'This text is too long',
    oneUserNeeded: 'Please choose at least one user',
    biggerThanZero: 'Must be greater than 0',
    lessThan50Chars: 'Must be less than 50 characters',
  },
  submitArea: { send: 'Submit my answer' },
  successView: {
    goToHomescreen: 'Back to the app',
    thankYou: 'Thank you for your feedback!',
  },
  form: {
    noItems: 'No questions available',
    continue: 'Continue',
    commentLabel: 'Comment (optional)',
    required: 'This is a required question',
  },
  welcomeScreen: { start: 'Start the Survey' },
  formFooter: {
    from: 'from',
    answered: 'answered',
  },
  user: {
    bot: {
      firstName: 'Mooncamp',
      lastName: 'Bot',
      name: '@:{\'user.bot.firstName\'} @:{\'user.bot.lastName\'}',
    },
    deleted: {
      firstName: 'Deleted',
      lastName: 'user',
      name: '@:{\'user.deleted.firstName\'} @:{\'user.deleted.lastName\'}',
    },
  },
  userPicker: {
    placeholder: 'Search Name',
    noData: 'No users found',
    description: 'Pick a person',
  },
  page: { addEmoji: 'Add icon' },
  mSelect: {
    pickAnItem: 'Pick an item',
    searchPlaceholder: 'Search for an option...',
    noItems: 'No items available',
    placeholder: 'Empty',
    title: 'Pick an item',
    create: 'Create',
    more: 'more',
  },
  timeZonePicker: {
    timeZone: 'Time zone',
    placeholder: 'Search for a time zone...',
    prompt: 'Select a time zone',
  },
  mDatePicker: {
    title: 'Pick a date',
    timePicker: 'Time included',
    startDate: 'Start date',
    endDate: 'End date',
    empty: 'Empty',
    month: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    monthShort: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    },
    weekdays: {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday',
    },
  },
  mUrlField: { openLink: 'Open link', copyLink: 'Copy link' },
  mTable: {
    search: 'Enter {title}...',
    noData: 'No data available',
    count: 'Count',
  },
  actions: {
    drag: 'Drag',
    toRealign: ' to move',
    click: 'Click',
    toSeeActions: ' to see actions',
  },
};

import { UID } from 'shared/api/query/constants';
import {
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
} from 'shared/api/query/configs.json';

export const gridPageTileChildren = [
  { attr: UID },
  { attr: gridPageTileConfig.edges.columns },
  { attr: gridPageTileConfig.edges.type },
  { attr: gridPageTileConfig.edges.title, default: '' },
  { attr: gridPageTileConfig.edges.description, default: '' },
  { attr: gridPageTileConfig.edges.hideBorder },
  { attr: gridPageTileConfig.edges.gridPageRow, model: gridPageRowConfig.model, children: [{ attr: UID }] },
];

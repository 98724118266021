<template>
  <sign-in :title="$t('setPassword.header')">
    <set-password-form />
  </sign-in>
</template>

<script>
import SetPasswordForm from '@/components/SetPasswordForm.vue';
import SignIn from '@/components/SignIn.vue';

export default {
  name: 'SetPassword',
  components: { SetPasswordForm, SignIn },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>

import mutations from '@/nebula/mutations';
import schema from '@/nebula/schema';
import { buildReferenceTable } from '@/nebula/references';
import { initState } from '@/nebula/initial-state';

export const initNebula = () => {
  buildReferenceTable(schema);

  return {
    namespaced: true,
    state: initState(schema),
    mutations,
  };
};

<template>
  <m-card-item
    v-if="showSort"
    icon="arrow-up-thin"
    @click="handleClickAction('sort', false)"
  >
    {{ $t('tableHeaderMenuCell.sortAscending') }}
  </m-card-item>
  <m-card-item
    v-if="showSort"
    icon="arrow-down-thin"
    @click="handleClickAction('sort', true)"
  >
    {{ $t('tableHeaderMenuCell.sortDescending') }}
  </m-card-item>
  <m-card-item
    v-if="showFilter"
    icon="funnel"
    @click="handleClickAction('filter')"
  >
    {{ $t('tableHeaderMenuCell.filter') }}
  </m-card-item>
  <m-card-item
    v-if="showHide"
    icon="eyeInvisible"
    @click="handleClickAction('hide')"
  >
    {{ $t('tableHeaderMenuCell.hideInView') }}
  </m-card-item>
</template>
<script setup>

import { computed } from 'vue';

const props = defineProps({
  actions: {
    type: Array,
    default: () => ['sort', 'filter', 'hide'],
  },
});

const emit = defineEmits(['click-action']);

const showSort = computed(() => props.actions.includes('sort'));
const showFilter = computed(() => props.actions.includes('filter'));
const showHide = computed(() => props.actions.includes('hide'));

const handleClickAction = (action, input = undefined) => {
  emit('click-action', { action, input });
};
</script>

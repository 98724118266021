<template>
  <div
    :class="['cascade-table-row', selected ? '-selected': '', selectedSecondary ? '-selected-secondary' : '', disabled ? '-disabled' : '', inlineEditingMode ? '-inline-edit-mode' : '', dragging ? '-dragging' : '', entity.isParent ? '-is-parent' : '', canEditInline ? '-can-edit-inline' : '']"
    @click="handleRowClick"
  >
    <div
      v-if="showDragOverTopTargets"
      class="_top-alignment"
      :style="topDragTargetStyle"
    >
      <div
        class="_bar"
        :style="{ backgroundColor: $colors.blue.lighten1 }"
      />
    </div>
    <div class="_content">
      <div
        v-for="column in props"
        :key="column.key"
        :class="['_item', column.hideBorder ? '-hide-border' : '', column.isTitle ? '-is-title' : '', Object.keys(errorMessages).length > 0 ? '-has-error' : '', canEditCell(column) ? '-can-edit-cell' : '']"
        :style="columnStyle(column)"
        @click="focusInput(column)"
      >
        <div
          v-if="column.isCheckbox"
          class="_checkbox"
        >
          <m-checkbox
            v-if="!disabled"
            :value="selected"
          />
        </div>
        <template v-else-if="column.isTitle">
          <m-dropdown
            :value="inlineEditingMode"
            placement="onTopLeft"
            match-trigger-width
            block
            :relocate-key="localTitle"
            :title="$t('cascadeTableRow.editTitle')"
            @hide="hideEditTitle"
          >
            <div
              ref="title"
              class="_title"
              :style="titleStyle(column)"
              @click.stop="handleClick"
            >
              <div
                v-for="i in indentationLevel"
                :key="i"
                class="_indentation-elements"
                :style="alignItemStyle"
              >
                <div
                  v-if="showDragOverBottomTargets && !expanded && isLastItem"
                  class="_bar -align-top"
                  :style="alignItemBarStyle(i - 1)"
                />
              </div>
              <div
                v-if="showExpand"
                class="_expand"
                @mouseenter="scheduleExpand"
                @mouseleave="cancelExpand"
              >
                <m-btn
                  hide-border
                  small
                  fab
                  :disabled="!hasChildren"
                  @mouseover="scheduleExpand"
                  @mouseleave="cancelExpand"
                  @click.stop="expand"
                >
                  <m-icon
                    :type="expandIconType"
                    :spin="loading"
                    class="_icon"
                    :style="expandIconStyle"
                  />
                </m-btn>
              </div>
              <item-title
                :title="entity.title"
                :icons="[{ value: goalTypeIcon, showBackground: true, size: 18, tooltip: goalTypeIconTooltip }, { value: icon }]"
                :wrap-title="wrapCells"
                class="_title-inner"
                :style="{ padding: '.8rem .8rem .8rem .4rem' }"
              />
              <div
                class="_title-actions"
              >
                <m-tooltip
                  v-if="canEditInline && !dragging"
                  class="_edit-btn"
                >
                  <m-btn
                    small
                    fab
                    light
                    hide-border
                    icon="edit"
                    @click.stop="editTitle"
                  />
                  <template #title>
                    {{ $t('cascadeTableRow.editTitle') }}
                  </template>
                </m-tooltip>
                <error-indicator-dropdown
                  class="_error-btn"
                  :can-edit="canEditInline"
                  :error-messages="errorMessages"
                  :has-errors="hasErrors"
                  :goal="entity"
                  :update-property="updateProperty"
                  @select="$emit('select-row')"
                />
              </div>
            </div>
            <template #overlay>
              <m-card
                no-padding
                class="_overlay"
                :style="editingTitleCardStyle"
              >
                <div
                  class="_inner"
                >
                  <simple-editor
                    :initial-value="entity.title"
                    :placeholder="$t('list.addTitle')"
                    full-width
                    auto-focus
                    :read-only="false"
                    @enter="blur"
                    @escape="blur"
                    @blur="blur"
                    @update:value="updateTitle"
                  />
                </div>
              </m-card>
            </template>
          </m-dropdown>
        </template>
        <component-prop
          v-if="column.property.component !== undefined"
          :entity="entity"
          :prop="column"
          align-diff-right
          :show-progress-diff="showProgressDiff"
          :read-only="readOnly"
          :style="{ padding: '0 .8rem', marginTop: '1rem' }"
          progress-bar-width="6rem"
          :progress-display-option="progressDisplayOption"
          @click="emitClick(column, entity)"
        />
        <div
          v-else-if="column.property.type === propertyType.status"
          :style="{ display: 'flex' }"
        >
          <status-prop
            :style="{ padding: '0 .8rem', marginTop: '.8rem' }"
            :goal="entity"
            @click="emitClick(column, entity)"
          />
          <status-auto-update-hint
            v-if="entity.disableStatusAutoUpdate && entity.progressMeasurement === alignedItems"
            :goal="entity"
            :style="{ marginTop: '.8rem' }"
          />
        </div>
        <goal-property-form-item
          v-else-if="!column.isTitle"
          :ref="`${column.key}`"
          :property="column.property"
          :goal-picker-service="{}"
          :update-properties="(val) => updateProp(val, column.property)"
          :wrap="wrapCells"
          popup
          hide-hover
          hide-border
          hide-placeholder
          :goal="entity"
          :read-only="readOnly || !canEdit || showCheckbox || column.property.type === propertyType.goalRelation"
          @blur="$emit('select-row')"
        />
      </div>
      <div
        v-if="hasEmptyRow"
        class="_empty-row"
      />
      <div
        :style="propertyColumnStyle"
      />
    </div>
    <div
      v-if="showDragOverBottomTargets && (!expanded || !hasChildren)"
      class="_bottom-alignment"
      :style="bottomDragTargetStyle"
    >
      <div
        class="_bar"
        :style="{ backgroundColor: $colors.blue.lighten1 }"
      />
    </div>
  </div>
</template>

<script>
import ComponentProp from '@/components/list/ComponentProp.vue';
import ErrorIndicatorDropdown from '@/components/goal/ErrorIndicatorDropdown.vue';
import GoalPropertyFormItem from '@/components/goal/GoalPropertyFormItem.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import StatusAutoUpdateHint from '@/components/goal/StatusAutoUpdateHint.vue';
import StatusProp from '@/components/property/StatusProp.vue';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useInlineEditing from '@/composables/inline-editing';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { CREATED_AT } from 'shared/api/query/constants';
import { GOAL_RELATION, TIME_DIFF } from '@/lib/props/custom-types';
import { INDENTATION_WIDTH } from 'shared/constants';
import { accessPolicyType, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'CascadeTableRow',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    identifier: {
      type: [String, Number],
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    indentationLevel: {
      type: Number,
      default: 0,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    dragDepth: {
      type: Number,
      default: 0,
    },
    isLastItem: {
      type: Boolean,
      default: false,
    },
    showDragOverTopTargets: {
      type: Boolean,
      default: false,
    },
    showDragOverBottomTargets: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    hasEmptyRow: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedSecondary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    wrapCells: {
      type: Boolean,
      default: false,
    },
    inlineEditable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Object,
      default: () => ({}),
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    fillerColumnWidth: {
      type: Number,
      default: 40,
    },
    progressDisplayOption: { type: String, default: undefined },
  },
  setup(props) {
    const { goalTypeIcon, goalTypeOption } = useGoalTypeProperty();
    const { userLang } = useLoggedInUser();
    const { updateSingle } = useGoals();

    const inlineEditingSvc = useInlineEditing();

    const isInlineEditing = computed(() => inlineEditingSvc.isInlineEditing(props.identifier).value);

    return {
      userLang,
      goalTypeIconFn: goalTypeIcon,
      goalTypeOption,
      isInlineEditing,
      inlineEditingSvc,
      updateSingle,
    };
  },
  emits: ['expand-clicked', 'schedule-expand', 'cancel-expand', 'option-clicked', 'arrow-down', 'select-row', 'select', 'prop-clicked', 'click'],
  components: {
    StatusAutoUpdateHint,
    ErrorIndicatorDropdown,
    GoalPropertyFormItem,
    ComponentProp,
    ItemTitle,
    SimpleEditor,
    StatusProp,
  },
  data() {
    return {
      propertyType: { ...propertyType, goalRelation: GOAL_RELATION },
      TIME_DIFF,
      localTitle: '',
      focusedColumn: '',
      alignedItems: goalProgressMeasurement.alignedItems,
    };
  },
  computed: {
    goalTypeIcon() {
      return this.goalTypeIconFn(this.entity);
    },
    goalTypeIconTooltip() {
      const type = this.goalTypeOption(this.entity);
      if (type === null) {
        return '';
      }
      return textByLang(type.label, this.userLang);
    },
    topDragTargetStyle() {
      return {
        position: 'absolute',
        top: '-2px',
        left: 0,
        height: '4px',
        width: `calc(100% - ${this.indentationLevel * INDENTATION_WIDTH}px)`,
        marginLeft: `${this.indentationLevel * 4}rem`,
      };
    },
    bottomDragTargetStyle() {
      return {
        position: 'absolute',
        bottom: '-2px',
        left: 0,
        height: '4px',
        width: `calc(100% - ${this.indentationLevel * INDENTATION_WIDTH}px)`,
        marginLeft: `${this.indentationLevel * 4}rem`,
      };
    },
    alignItemStyle() {
      return {
        minWidth: `${INDENTATION_WIDTH}px`,
        width: `${INDENTATION_WIDTH}px`,
        height: '100%',
        position: 'relative',
      };
    },
    editingTitleCardStyle() {
      if (this.$refs.title) {
        return { minHeight: `${this.$refs.title.offsetHeight}px` };
      }
      return {};
    },
    hasErrors() {
      return Object.keys(this.errorMessages).length > 0;
    },
    canEdit() {
      return !this.readOnly && [accessPolicyType.full, accessPolicyType.write].includes(this.entity.accessRight);
    },
    canEditInline() {
      return this.canEdit && this.inlineEditable;
    },
    inlineEditingMode() {
      return this.focused && this.isInlineEditing;
    },
    expandIconStyle() {
      if (!this.expanded) {
        return { marginLeft: '.2rem' };
      }
      return '';
    },
    expandIconType() {
      if (!this.hasChildren) {
        return 'dot';
      }
      if (this.loading) {
        return 'loading';
      }
      if (this.expanded) {
        return 'chevron-down';
      }
      return 'chevron-right';
    },
    icon() {
      return buildIconFromEntity(this.entity);
    },
    propertyColumnStyle() {
      return { flex: `0 0 ${this.fillerColumnWidth}px`, width: `${this.fillerColumnWidth}px` };
    },
  },
  methods: {
    canEditCell(column) {
      if (!this.canEditInline) {
        return false;
      }

      if (column.property.type === this.propertyType.lookup
          || column.property.type === this.propertyType.goalRelation
          || column.key === goalConfig.edges.cachedLastUpdatedAt
          || column.key === CREATED_AT
      ) {
        return false;
      }
      return !(this.entity.progressMeasurement === goalProgressMeasurement.none
          && ([this.propertyType.status].includes(column.property.type) || column.key === goalConfig.edges.cachedCalculatedCurrent));
    },
    focusInput(column) {
      this.focusedColumn = '';
      if (column.property.type === this.propertyType.goalRelation) {
        return;
      }
      if (column.property.component !== undefined) {
        this.emitClick(column, this.entity);
        return;
      }
      if (column.property.type === this.propertyType.lookup) {
        this.handleClick();
        return;
      }
      if (column.property.type === this.propertyType.status) {
        this.emitClick(column, this.entity);
        return;
      }
      if (this.$refs[`${column.key}`][0] === undefined) {
        return;
      }
      this.$refs[`${column.key}`][0].focus();
    },
    updateProp(value, property) {
      this.updateProperty(value, property, this.entity);
    },
    expand() {
      this.$emit('expand-clicked');
    },
    scheduleExpand() {
      if (this.dragging) {
        this.$emit('schedule-expand');
      }
    },
    cancelExpand() {
      this.$emit('cancel-expand');
    },
    alignItemBarStyle(v) {
      if (this.dragDepth <= v) {
        return { backgroundColor: this.$colors.blue.lighten1 };
      }

      return { backgroundColor: 'transparent' };
    },
    titleStyle(column) {
      return { width: `${column.width}px` };
    },
    editTitle() {
      if (this.showCheckbox || this.readOnly) {
        this.$emit('click', this.entity);
        return;
      }
      this.inlineEditingSvc.set(this.identifier);
    },
    updateTitle(val) {
      this.localTitle = val;
    },
    hideEditTitle() {
      this.saveTitle();
      this.$emit('select-row');
    },
    saveTitle() {
      if (this.entity.title === this.localTitle) {
        return;
      }
      this.updateSingle({ uid: this.entity.uid, title: this.localTitle }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    blur() {
      this.inlineEditingSvc.reset();
    },
    columnStyle(column) {
      return { flex: `0 0 ${column.width}px`, width: `${column.width}px` };
    },
    handleRowClick() {
      if (!this.canEdit || this.showCheckbox) {
        this.$emit('click', this.entity);
      }
    },
    handleClick() {
      if (this.disabled) {
        return;
      }
      this.$emit('click', this.entity);
    },
    emitClick(prop, entity) {
      if (!this.canEdit || this.showCheckbox) {
        return;
      }
      this.$emit('prop-clicked', prop, entity);
    },
  },
  watch: {
    inlineEditingMode(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.localTitle = this.entity.title;
      }
    },
  },
  created() {
    this.localTitle = this.entity.title;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  $height: 4rem;

  .cascade-table-row {
    position: relative;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid $border-color;

    ._bar {
      width: 100%;
      height: 100%;

      &.-align-top {
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 4px;
      }
    }

    ._content {
      display: flex;
      align-items: center;
      min-width: 100%;
      min-height: $height;

      ._checkbox {
        display: none;
        flex: 0 0 3rem;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
      }

      ._empty-row {
        flex: 1 0 $height;
        min-width: $height;
      }

      ._item {
        position: relative;
        display: flex;
        align-items: flex-start;
        height: 100%;
        min-height: inherit;
        border-right: 1px solid $border-color;

        &:not(.-is-title) {
          overflow: hidden;
        }

        ._title {
          display: flex;
          align-items: flex-start;
          min-height: 4rem;

          ._title-inner {
            font-weight: $font-weight-medium;
            line-height: 1.6;
          }

          ._expand {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 2.8rem;
            margin-top: .6rem;
            margin-left: .6rem;
            overflow: hidden;

            ._icon {
              color: $font-color-secondary;
            }
          }

          ._title-actions {
            position: absolute;
            top: .6rem;
            right: .8rem;
            display: flex;
            align-items: center;

            ._edit-btn {
              display: none;
              background-color: white;
              border-radius: $default-border-radius;

              @include box_shadow(1);
            }

            ._error-btn {
              background-color: map_get($red, 'lighten-5');
              border-radius: $default-border-radius;
              margin-left: .4rem;
            }
          }

          &:hover {
            ._title-actions {
              ._error-btn {
                @include box_shadow(1);
              }

              @media (min-width: $screen-size-md) {
                ._edit-btn {
                  display: flex;
                }
              }
            }
          }
        }

        &:hover {
          &.-can-edit-cell {
            background-color: $hover-color;
          }
        }

        &.-is-title {
          align-items: stretch;

          &.-has-error {
            ._title {
              padding-right: 6rem;
            }
          }
        }

        &.-hide-border {
          border-right: none;
        }

        &:last-of-type {
          border-right: none;
        }

        &:first-of-type {
          &:not(.-is-title) {
            padding-left: .6rem;
          }
        }
      }
    }

    &.-disabled {
      cursor: default;
      opacity: .5;
    }

    &:hover {
      &:not(.-can-edit-inline) {
        background-color: $hover-color;
      }

      ._checkbox {
        display: flex;
      }
    }

    &.-selected-secondary {
      &:not(.-inline-edit-mode) {
        ._content {
          background-color: $highlighted-color-light;
        }
      }
    }

    &.-selected {
      &:not(.-inline-edit-mode) {
        ._content {
          background-color: $highlighted-color;
        }

        ._checkbox {
          display: flex;
        }
      }
    }
  }

  ._overlay {
    display: flex;
    align-items: center;
    min-height: 4rem;

    ._inner {
      display: flex;
      align-items: center;
      padding: .8rem;
      line-height: 1.6;

      ._overlay-text {
        &:empty::before {
          color: $font-color-tertiary;
          content: attr(data-placeholder);
        }

        width: 100%;

        &:focus {
          outline: none;
        }
      }

      ._placeholder {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 4rem;
        color: $font-color-tertiary;
        pointer-events: none;
      }
    }
  }
</style>

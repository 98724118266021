<template>
  <div class="ms-power-bi-form">
    <m-content
      v-if="hasError"
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <m-alert
        class="_error-message"
        type="error"
        :message="errorMessage"
      />
    </m-content>
    <template v-if="!hasConnectionError">
      <m-content
        :padding-top="0"
        :padding-x="11"
        :padding-bottom="2"
      >
        <div class="_item">
          <div class="_label">
            {{ $t('msPowerBIForm.selectDatasetLabel') }}
          </div>
          <m-select
            v-model:value="localQuery.datasetId"
            :items="dataSource[customDatasourceProperty.msPowerBiDatasets]"
            value-key="id"
            item-text="name"
            full-width
            match-trigger-width
            show-search
          />
        </div>
        <template
          v-if="localQuery.datasetId !== null && dataSource[customDatasourceProperty.msPowerBiDatasets].length > 0"
        >
          <div class="_item">
            <div class="_label">
              {{ $t('msPowerBIForm.daxQueryLabel') }}
            </div>
            <m-textarea
              v-model:value="localQuery.dax"
              :rows="5"
              full-width
              :placeholder="$t('msPowerBIForm.daxQueryPlaceholder')"
            />
          </div>
        </template>
        <div
          v-if="localQuery.datasetId !== null"
          class="_item"
          :style="{ marginTop: '2rem' }"
        >
          <always-sync-checkbox v-model:value="localQuery.alwaysSync" />
        </div>
      </m-content>
      <m-divider xs />
      <template v-if="localQuery.uid === undefined">
        <m-content padding-small>
          <div class="_actions">
            <div class="_btns">
              <m-btn
                class="_btn"
                @click="$emit('close')"
              >
                {{ $t('general.cancel') }}
              </m-btn>
              <m-btn
                color="primary"
                class="_btn"
                :loading="mutateLoading"
                :disabled="!isValid"
                @click="save"
              >
                {{ $t('general.save') }}
              </m-btn>
            </div>
          </div>
        </m-content>
      </template>
      <template v-else>
        <m-card-item
          icon="save"
          :loading="mutateLoading"
          :padding-x="8"
          :disabled="!isValid"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-card-item>
        <m-card-item
          icon="sync"
          :loading="syncLoading"
          :padding-x="8"
          :disabled="!isValid || hasError"
          @click="syncNow"
        >
          {{ $t('dataSource.syncNow') }}
        </m-card-item>
        <m-card-item
          icon="delete"
          :padding-x="8"
          :loading="deleteLoading"
          @click="deleteMsPowerBIQuery"
        >
          {{ $t('general.delete') }}
        </m-card-item>
      </template>
    </template>
  </div>
</template>

<script setup>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import useConfirmDialog from '@/composables/confirm-dialog';
import useMSPowerBI from '@/composables/integrations/ms-powerbi/ms-powerbi';
import useSnackBar from '@/composables/snackbar';
import { computed, ref } from 'vue';
import { copy } from 'shared/lib/copy';
import { customDatasourceProperty } from 'shared/constants.json';
import { isEmpty } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const snackbar = useSnackBar();

const props = defineProps({
  dataSource: {
    type: Object,
    required: true,
  },
  query: {
    type: Object,
    default: () => null,
  },
  goal: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['close', 'deleted']);

const {
  createQuery,
  createQueryLoading,
  updateQuery,
  updateQueryLoading,
  deleteQuery,
  deleteQueryLoading: deleteLoading,
} = useMSPowerBI();
const mutateLoading = computed(() => createQueryLoading.value || updateQueryLoading.value);

const syncLoading = ref(false);
const defaultMSPowerBIQuery = () => ({
  datasetId: null,
  datasetName: null,
  dax: '',
  error: null,
});

const localQuery = ref(defaultMSPowerBIQuery());
if (props.query !== null) {
  localQuery.value = copy(props.query);
}

const hasConnectionError = computed(() => props.dataSource[customDatasourceProperty.msPowerBiError] !== '');
const hasError = computed(() => hasConnectionError.value || !isEmpty(localQuery.value.error));
const errorMessage = computed(() => {
  if (props.dataSource[customDatasourceProperty.msPowerBiError] !== '') {
    return t(`msPowerBI.error.${props.dataSource[customDatasourceProperty.msPowerBiError]}`);
  }

  if (!isEmpty(localQuery.value.error)) {
    return t(`msPowerBI.error.${localQuery.value.error}`);
  }

  return '';
});

const selectedDataset = computed(() => {
  if (localQuery.value.datasetId === null) {
    return null;
  }

  const dataset = props.dataSource[customDatasourceProperty.msPowerBiDatasets].find((r) => r.id === localQuery.value.datasetId);
  if (dataset === undefined) {
    return null;
  }

  return dataset;
});

const isDAXValid = computed(() => localQuery.value.dax.startsWith('EVALUATE'));
const isValid = computed(() => selectedDataset.value !== null && isDAXValid.value);

const save = () => {
  if (typeof localQuery.value.uid === 'undefined') {
    createMsPowerBIQuery(localQuery.value);
    return;
  }

  updateMsPowerBIQuery(localQuery.value);
};
const createMsPowerBIQuery = (query) => {
  createQuery({
    ...query,
    datasetName: selectedDataset.value.name,
    dataSource: { uid: props.dataSource.uid },
    goal: { uid: props.goal.uid },
  }).then((query) => {
    localQuery.value = copy(query);
  }).catch(logCatch(() => {
    snackbar.error();
  }));
};
const updateMsPowerBIQuery = (query) => {
  updateQuery({
    ...query,
    datasetName: selectedDataset.value.name,
    dataSource: { uid: props.dataSource.uid },
  }, { ignoreResponse: false }).then((query) => {
    localQuery.value = copy(query);
  }).catch(logCatch(() => {
    snackbar.error();
  }));
};
const syncNow = () => {
  syncLoading.value = true;
  updateQuery(props.goal.msPowerBiQuery).then((query) => {
    localQuery.value = copy(query);
    snackbar.success(t('dataSource.successfullySynced'));
  }, { ignoreResponse: false }).catch(logCatch(() => {
    snackbar.error();
  })).finally(() => {
    syncLoading.value = false;
  });
};
const confirmDialog = useConfirmDialog();
const deleteMsPowerBIQuery = () => {
  const deleteMethod = () => deleteQuery(props.goal.msPowerBiQuery.uid).then(() => {
    emit('deleted');
    emit('close');
  }).catch(() => {
    snackbar.error();
  });

  confirmDialog.confirm({
    title: t('dataSource.deleteConnectionPrompt'),
    onOk() {
      deleteMethod();
    },
  });
};
</script>

<style scoped lang="scss" type="text/scss">
.ms-power-bi-form {
  ._item {
    margin-bottom: 1.4rem;

    ._label {
      margin-bottom: .4rem;
      color: $font-color-secondary;
    }
  }

  ._actions {
    display: flex;

    ._btns {
      display: flex;
      margin-left: auto;

      ._btn {
        margin-left: .4rem;
      }
    }
  }
}
</style>

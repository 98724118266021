<template>
  <div class="dashboard-card-header">
    <m-tooltip
      :style="{ width: 'calc(100% - 3rem)' }"
      :mouse-enter-delay="1"
    >
      <div class="_title">
        {{ title }}
      </div>
      <template #title>
        {{ title }}
      </template>
    </m-tooltip>
    <m-dropdown
      v-if="!isPrint"
      class="_trigger"
      :title="$t('goalInsightsHealth.goToSettings')"
      placement="bottom"
      :disabled="loading"
    >
      <m-btn
        icon="infoCircle"
        fab
        small
        light
        hide-border
      />
      <template #overlay>
        <m-card
          list
          class="_overlay"
        >
          <m-content
            padding-xs
            class="_text"
          >
            <i18n-t
              :keypath="textKey"
              tag="div"
            >
              <template #settingsTitle>
                <span class="_settings-title">{{ $t('settingsNavigation.title') }} / {{ $t('settingsNavigation.goalTypes') }}</span>
              </template>
            </i18n-t>
          </m-content>
          <template v-if="canEdit">
            <m-divider xxs />
            <m-link
              :to="link"
            >
              <m-card-item
                icon="settings"
              >
                {{ $t('goalInsightsHealth.goToSettings') }}
              </m-card-item>
            </m-link>
          </template>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import useExport from '@/composables/export/export';
import { routeName } from 'shared/constants.json';

export default {
  name: 'DashboardCardHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    textKey: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => ({ name: routeName.goalTypes }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isPrint } = useExport();
    return { isPrint };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";

  .dashboard-card-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._title {
      margin-right: auto;
      overflow: hidden;
      font-size: $font-size-5;
      font-weight: $font-weight-medium;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ._overlay {
    width: 25rem;

    ._text {
      font-size: $font-size-4;
    }

    ._settings-title {
      font-weight: $font-weight-semibold;
    }
  }
</style>

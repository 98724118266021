import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { comment as commentConfig } from 'shared/api/query/configs.json';
import { computed } from 'vue';
import { extractMentions } from '@/lib/mention/mention';

export default function useComments() {
  const repo = useRepo(commentConfig.model);
  const { loggedInUser } = useLoggedInUser();

  const comments = ({ updateId }) => {
    if (typeof updateId !== 'undefined') {
      return computed(() => repo.entityList.value.filter((c) => c.update !== null && typeof c.update !== 'undefined' && c.update.uid === updateId));
    }
    return repo.entityList;
  };

  const createComment = ({ message, update }) => repo.createSingle({
    creator: { uid: loggedInUser.value.uid },
    message,
    update,
    mentions: extractMentions(message),
  }).then((comment) => {
    EventBus.$emit(EVENTS.INTERACTION.COMMENT_CREATED);
    return comment;
  });

  const updateComment = (comment) => repo.updateSingle({
    ...comment,
    mentions: extractMentions(comment.message),
  });

  return {
    comments,
    createCommentLoading: repo.createLoading,
    createComment,
    updateCommentLoading: repo.updateLoading,
    updateComment,
    deleteCommentLoading: repo.deleteLoading,
    deleteComment: repo.deleteSingle,
  };
}

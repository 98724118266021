<template>
  <div
    class="goal-list-item"
    :data-id="goal.uid"
  >
    <list-item
      :entity="goal"
      :props="props"
      :has-children="hasChildren"
      :selected="selected"
      :selected-secondary="selectedSecondary"
      :read-only="readOnly"
      :show-progress-diff="showProgressDiff"
      :progress-display-option="progressDisplayOption"
      @select="openInModal"
      @prop-clicked="openInModal"
      @contextmenu="handleContextMenuClick"
    />
    <goal-context-menu
      ref="contextMenu"
      :goal="goal"
      :create-loading="createLoading"
      :can-create="canCreate"
      @goals-duplicated="$emit('goals-duplicated')"
      @create-beneath="$emit('create', $event)"
    />
    <m-dialog
      v-model:value="showUpdateProgressModal"
      hide-footer
      :center="$store.state.breakpoint.smAndDown"
      :fullscreen-on-mobile="false"
      :body-style="{ overflow: 'visible' }"
      :max-width="$modalSizes.lg"
    >
      <goal-update-editor
        :key="goal.uid"
        :goal="goal"
        :goal-children="[goal]"
        allow-goal-activity
        auto-add-goal-activity
        @created="showUpdateProgressModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import GoalContextMenu from '@/components/goal/GoalContextMenu.vue';
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';
import ListItem from '@/components/list/ListItem.vue';
import useGoal from '@/composables/goal/goal';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { toRef } from 'vue';

export default {
  name: 'GoalListItem',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedSecondary: {
      type: Boolean,
      default: false,
    },
    selectedGoalIds: {
      type: Array,
      required: true,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    progressDisplayOption: { type: String, default: undefined },
  },
  emits: ['goals-duplicated', 'create', 'selection-right-click', 'select-row'],
  setup(props) {
    const { selectMultiple: selectGoal } = useGoal();
    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));
    return {
      selectGoal,
      peekModeSvc,
    };
  },
  components: { ListItem, GoalUpdateEditor, GoalContextMenu },
  data() {
    return {
      showUpdateProgressModal: false,
      childrenLoaded: false,
    };
  },
  computed: {
    hasChildren() {
      return this.goal.children.length > 0;
    },
    children() {
      const ids = this.goal.children.map((g) => g.uid);
      return this.selectGoal(ids);
    },
  },
  methods: {
    handleContextMenuClick(event) {
      event.preventDefault();
      if (this.selectedGoalIds.length > 0 && this.selectedGoalIds.includes(this.goal.uid)) {
        this.$emit('selection-right-click', event);
        return;
      }

      this.$emit('select-row', { goal: this.goal });
      this.$refs.contextMenu.show(event);
    },
    openInModal() {
      this.peekModeSvc.openGoal({ goalId: this.goal.uid });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-list-item {
    ._children {
      margin-left: 2rem;

      ._item {
        padding: .2rem 0;
      }
    }
  }
</style>

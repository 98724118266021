/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { RESULT } from 'shared/api/query/constants';
import {
  addOrUpdate, addOrUpdateObjectInArray, addOrUpdateObjectsInArray,
  removeObjectInArray, removeObjectsInArray, updateObjectInArray,
} from 'shared/lib/array/write';
import { copy } from 'shared/lib/copy';
import { findIndexArray } from 'shared/lib/array/array';
import { getDefaultState } from '@/store/helper';
import { user as userConfig } from 'shared/api/query/configs.json';
import {
  userScopeOperator,
} from 'shared/constants.json';

const vset = (onto, prop, val) => {
  onto[prop] = val;
};

export default {
  USER_NOT_LOGGED_IN(state) {
    state.isLoggedIn = false;
    state.loggedInUserId = 0;
  },
  ENABLE_MAINTENANCE_MODE(state) {
    state.maintenanceMode = true;
  },
  LOGGED_IN_USER_RETRIEVED(state, { userId }) {
    state.isLoggedIn = true;
    state.loggedInUserId = userId;
  },
  PUBLISHED_VIEW_DATA(state) {
    state.loggedInUserId = Object.keys(state.nebula[`${userConfig.model}`])[0];
  },
  RESET_STATE(state) {
    Object.assign(state, {
      ...getDefaultState(),
      appVersion: state.appVersion,
      semverVersion: state.semverVersion,
      clientInfo: state.clientInfo,
      registerURLs: state.registerURLs,
      publicAccountData: state.publicAccountData,
    });
    localStorage.clear();
  },
  SET(state, { key, value }) {
    state[key] = value;
  },
  FORMS_RETRIEVED(state, { forms }) {
    state.forms = forms;
  },
  FORM_CREATED(state, { form }) {
    state.forms.push(form);
    state.form = form;
  },
  FORM_RETRIEVED(state, { form }) {
    const userScopeTree = form.analyticAccess.readScope;
    for (let i = 0; i < userScopeTree.children.length; i++) {
      if (userScopeTree.children[i].children.length === 0) {
        const cpy = copy(userScopeTree.children[i]);
        const wrappedNode = {
          op: userScopeOperator.and,
          children: [cpy],
        };
        userScopeTree.children[i] = wrappedNode;
      }
    }
    form.analyticAccess.readScope = userScopeTree;
    state.form = form;
  },
  FORM_RESULT_SET_LOADING(state, loading) {
    state.formResultLoading = loading;
  },
  FORM_RESULT_DATA_RETRIEVED(state, { data }) {
    state.formResultData = { ...state.formResultData, ...data };
  },
  FORM_RESULT_ANSWERS_RETRIEVED(state, { data }) {
    state.formResultAnswers = data;
  },
  FORM_RESULT_ANSWER_COUNT_RETRIEVED(state, { count }) {
    state.formResultAnswerCount = count;
  },
  ORDER_ITEM_CHANGED(state, { orderItem, orderItemIndex, model }) {
    state[model].orderItems.splice(
      orderItemIndex,
      1,
      {
        ...orderItem,
        field: {
          ...orderItem.field,
          properties: orderItem.field.properties,
        },
      },
    );
  },
  ORDER_ITEMS_CHANGED(state, { moved, model }) {
    state[model].orderItems.forEach((item) => {
      if (item.value === moved.oldIndex) {
        item.value = moved.newIndex;
        return;
      }
      if (item.value > moved.oldIndex && item.value > moved.newIndex) {
        return;
      }
      if (item.value < moved.oldIndex && item.value < moved.newIndex) {
        return;
      }
      if (item.value > moved.oldIndex && item.value <= moved.newIndex) {
        item.value -= 1;
      }
      if (item.value < moved.oldIndex && item.value >= moved.newIndex) {
        item.value += 1;
      }
    });
    state[model].orderItems.sort((a, b) => a.value - b.value);
  },
  RESOURCE_POLICY_UPDATED(state, { model, resourcePolicy }) {
    state[model] = {
      ...state[model],
      resourcePolicy,
    };
  },
  ENTITY_UPDATED(state, { entity, model }) {
    if (Array.isArray(state[model])) {
      state[model] = addOrUpdate(state[model], [entity]);
      return;
    }
    state[model] = entity;
  },
  ENTITY_CREATED(state, { entity, model }) {
    if (Array.isArray(state[model])) {
      state[model].push(entity);
      return;
    }
    state[model] = entity;
  },
  ENTITIES_CREATED(state, { entities, model }) {
    state[model].push(...entities);
  },
  ENTITY_DELETED(state, { entity, model }) {
    removeObjectInArray(state[model], entity);
  },
  ENTITIES_DELETED(state, { entities, model }) {
    removeObjectsInArray(state[model], entities);
  },
  ENTITY_RETRIEVED(state, { entity, model }) {
    if (Array.isArray(state[model])) {
      addOrUpdateObjectInArray(state[model], entity);
      return;
    }
    state[model] = entity;
  },
  ENTITIES_RETRIEVED(state, { entities, key }) {
    state[key] = entities;
  },
  FORM_ANALYTIC_ACCESS_UPDATED(state, form) {
    state.form = {
      ...state.form,
      analyticAccess: form.analyticAccess,
    };
  },
  FORM_TITLE_UPDATED(state, { title }) {
    state.form.title = title;
  },
  ORDER_ITEMS_ORDER_CHANGED(state, { orderItems, model }) {
    state[model].orderItems = orderItems.map((orderItem, index) => ({
      ...orderItem,
      value: index,
    }));
  },
  UPDATE_ORDER_ITEM(state, { item, model }) {
    updateObjectInArray(state[model].orderItems, item);

    if (typeof item.field.deletedAt !== 'undefined') {
      for (let i = 0; i < state[model].formFactors.length; i++) {
        updateObjectInArray(state[model].formFactors, {
          ...state[model].formFactors[i],
          factorFields: state[model].formFactors[i].factorFields.filter((f) => f.field.uid !== item.field.uid),
        });
      }
    }
  },
  WELCOME_SCREEN_DESCRIPTION_UPDATED(state, { value, lang, model }) {
    state[model].welcomeScreen.description[lang] = value;
  },
  WELCOME_SCREEN_TITLE_UPDATED(state, { value, lang, model }) {
    state[model].welcomeScreen.title[lang] = value;
  },
  WELCOME_SCREEN_DELETED(state, { model }) {
    vset(state[model].welcomeScreen, 'deletedAt', DateTime.local().toISO());
  },
  FIELD_TITLE_LANGUAGE_ADDED(state, { lang, model }) {
    state[model].orderItems.forEach((item, index) => {
      vset(state[model].orderItems[index].field.title, lang, '');
    });
    if (state[model].welcomeScreen !== null) {
      vset(state[model].welcomeScreen.title, lang, '');
      vset(state[model].welcomeScreen.description, lang, '');
    }
  },
  TEMPLATE_TAGS_CHANGED(state, { tags }) {
    vset(state.formTemplate, 'tags', tags);
  },
  UNSAVED_FORM_CHANGES_EXIST_CHANGED(state, value) {
    state.unsavedFormChangesExist = value;
  },
  UNSAVED_SURVEY_PROCESS_CHANGES_EXIST_CHANGED(state, value) {
    state.unsavedSurveyProcessChangesExist = value;
  },
  LANGUAGE_DELETED(state, { languages, model }) {
    languages.forEach((lang) => {
      state[model].orderItems.forEach((item) => {
        delete (item.field.title[lang]);
        delete (item.field.opinionScaleProperties.labelLeft[lang]);
        delete (item.field.opinionScaleProperties.labelCenter[lang]);
        delete (item.field.opinionScaleProperties.labelRight[lang]);
      });

      if (state[model].welcomeScreen === null) {
        return;
      }
      if (Object.keys(state[model].welcomeScreen).length > 0) {
        delete (state[model].welcomeScreen.title[lang]);
        delete (state[model].welcomeScreen.description[lang]);
      }
    });
  },
  UPDATE_SURVEY_PROCESS_SCHEDULE(state, { schedule }) {
    state.surveyProcess.schedule = schedule;
  },
  SURVEY_PROCESS_RETRIEVED(state, { surveyProcess }) {
    state.surveyProcess = surveyProcess;
    state.origSurveyProcess = copy(surveyProcess);
  },
  USER_SCOPE_TREE_CHANGED(state, { userScopeTree }) {
    state.surveyProcess.userScopeTree = userScopeTree;
  },
  SURVEY_PROCESS_UPDATED(state, { surveyProcess }) {
    state.surveyProcess = surveyProcess;
  },
  SURVEY_PROCESS_CREATED(state, { surveyProcess }) {
    state.surveyProcess = surveyProcess;
  },
  SURVEY_PROCESS_REMIND_AFTER_CHANGED(state, { remindAfter }) {
    state.surveyProcess.remindAfter = remindAfter;
  },
  REMINDER_COUNT_DECREASED(state) {
    state.surveyProcess.reminderCount -= 1;
  },
  REMINDER_COUNT_INCREASED(state) {
    state.surveyProcess.reminderCount += 1;
  },
  PULSE_MODE_CHANGED(state, value) {
    state.surveyProcess.pulseMode = value;
  },
  PULSE_CALCULATION_CHANGED(state, value) {
    state.surveyProcess.form = { ...state.surveyProcess.form, pulseCalculation: value };
  },
  QUESTIONS_PER_PULSE_MODE_CHANGED(state, value) {
    state.surveyProcess.questionsPerPulse = value;
  },
  FORM_FACTOR_UPDATED(state, { factor, model }) {
    updateObjectInArray(state[model].formFactors, factor);
  },
  FORM_FACTORS_ORDER_CHANGED(state, { factors, model }) {
    state[model].formFactors = factors.map((factor, index) => ({
      ...factor,
      order: index,
    }));
  },
  UPDATE_DISTRIBUTION_CHANNEL(state, value) {
    if (state.surveyProcess.distributionChannels.length === 0) {
      state.surveyProcess.distributionChannels.push(value);
      return;
    }
    state.surveyProcess.distributionChannels = state.surveyProcess.distributionChannels.map((dc) => ({
      ...dc,
      mailProperties: {
        ...dc.mailProperties,
        subject: value.mailProperties.subject,
        body: value.mailProperties.body,
      },
    }));
  },
  PRIVACY_THRESHOLD_CHANGED(state, value) {
    state.surveyProcess.form = { ...state.surveyProcess.form, privacyThreshold: value };
  },
  FORM_TEMPLATE_DELETED(state, { entity }) {
    removeObjectInArray(state.formTemplates, entity);
  },
  FORM_TEMPLATE_CREATED(state, { formTemplate }) {
    state.formTemplates.push(formTemplate);
  },
  FORM_TEMPLATES_RETRIEVED(state, { formTemplates }) {
    state.formTemplates = formTemplates;
  },
  FORM_TEMPLATE_RETRIEVED(state, { formTemplate }) {
    state.formTemplate = formTemplate;
  },
  UNSAVED_FORM_TEMPLATE_CHANGES_EXIST_CHANGED(state, value) {
    state.unsavedFormTemplateChangesExist = value;
  },
  FORM_TEMPLATE_TITLE_UPDATED(state, { title }) {
    state.formTemplate.title = title;
  },
  FORM_TEMPLATE_ICON_UPDATED(state, { icon }) {
    state.formTemplate.icon = icon;
  },
  FORM_DELETE(state, { form }) {
    state.forms = state.forms.filter((f) => f.uid !== form.uid);
  },
  REGISTER_URLS(state, { urls }) {
    state.registerURLs = urls;
  },
  LOGIN_URLS(state, { urls }) {
    state.loginURLs = urls;
  },
  SURVEY_VIEW_FORM_RETRIEVED(state, { form }) {
    state.surveyForm = form;
  },
  TRASH_ITEMS_RETRIEVED(state, { data, model }) {
    addOrUpdateObjectsInArray(state.trashItems[model], data[RESULT]);
  },
  TRASH_ITEM_RESTORED(state, { entity, model }) {
    removeObjectsInArray(state.trashItems[model], [{ uid: entity.uid }]);
    state.trashItems.count[model] -= 1;
  },
  TRASH_ITEM_REMOVE(state, { entity, model }) {
    removeObjectsInArray(state.trashItems[model], [{ uid: entity.uid }]);
  },
  TRASH_ITEMS_REMOVE(state, { entities, model }) {
    entities.forEach((entity) => {
      removeObjectsInArray(state.trashItems[model], [{ uid: entity.uid }]);
    });
  },
  TRASH_ITEMS_RESET(state, { model }) {
    state.trashItems[model] = [];
    state.trashItems.count[model] = undefined;
  },
  UPDATE_TRASH_ITEMS_COUNT(state, { model, count }) {
    state.trashItems.count[model] = count;
  },
  DO_NOTHING() {
  },
  ENTITIES_RETRIEVED_V2(state, { entities, model }) {
    addOrUpdateObjectsInArray(state[model], entities);
  },
  APP_VERSION_RETRIEVED(state, { version, semverVersion }) {
    state.appVersion = version;
    state.semverVersion = semverVersion;
  },
  ENTITIES_UPDATED(state, { entities, model }) {
    state[model] = addOrUpdate(state[model], entities);
  },
  UNSET_FOREIGN_LOGIN(state) {
    state.foreignLogin = false;
  },
  ENTITIES_RETRIEVED_V3(state, { data, model, options }) {
    addOrUpdateObjectsInArray(state[model], data[options.alias]);
  },
  ENTITY_RETRIEVED_V2(state, { entity, model }) {
    addOrUpdateObjectInArray(state[model], entity);
  },
  FEEDBACK_ANSWER_UPDATED(state, { entity }) {
    const index = findIndexArray({ haystack: state.feedbackAnswer, needle: entity.uid });
    if (index > -1) {
      state.feedbackAnswer[index].status = entity.status;
    }
  },
  PAGE_VISIT_CREATED(state, { entity }) {
    state.pageVisit.unshift(entity);
    if (state.pageVisit.length > 20) {
      state.pageVisit.pop();
    }
  },
  PAGE_VISIT_NODE_ENRICHED(state, { node }) {
    state.pageVisit.forEach((current) => {
      if (typeof current.node === 'undefined'
                || typeof current.node.uid === 'undefined') {
        return;
      }
      if (current.node.uid === node.uid) {
        current.node = node;
      }
    });
  },
  PAGE_VISIT_RETRIEVED(state, { entities }) {
    state.pageVisit = entities;
  },
  PUBLIC_ACCOUNT_DATA_RETRIEVED(state, { publicAccountData }) {
    state.publicAccountData = publicAccountData;
  },
  SET_CLIENT_INFO(state, clientInfo) {
    state.clientInfo = clientInfo;
  },
  USER_PROVISIONING_STATUS(state, { provisioningStatus }) {
    state.provisioningStatus = provisioningStatus;
  },
  CUSTOM_FUNC_CTX_SET(state, { ctxAlias, node, value }) {
    if (!(ctxAlias in state.customFuncCtx)) {
      state.customFuncCtx[ctxAlias] = {};
    }
    state.customFuncCtx[ctxAlias][node.uid] = value;
  },
};
/* eslint-enable no-param-reassign */
